import { useState, useRef, useEffect } from 'react';

// material-ui
import { useTheme } from '@mui/material/styles';
import {
    Avatar,
    Badge,
    Box,
    Link,
    ButtonBase,
    CardActions,
    Chip,
    CircularProgress,
    ClickAwayListener,
    Divider,
    Grid,
    Paper,
    Popper,
    Stack,
    TextField,
    Typography,
    useMediaQuery
} from '@mui/material';

// project imports
import MainCard from 'ui-component/cards/MainCard';
import Transitions from 'ui-component/extended/Transitions';
import NotificationList from './NotificationList';
import AnimateButton from 'ui-component/extended/AnimateButton';
// assets
import { IconBell } from '@tabler/icons';
import { 
    useGetUserNotificationsListMutation, 
    useUpdateAllNotificationsToReadMutation,
    useGetAmountOfNotificationsMutation,
} from 'Services/UserNotificationService';

import { useDispatch } from 'react-redux';
import { showErrorToaster } from 'store/Shared.Slice';
import { LoadingButton } from '@mui/lab';

import NotificationPopup from './NotificationPopup';

// notification status options
const status = [
    {
        value: 'all',
        label: 'All Notifications'
    },
    {
        value: 'new',
        label: 'New'
    },
    {
        value: 'unread',
        label: 'Unread'
    }
];

// ==============================|| NOTIFICATION ||============================== //

const NotificationSection = () => {
    const theme = useTheme();
    const dispatch = useDispatch();
    const matchesXs = useMediaQuery(theme.breakpoints.down('md'));

    const [ getUserNotificationsList ] = useGetUserNotificationsListMutation();
    const [ updateAllNotificationsToRead, { isLoading: isUpdating } ] = useUpdateAllNotificationsToReadMutation();
    const [ getAmountOfNotifications ] = useGetAmountOfNotificationsMutation();

    const [open, setOpen] = useState(false);
    const [value, setValue] = useState('');
    const [pageNumber, setPageNumber] = useState(1);
    const [isLoading, setIsLoading] = useState(false);
    const [isLoadingShowMore, setIsLoadingShowMore] = useState(false);
    const [userNotifications, setUserNotifications] = useState([]);
    
    const [openReadNotification, setOpenReadNotification] = useState({
        isOpen: false,
        notificationId: ''
    });

    const [notificationsAmount, setNotificationsAmount] = useState({
        allNotifications: 0,
        unreadNotifications: 0
    });

    const [ userNotification, setUserNotification ] = useState({
        title: '',
        description: ''
    });

    /**
     * anchorRef is used on different componets and specifying one type leads to other components throwing an error
     * */
    const anchorRef = useRef(null);

    const handleClose = (event) => {
        if (anchorRef.current && anchorRef.current.contains(event.target)) {
            return;
        }
        setOpen(false);
    };

    const prevOpen = useRef(open);
    useEffect(() => {
        if (prevOpen.current === true && open === false) {
            anchorRef.current.focus();
        }
        prevOpen.current = open;
    }, [open]);

    const handleChange = (event) => {
        if (event?.target.value) setValue(event?.target.value);
    };

    const handleGetUserNotifications = (currentPageNumber) => {

        setIsLoading(true);

        getUserNotificationsList(currentPageNumber).unwrap().then(response => {
            setIsLoading(false);

            setUserNotifications([...response]);

            setOpen((prevOpen) => !prevOpen);

            const currentUnreadNotifications = response.filter(o => o.tagUnread === true).length;

            if (currentUnreadNotifications > notificationsAmount.unreadNotifications) {
                setNotificationsAmount({
                    ...notificationsAmount,
                    unreadNotifications: response.filter(o => o.tagUnread === true).length,
                });  
            }

        }, error => {
            // Bad Request
            setIsLoading(false);
            if (error.data?.message != null) {
                dispatch(showErrorToaster(error.data?.message));
            }
        });
    }

    const handleShowMore = () => {

        const currentPageNumber = pageNumber + 1;

        setPageNumber(currentPageNumber);
        setIsLoadingShowMore(true);
        
        getUserNotificationsList(currentPageNumber).unwrap().then(response => {
            setUserNotifications([...response]);
            setIsLoadingShowMore(false);

        }, error => {
            // Bad Request
            setIsLoadingShowMore(false);
            if (error.data?.message != null) {
                dispatch(showErrorToaster(error.data?.message));
            }
        });
    }

    const handleGetAmountOfNotifications = () => {
        getAmountOfNotifications().unwrap().then(response => {
            setNotificationsAmount({
                ...notificationsAmount,
                allNotifications: response?.data?.allNotifications,
                unreadNotifications: response?.data?.unreadNotifications,
            });
        }, error => {
            // Bad Request
            if (error.data?.message != null) {
                dispatch(showErrorToaster(error.data?.message));
            }
        });
    }

    const handleShowNotification = (userNotificationItem) => {

        const userNotificationsTemp = userNotifications.map(notification => ({
            ...notification,
            tagUnread: userNotificationItem.notificationId === notification.notificationId ? false : notification.tagUnread,
            tagNew: userNotificationItem.notificationId === notification.notificationId ? false : notification.tagNew,
        }));

        setUserNotifications(userNotificationsTemp);

        setOpenReadNotification({
            ...openReadNotification,
            isOpen: true,
            notificationId: userNotificationItem.notificationId
        });
    }

    const handleMarkAllAsRead = () => {

        updateAllNotificationsToRead().unwrap().then(() => {

            const userNotificationsTemp = userNotifications.map(notification => ({
                ...notification,
                tagUnread: false,
                tagNew: false,
            }));
    
            setUserNotifications(userNotificationsTemp);

            setNotificationsAmount({
                ...notificationsAmount,
                unreadNotifications: 0
            });

        }, error => {
            // Bad Request
            if (error.data?.message != null) {
                dispatch(showErrorToaster(error.data?.message));
            }
        })

    }

    useEffect(() => {
        handleGetAmountOfNotifications();
    }, []);

    
    return (
        <>
            <Box
                sx={{
                    ml: 2,
                    mr: 3,
                    [theme.breakpoints.down('md')]: {
                        mr: 2
                    }
                }}
            >
                <ButtonBase sx={{borderRadius: '12px', visibility: notificationsAmount.allNotifications === 0 && 'hidden' }}>

                    {isLoading && (
                        <CircularProgress size={20} />
                    )}

                    {!isLoading && (
                        <Avatar
                            variant="rounded"
                            sx={{
                                ...theme.typography.commonAvatar,
                                ...theme.typography.mediumAvatar,
                                transition: 'all .2s ease-in-out',
                                background: theme.palette.mode === 'dark' ? theme.palette.secondary.dark : theme.palette.secondary.light,
                                color: theme.palette.mode === 'dark' ? theme.palette.secondary.light :theme.palette.secondary.dark,
                                '&[aria-controls="menu-list-grow"],&:hover': {
                                    background: theme.palette.mode === 'dark' ? theme.palette.secondary.light : theme.palette.secondary.dark,
                                    color: theme.palette.mode === 'dark' ? theme.palette.secondary.dark :theme.palette.secondary.light
                                }
                            }}
                            ref={anchorRef}
                            aria-controls={open ? 'menu-list-grow' : undefined}
                            aria-haspopup="true"
                            onClick={() => {
                                if (open) {
                                    setOpen((prevOpen) => !prevOpen);
                                }
                                else {
                                    setPageNumber(1);
                                    handleGetUserNotifications(1);
                                }
                            }}
                            color="inherit"
                        >
                    
                            {(notificationsAmount.unreadNotifications === 0) && (
                                <IconBell 
                                    sx={{
                                        color: theme.palette.mode === 'dark' ? 'secondary.dark' : 'secondary.main!important',
                                        background: theme.palette.mode === 'dark' ? 'secondary.dark' : 'secondary.main!important'
                                    }}
                                    stroke={1.5} size="20" />
                            )}

                            {notificationsAmount.unreadNotifications > 0 && (
                                <Badge color="info"  overlap="circular" badgeContent=" " variant="dot">
                                    <AnimateButton type="jingle">
                                        <IconBell sx={{
                                                    color: theme.palette.mode === 'dark' ? 'secondary.dark' : 'secondary.light'
                                            }}  stroke={1.5} size="20" />
                                    </AnimateButton>
                                    
                                </Badge>  
                            )}
                            
                            
                        </Avatar>
                    )}
                </ButtonBase>
            </Box>
            <Popper
                placement={matchesXs ? 'bottom' : 'bottom-end'}
                open={open}
                anchorEl={anchorRef.current}
                role={undefined}
                transition
                disablePortal
                popperOptions={{
                    modifiers: [
                        {
                            name: 'offset',
                            options: {
                                offset: [matchesXs ? 5 : 0, 20]
                            }
                        }
                    ]
                }}
            >
                {({ TransitionProps }) => (
                    <Transitions position={matchesXs ? 'top' : 'top-right'} in={open} {...TransitionProps}>
                        <Paper>
                            <ClickAwayListener onClickAway={handleClose} {...openReadNotification.isOpen && { mouseEvent: false }}>
                                <MainCard border={false} elevation={16} content={false} boxShadow shadow={theme.shadows[16]} sx={{ width: '380px' }}>
                                    <Grid container direction="column" spacing={2}>
                                        <Grid item xs={12}>
                                            <Grid container alignItems="center" justifyContent="space-between" sx={{ pt: 2, px: 2 }}>
                                                <Grid item>
                                                    <Stack direction="row" spacing={2}>
                                                        <Typography variant="subtitle1">All Notifications</Typography>
                                                        <Chip
                                                            size="small"
                                                            label={notificationsAmount.unreadNotifications}
                                                            sx={{
                                                                color: theme.palette.background.default,
                                                                bgcolor: theme.palette.warning.dark
                                                            }}
                                                        />
                                                    </Stack>
                                                </Grid>
                                                <Grid item ml={1}>
                                                    {isUpdating && (
                                                        <CircularProgress size={20} />
                                                    )}

                                                    {!isUpdating && (
                                                        <Link component="button" color="primary" variant="subtitle2" onClick={handleMarkAllAsRead}
                                                            >Mark as all read
                                                        </Link>
                                                    )}
                                                    
                                                </Grid>
                                            </Grid>
                                        </Grid>
                                        <Grid item xs={12} sx={{ maxHeight: '60vh', overflow: 'auto' }}>
                                            <Grid container direction="column" spacing={2}>
                                                    <Grid item xs={12}>
                                                        <Box sx={{ px: 2, pt: 0.25 }}>
                                                            <TextField
                                                                id="outlined-select-currency-native"
                                                                select
                                                                fullWidth
                                                                value={value}
                                                                onChange={handleChange}
                                                                SelectProps={{
                                                                    native: true
                                                                }}
                                                            >
                                                                {status.map((option) => (
                                                                    <option key={option.value} value={option.value}>
                                                                        {option.label}
                                                                    </option>
                                                                ))}
                                                            </TextField>
                                                        </Box>
                                                    </Grid>
                                                    <Grid item xs={12} p={0}>
                                                        <Divider sx={{ my: 0 }} />
                                                    </Grid>
                                            </Grid>

                                            <NotificationList
                                                userNotifications={userNotifications}
                                                filterOption={value}
                                                onClickNotification={handleShowNotification}
                                            />

                                        </Grid>
                                    </Grid>
                                    <Divider />
                                    <CardActions sx={{ p: 1.25, justifyContent: 'center' }}>
                                        <LoadingButton size="small" disableElevation onClick={handleShowMore} loading={isLoadingShowMore}>
                                            Show more
                                        </LoadingButton>
                                    </CardActions>
                                </MainCard>
                            </ClickAwayListener>
                        </Paper>
                    </Transitions>
                )}
            </Popper>

            <NotificationPopup
                openPopup={openReadNotification.isOpen} 
                notificationId={openReadNotification.notificationId}
                onClose={() => setOpenReadNotification({ ...openReadNotification, isOpen: false, notificationId: '' })}
                onReadNotification={(amount) => {

                    setNotificationsAmount({
                        ...notificationsAmount,
                        allNotifications: amount.allNotifications,
                        unreadNotifications: amount.unreadNotifications,
                    });

                }}
            />
        </>
    );
};

export default NotificationSection;
