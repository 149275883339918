import { createSlice } from "@reduxjs/toolkit";
import { userProjectApi } from "Services/UserProjectService";
import { proposalLetterApi } from "Services/ProposalLetterService";

export const initialState = {
    id: null,
    userProject: {
        id: null,
        value: null,
        label: null
    },
    userProjectToChange: {
        id: null,
        value: null,
        label: null
    },
    allProjectList: [],
    activeStep: 0,
    proposalDocument: {
        coverLetter: '',
        executiveSummary: '',
        needStatement: '',
        goals: '',
        methods: '',
        evaluation: '',
        funding: '',
        organization: '',
        closingStatement: '',
    },
    stepList: [],
    forceDisabled: false,
    autosaveEnabled: false,
};

const proposalSlice = createSlice({
    name: "document-generator-proposal",
    initialState,
    reducers: {
        handleChangeUserProject: (state, action) => {
            if (action.payload !== null) {
                state.userProjectToChange = state.allProjectList.find(o => o.id === action.payload.id);
            }
        },
        handleConfirmChange: (state, action) => {
            state.userProject = state.userProjectToChange;
        },
        handleStep: (state, action) => {
            state.activeStep = action.payload;
        },
        handleChangeDocument: (state, action) => {
            state.proposalDocument[action.payload.stepName] = action.payload.document;
        },
        handleSaveSuccess: (state, action) => {
            state.id = action.payload.data;
        },
        handleAutoSaveEnabled: (state, action) => {
            state.autosaveEnabled = action.payload;
        },
        handleClear: (state, action) => {
            state.id = null;
            state.activeStep = 0;
            state.userProject.id = null;
            state.userProject.value = null;
            state.userProject.label = null;
            state.autosaveEnabled = false;
            state.proposalDocument = initialState.proposalDocument;
        },
        handleFillAllProjects: (state, action) => {
            const { selectedProject, projects } = action.payload;
            state.forceDisabled = action.payload.length === 0;
            state.autosaveEnabled = false;
            state.allProjectList = projects ?? [];
            state.userProject = selectedProject;
        },
    },
    extraReducers: (builder) => {
        builder
        .addMatcher(
            proposalLetterApi.endpoints.getProposalStepList.matchFulfilled, (state, action) => {
                state.stepList = action.payload;
            }
        )
        .addMatcher(
            proposalLetterApi.endpoints.getProposalLetter.matchFulfilled, (state, action) => {
                state.id = action.payload.id;
                state.proposalDocument.coverLetter = action.payload.coverLetter;
                state.proposalDocument.executiveSummary = action.payload.executiveSummary;
                state.proposalDocument.needStatement = action.payload.needStatement;
                state.proposalDocument.goals = action.payload.goals;
                state.proposalDocument.methods = action.payload.methods;
                state.proposalDocument.evaluation = action.payload.evaluation;
                state.proposalDocument.funding = action.payload.funding;
                state.proposalDocument.organization = action.payload.organization;
                state.proposalDocument.closingStatement = action.payload.closingStatement;
            }
        )
    }

});

export const { 
    handleChangeUserProject,
    handleStep,
    handleChangeDocument,
    handleSaveSuccess,
    handleAutoSaveEnabled,
    handleClear,
    handleConfirmChange,
    handleFillAllProjects,
} = proposalSlice.actions;

export default proposalSlice.reducer;
