import { emptySplitApi } from './index'

export const userNotificationApi = emptySplitApi.injectEndpoints({
  endpoints: (builder) => ({
    getUserNotificationsList: builder.mutation({
      query: (pageNumber) => ({
        url: `api/v1/auth-service/user-notifications/${pageNumber}`,
        method: "GET"
      }),
    }),
    readUserNotification: builder.mutation({
        query: (notificationId) => ({
          url: `api/v1/auth-service/user-notifications/read/${notificationId}`,
          method: "GET"
        }),
    }),
    updateAllNotificationsToRead: builder.mutation({
        query: () => ({
          url: `api/v1/auth-service/user-notifications/read-all`,
          method: "POST"
        }),
    }),
    getAmountOfNotifications: builder.mutation({
        query: () => ({
          url: `api/v1/auth-service/user-notifications/amount`,
          method: "GET"
        }),
    }),
  }),
  overrideExisting: false,
});

export const { 
    useGetUserNotificationsListMutation,
    useReadUserNotificationMutation,
    useUpdateAllNotificationsToReadMutation,
    useGetAmountOfNotificationsMutation
} = userNotificationApi;
