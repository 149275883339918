import PropTypes from 'prop-types';
import { forwardRef } from 'react';

// material-ui
import { useTheme } from '@mui/material/styles';
import { Card, CardContent, CardHeader, Divider, Typography, Grid } from '@mui/material';

// ==============================|| CUSTOM SUB CARD ||============================== //

const SubCard = forwardRef(({ children, content, contentClass, darkTitle, secondary, sx = {}, contentSX = {}, title, extra, ...others }, ref) => {
    const theme = useTheme();
    return (
        <Card
            ref={ref}
            sx={{
                border: '1px solid',
                borderColor: theme.palette.mode === 'dark' ? theme.palette.dark.light + 15 : theme.palette.primary.light,
                ':hover': {
                    boxShadow: theme.palette.mode === 'dark' ? '0 2px 14px 0 rgb(33 150 243 / 10%)' : '0 2px 14px 0 rgb(32 40 45 / 8%)'
                },
                ...sx
            }}
            {...others}
        >
            {/* card header and action */}
            { extra.length === 0 ? 
                ( 
                    !darkTitle && title && 
                    <CardHeader sx={{ p: 2.5 }} title={<Typography variant="h5">{title}</Typography>} action={secondary} />
                )
            :  ( 
                    !darkTitle && title && (
                        <CardHeader
                            sx={{ p: 2.5 }}
                            title={
                                <Grid container alignItems="center" justifyContent="space-between" spacing={1}>
                                    <Grid item>
                                        <Typography variant="h5">{title}</Typography>
                                    </Grid>
                                    {extra && (
                                        <Grid item>
                                            <Grid container spacing={1} alignItems="center">
                                                {extra.map((item, index) => (
                                                    <Grid item key={index} sx={{ marginLeft: index > 0 ? theme.spacing(2) : 0 }}>
                                                        {item}
                                                    </Grid>
                                                ))}
                                            </Grid>
                                        </Grid>
                                    )}
                                </Grid>
                            }
                            action={secondary}
                        />
                )
            )}

            { extra.length === 0 ? 
                ( 
                    darkTitle && title && 
                    <CardHeader sx={{ p: 2.5 }} title={<Typography variant="h4">{title}</Typography>} action={secondary} />
                )
            : (
                    darkTitle && title && (
                    <CardHeader
                        sx={{ p: 2.5 }}
                        title={
                            <Grid container alignItems="center" justifyContent="space-between" spacing={1}>
                                <Grid item>
                                    <Typography variant="h5">{title}</Typography>
                                </Grid>
                                {extra && (
                                    <Grid item>
                                        <Grid container spacing={1} alignItems="center">
                                            {extra.map((item, index) => (
                                                <Grid item key={index} sx={{ marginLeft: index > 0 ? theme.spacing(2) : 0 }}>
                                                    {item}
                                                </Grid>
                                            ))}
                                        </Grid>
                                    </Grid>
                                )}
                            </Grid>
                        }
                        action={secondary}
                    />
                )
            )}

            {/* content & header divider */}
            {title && (
                <Divider
                    sx={{
                        opacity: 1,
                        borderColor: theme.palette.mode === 'dark' ? theme.palette.dark.light + 15 : theme.palette.primary.light
                    }}
                />
            )}

            {/* card content */}
            {content && (
                <CardContent sx={{ p: 2.5, ...contentSX }} className={contentClass || ''}>
                    {children}
                </CardContent>
            )}
            {!content && children}
        </Card>
    );
});

SubCard.propTypes = {
    children: PropTypes.node,
    content: PropTypes.bool,
    contentClass: PropTypes.string,
    darkTitle: PropTypes.bool,
    secondary: PropTypes.oneOfType([PropTypes.node, PropTypes.string, PropTypes.object]),
    sx: PropTypes.object,
    contentSX: PropTypes.object,
    title: PropTypes.oneOfType([PropTypes.node, PropTypes.string, PropTypes.object]),
    extra: PropTypes.arrayOf(PropTypes.node)
};

SubCard.defaultProps = {
    content: true,
    extra: []
};

export default SubCard;
