import { emptySplitApi } from './index'

export const foundationProfileApi = emptySplitApi.injectEndpoints({
  endpoints: (builder) => ({
    getProfileHeader: builder.mutation({
        query: (model) => ({
            url: `api/v1/search-engine-service/foundation-profile/profile-header/${model.profileId}`,
            method: "GET",
        }),
    }),
    getTaxReturn: builder.query({
        query: (profileId) => ({
            url: `api/v1/search-engine-service/foundation-profile/tax-return/${profileId}`,
            method: "GET",
        }),
    }),
    getFinancialList: builder.mutation({
        query: (profileId) => ({
            url: `api/v1/search-engine-service/foundation-profile/financial-list/${profileId}`,
            method: "GET",
        }),
    }),
    getGiftsPagedList: builder.mutation({
        query: (model) => ({
            url: `api/v1/search-engine-service/foundation-profile/gifts/${model.profileId}`,
            method: "POST",
            body: model
        }),
    }),
    getProgramList: builder.query({
        query: (profileId) => ({
          url: `api/v1/search-engine-service/foundation-profile/programs/${profileId}`,
          method: "GET",
        }),
    }),
    getApplication: builder.query({
      query: (profileId) => ({
        url: `api/v1/search-engine-service/foundation-profile/applications/${profileId}`,
        method: "GET",
      }),
    }),
    getApplicationWithTrigger: builder.mutation({
        query: (profileId) => ({
          url: `api/v1/search-engine-service/foundation-profile/applications/${profileId}`,
          method: "GET",
        }),
    }),
    getGrantOverview: builder.mutation({
        query: (model) => ({
          url: `api/v1/search-engine-service/foundation-profile/grant-overview/${model.profileId}?`
                + `pageNumber=${model?.pageNumber || 0}`
                + `&pageSize=${model?.pageSize || ''}`
                + `&orderField=${model?.orderField || 'description'}`
                + `&orderDirection=${model?.orderDirection || 'asc'}`,
          method: "GET",
        }),
    }),
    getDirectorList: builder.mutation({
        query: (model) => ({
          url: `api/v1/search-engine-service/foundation-profile/directors/${model.profileId}?`
                + `pageNumber=${model?.pageNumber || 0}`
                + `&pageSize=${model?.pageSize || ''}`
                + `&orderField=${model?.orderField || 'description'}`
                + `&orderDirection=${model?.orderDirection || 'asc'}`,
          method: "GET",
        }),
    }),
   
    getChartProvinceList: builder.mutation({
        query: (profileId) => ({
          url: `api/v1/search-engine-service/foundation-profile/charts/province-list/${profileId}`,
          method: "GET",
        }),
    }),
    getChartRangeAmountList: builder.mutation({
        query: (profileId) => ({
          url: `api/v1/search-engine-service/foundation-profile/charts/range-amount/${profileId}`,
          method: "GET",
        }),
    }),
    getChartRangeSector: builder.mutation({
        query: (profileId) => ({
          url: `api/v1/search-engine-service/foundation-profile/charts/range-sector/${profileId}`,
          method: "GET",
        }),
    }),
    getFoundationProfileSummaryOverview: builder.mutation({
        query: (profileId) => ({
          url: `api/v1/search-engine-service/foundation-profile/summary-overview/${profileId}`,
          method: "GET",
        }),
    })
  }),
  overrideExisting: false,
});

export const {
    useGetProfileHeaderMutation,
    useGetGiftsPagedListMutation,
    useGetProgramListQuery,
    useGetApplicationQuery,
    useGetApplicationWithTriggerMutation,
    useGetGrantOverviewMutation,
    useGetDirectorListMutation,
    useGetChartProvinceListMutation,
    useGetChartRangeAmountListMutation,
    useGetChartRangeSectorMutation,
    useGetFoundationProfileSummaryOverviewMutation,
    useGetTaxReturnQuery,
    useGetFinancialListMutation,
} = foundationProfileApi;
