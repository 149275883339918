import { Outlet } from 'react-router-dom';
import { useSelector, useDispatch } from "react-redux";

// project imports
import Customization from '../Customization';

import Snackbar from '@mui/material/Snackbar';
import SweetAlert from 'react-bootstrap-sweetalert';

import { closeToaster, closeMessage } from "store/Shared.Slice";
import { useTranslation } from "react-i18next";


// ==============================|| MINIMAL LAYOUT ||============================== //

const MinimalLayout = () => {

  const { message, toaster } = useSelector((state) => state.sharedState);
  const dispatch = useDispatch();
  const { t } = useTranslation();

  return (
    <>
        <Snackbar
            open={toaster.isOpen}
            autoHideDuration={6000}
            onClose={() => dispatch(closeToaster())}
            message={t(toaster.message)}
        />

        {message.isOpen && (
          <SweetAlert
              title={t(message.title)}
              onConfirm={() => dispatch(closeMessage())}
              onCancel={() => dispatch(closeMessage())}>
              {t(message.text)}
          </SweetAlert>
          )
        }
        <Outlet />
        <Customization />
    </>
  )
};

export default MinimalLayout;
