import { createSlice } from "@reduxjs/toolkit";
import { subscriptionPlanApi } from 'Services/SubscriptionPlanService'
import { countryApi } from "Services/CountryService";
import { adminApi } from "Services/AdminService";

export const initialState = {
    roleList: ['Admin', 'User', 'SubUser', 'Supervisor', 'Member', 'Demo', 'Sales', 'SuperAdmin', 'IT'],
    additionalUsersList: [0,1,2,3,4,5,10,20],
    subscriptionPlanList: [],
    countryList: [],
    selectedCountry: {
        id: null,
        code: null,
        description: null,
        provinceOrStateList: []
    },
    selectedProvinceOrState: {
        id: null,
        code: null,
        description: null,
    },
    firstName: {
        value: '',
        error: ''
    },
    lastName: {
        value: '',
        error: ''
    },
    phoneNumber: {
        value: '',
        error: ''
    },

    userName: {
        value: '',
        error: ''
    },
    email: {
        value: '',
        error: ''
    },
    subscriptionPlanName: {
        value: '',
        error: ''
    },
    userRole: {
        value: '',
        error: ''
    },
    companyName: {
        value: '',
        error: ''
    },
    addressLine: {
        value: '',
        error: ''
    },
    addressLine2: {
        value: '',
        error: ''
    },
    city: {
        value: '',
        error: ''
    },
    provinceOrState: {
        value: '',
        error: ''
    },
    postalCode: {
        value: '',
        error: ''
    },
    country: {
        value: '',
        error: ''
    },
    additionalUsers: {
        value: '',
        error: ''
    },
    isImporting: false,
    pipedrivePersonCode: null,
    pipedriveOrganizationCode: null,
    processPayment: false,
    openPopupPayment: false
};

const createAccountSlice = createSlice({
    name: "create-account",
    initialState,
    reducers: {
        handleChange: (state, action) => {
            state[action.payload.field].error = '';
            state[action.payload.field].value = action.payload.value;            
        },
        handleModelErrors: (state, action) => {
            Object.entries(action.payload).forEach(([field, errors]) => { 
                state[field].error = errors[0];
            });
        },
        handleChangeSubscriptionPlan: (state, action) => {
            state.subscriptionPlanName.value = action.payload;
            state.subscriptionPlanName.error = '';
            state.additionalUsers.value = state.subscriptionPlanList.find(value => (value.subscriptionPlanName === action.payload)).amountUsers;
            state.additionalUsers.error = '';
        },
        handleChangeCountry: (state, action) => {
            state.selectedCountry = state.countryList.find(o => o.description === action.payload);
            state.country.value = state.countryList.find(o => o.description === action.payload).description;
            state.country.error = '';
            state.selectedProvinceOrState = initialState.selectedProvinceOrState;
            state.provinceOrState = initialState.provinceOrState;
        },
        handleChangeProvinceOrState: (state, action) => {
            if (action.payload?.value) {
                state.selectedProvinceOrState = state.selectedCountry.provinceOrStateList.find(o => o.description === action.payload.value || '');
                state.provinceOrState.value = state.selectedCountry.provinceOrStateList.find(o => o.description === action.payload.value || '').description;
            }
        },
        handleFillCountryAndState: (state, action) => {

            const selectedCountry = state.countryList.find(o => o.description === state.country.value);

            if (selectedCountry) {
                state.selectedCountry = selectedCountry;
                const selectedProvinceOrState = selectedCountry.provinceOrStateList.find(o => o.description === state.provinceOrState.value);
                if (selectedProvinceOrState) {
                    state.selectedProvinceOrState = selectedProvinceOrState;
                }
            }
        },
        handleChangeProcessPayment: (state, action) => {
            state.processPayment = action.payload;
        },
        handleOpenPopupPayment: (state, action) => {
            state.openPopupPayment = true;
        },
        handleClosePopupPayment: (state, action) => {
            state.openPopupPayment = false;
        },
        clearErrors: (state, action) => {
            Object.keys(initialState).forEach(field => {
                if (state[field]?.error !== undefined) {
                    state[field].error = '';    
                }
            });
        },
        clearForm: (state, action) => {
            state.firstName.value = '';
            state.firstName.error = '';

            state.lastName.value = '';
            state.lastName.error = '';
            
            state.phoneNumber.value = '';
            state.phoneNumber.error = '';

            state.userName.value = '';
            state.userName.error = '';

            state.email.value = '';
            state.email.error = '';

            state.subscriptionPlanName.value = '';
            state.subscriptionPlanName.error = '';

            state.userRole.value = '';
            state.userRole.error = '';

            state.companyName.value = '';
            state.companyName.error = '';

            state.addressLine.value = '';
            state.addressLine.error = '';

            state.addressLine2.value = '';
            state.addressLine2.error = '';

            state.city.value = '';
            state.city.error = '';

            state.provinceOrState.value = '';
            state.provinceOrState.error = '';

            state.postalCode.value = '';
            state.postalCode.error = '';

            state.country.value = '';
            state.country.error = '';

            state.selectedProvinceOrState = initialState.selectedProvinceOrState;
            state.pipedriveOrganizationCode = null;
            state.pipedrivePersonCode = null;
            state.processPayment = false;
            state.openPopupPayment = false;
        },
    },
    extraReducers: (builder) => {
        builder.addMatcher(
            subscriptionPlanApi.endpoints.getPlans.matchFulfilled, (state, action) => {
                state.subscriptionPlanList = action.payload;
            }
        )
        .addMatcher(
            countryApi.endpoints.getCountryList.matchFulfilled, (state, action) => {
                state.countryList = action.payload;
            }
        )
        .addMatcher(
            adminApi.endpoints.importPipedriveOrganization.matchFulfilled, (state, action) => {

                state.isImporting = false;

                state.firstName.value = action.payload.firstName || '';
                state.firstName.error = '';

                state.lastName.value = action.payload.lastName || '';;
                state.lastName.error = '';
                
                state.phoneNumber.value = action.payload.phoneNumber || '';;
                state.phoneNumber.error = '';

                state.userName.value = action.payload.email || '';;
                state.userName.error = '';

                state.email.value = action.payload.email || '';;
                state.email.error = '';

                state.subscriptionPlanName.value = '';
                state.subscriptionPlanName.error = '';

                state.userRole.value = ''
                state.userRole.error = '';

                state.companyName.value = action.payload.companyName || '';;
                state.companyName.error = '';

                state.addressLine.value = action.payload.addressLine || '';;
                state.addressLine.error = '';

                state.addressLine2.value = '';
                state.addressLine2.error = '';

                state.city.value = action.payload.city || '';;
                state.city.error = '';

                state.provinceOrState.value = action.payload.provinceOrState || '';;
                state.provinceOrState.error = '';

                state.postalCode.value = action.payload.postalCode || '';;
                state.postalCode.error = '';

                state.country.value = action.payload.country || '';;
                state.country.error = '';

                state.pipedrivePersonCode = action.payload.pipedrivePersonCode;
                state.pipedriveOrganizationCode = action.payload.pipedriveOrganizationCode;
            }
        )
        .addMatcher(
            adminApi.endpoints.importPipedriveOrganization.matchPending, (state, action) => {
                state.isImporting = true;
            }
        )
        .addMatcher(
            adminApi.endpoints.importPipedriveOrganization.matchRejected, (state, action) => {
                state.isImporting = false;
            }
        )
    }
});

export const { 
    handleChange,
    handleModelErrors,
    clearForm,
    handleChangeSubscriptionPlan,
    handleChangeCountry,
    handleChangeProvinceOrState,
    handleFillCountryAndState,
    handleChangeProcessPayment,
    handleOpenPopupPayment,
    handleClosePopupPayment,
    clearErrors,
} = createAccountSlice.actions;

export default createAccountSlice.reducer;