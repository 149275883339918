import { emptySplitApi } from './index'

export const countryApi = emptySplitApi.injectEndpoints({
  endpoints: (builder) => ({
    getCountryList: builder.query({
        query: (model) => ({
            url: `api/v1/auth-service/country`,
            method: "GET"
        }),
    }),
    getCountryByCode: builder.query({
        query: (model) => ({
            url: `api/v1/auth-service/country/${model.countryCode}`,
            method: "GET"
        }),
    }),
    getCountryByCodeWithTrigger: builder.mutation({
        query: (model) => ({
            url: `api/v1/auth-service/country/${model.countryCode}`,
            method: "GET"
        }),
    }),
    getCountryOnlyList: builder.query({
        query: (model) => ({
            url: `api/v1/auth-service/country/country-only`,
            method: "GET"
        }),
    }),
    getCountyOnlyList: builder.query({
        query: (model) => ({
            url: `api/v1/search-engine-service/country/counties`,
            method: "GET"
        }),
    }),
    getCityListByProvinceOrStates: builder.mutation({
        query: (model) => ({
            url: `api/v1/auth-service/country/city-list`,
            method: "POST",
            body: model
        }),
    }),
    getProvinceOrStateListByCountryCodes: builder.mutation({
        query: (countryCodes) => ({
            url: `api/v1/auth-service/country/province-state-list`,
            method: "POST",
            body: [...countryCodes]
        }),
    }),
  }),
  overrideExisting: false,
});

export const { 
    useGetCountryListQuery,
    useGetCityListByProvinceOrStatesMutation,
    useGetCountryOnlyListQuery,
    useGetCountyOnlyListQuery,
    useGetProvinceOrStateListByCountryCodesMutation,
    useGetCountryByCodeQuery,
    useGetCountryByCodeWithTriggerMutation
} = countryApi;
