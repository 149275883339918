import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
    Grid,
    useMediaQuery,
    useTheme,
  } from "@mui/material";

import { MailOutline } from "@mui/icons-material"
import LoadingButton from '@mui/lab/LoadingButton';

import {  
    useGetAdditionalEmailPagedListMutation,
} from "Services/OrganizationAdditionalEmailService";

import { 
    openDialog
} from "./OrganizationAdditionalEmail.Slice";
import { showErrorToaster } from "store/Shared.Slice";
import { useTranslation } from "react-i18next";
import SubCard from "ui-component/cards/SubCard";
import OrganizationAdditionalEmailList from "./OrganizationAdditionalEmail.List";
import OrganizationAdditionalEmailPopup from "./OrganizationAdditionalEmail.Popup";
import { usePrompt } from "react-router-dom";

const OrganizationAdditionalEmailPage = () => {

    const {
        emailAddress,
        previousEmail,
        additionalEmailListPagedData: {
            pageNumber,
            pageSize,
            totalCount
        }
    } = useSelector((state) => state.organizationAdditionalEmailState);

    const theme = useTheme();
    const dispatch = useDispatch();
    const matchDownSM = useMediaQuery(theme.breakpoints.down("md"));
    const { t } = useTranslation();

    const [getAdditionalEmailList] = useGetAdditionalEmailPagedListMutation();

    usePrompt(
        "You have unsaved changes. Are you sure to discard all changes made?",
        previousEmail !== emailAddress.value
    );

    const loadListInitial = async() => {
        try {
            await getAdditionalEmailList({pageNumber, pageSize});
        }
        catch(error) {
            // Bad Request
            if (error.data?.message != null) {
                dispatch(showErrorToaster(error.data?.message));
            }
        }
    }

    useEffect(() => {
        loadListInitial();
        // eslint-disable-next-line react-hooks/exhaustive-deps 
    }, []);

    return (
        <>
            <OrganizationAdditionalEmailPopup />

            <Grid container spacing={matchDownSM ? 0 : 2}>
                <Grid item xs={12} sm={12} md={12}>
                    <SubCard title={
                        <Grid container alignItems="baseline" justifyContent="space-between">
                            <Grid item>
                                {`${t('labels.emails_created')}: ${totalCount}`}
                            </Grid>
                            <Grid item>
                                <LoadingButton
                                    sx={{ ml: 2 }}
                                    disableElevation
                                    size="large"
                                    type="button"
                                    variant="outlined"
                                    color="secondary"
                                    startIcon={<MailOutline />}
                                    onClick={() => dispatch(openDialog())}
                                >
                                    {t('buttons.add_email')}
                                </LoadingButton>
                            </Grid>
                        </Grid>
                    }>
                        <OrganizationAdditionalEmailList />
                    </SubCard>
                </Grid>
            </Grid>
        </>
    )

};


export default OrganizationAdditionalEmailPage;