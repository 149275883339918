// material-ui
import { Divider, Typography } from '@mui/material';

// project imports
import NavGroupV2 from './NavGroup/NavGroupV2';
import menus from 'menu-items';
import { useDispatch, useSelector } from 'react-redux';
import { useEffect } from 'react';
import { loadMenuItems } from 'store/Shared.Slice';
import NavItem from './NavItem';

// ==============================|| SIDEBAR MENU LIST ||============================== //

const MenuList = () => {

    const dispatch = useDispatch();
    const { userToken, menuItem } = useSelector(state => state.sharedState)

    let userRoles = [];

    useEffect(() => {

        if (menuItem.items.length === 0) {
            dispatch(loadMenuItems(menus));
        }

    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    if (Array.isArray(userToken.role)) {
        userRoles = userToken.role.map(value => value.toLowerCase());
    }
    else {
        userRoles.push(userToken.role.toLowerCase());
    }
    
    const navItems = menuItem.items.map((item) => {
        switch (item.type) {
            case 'group':

                if (userRoles.filter(x => item.roles.includes(x)).length > 0)
                    return <NavGroupV2 key={item.id} item={item} />;
                else
                    return null;

            case 'link':
                if (userRoles.filter(x => item.roles.includes(x)).length > 0)
                    return (
                        <>
                            <NavItem key={item.id} item={item} level={1} />
                            <Divider />
                        </>
                    )
                else
                    return null;

            default:
                return (
                    <Typography key={item.id} variant="h6" color="error" align="center">
                        Menu Items Error
                    </Typography>
                );
        }
    });

    return <div style={{ color: 'white' }}>{navItems}</div>;
};

export default MenuList;
