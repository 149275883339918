// material-ui
import { useTheme, styled } from '@mui/material/styles';
import {
    Avatar,
    Button,
    Card,
    CardContent,
    Chip,
    Divider,
    Grid,
    List,
    ListItem,
    ListItemAvatar,
    ListItemSecondaryAction,
    ListItemText,
    Stack,
    Typography
} from '@mui/material';

// assets
import { IconBrandTelegram, IconBuildingStore, IconMailbox, IconPhoto } from '@tabler/icons';
import User1 from 'assets/images/users/user-round.svg';
import { useEffect, useState } from 'react';

// styles
const ListItemWrapper = styled('div')(({ theme }) => ({
    cursor: 'pointer',
    padding: 16,
    '&:hover': {
        background: theme.palette.primary.light
    },
    '& .MuiListItem-root': {
        padding: 0
    }
}));

// ==============================|| NOTIFICATION LIST ITEM ||============================== //

const NotificationList = ({ 
    userNotifications = [],
    filterOption,
    onClickNotification
}) => {

    const theme = useTheme();
    const [filteredNotifications, setFilteredNotifications] = useState([]);

    const chipSX = {
        height: 24,
        padding: '0 6px'
    };
    const chipErrorSX = {
        ...chipSX,
        color: theme.palette.orange.dark,
        backgroundColor: theme.palette.orange.light,
        marginRight: '5px'
    };

    const chipWarningSX = {
        ...chipSX,
        color: theme.palette.warning.dark,
        backgroundColor: theme.palette.warning.light
    };

    useEffect(() => {

        setFilteredNotifications([...userNotifications]);

        if (filterOption === "new") {
            setFilteredNotifications([...userNotifications.filter(o => o.tagNew === true)]);
        }

        if (filterOption === "unread") {
            setFilteredNotifications([...userNotifications.filter(o => o.tagUnread === true)]);
        }

    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [filterOption, userNotifications]);

    return (
        <List
            sx={{
                width: '100%',
                maxWidth: 380,
                py: 0,
                borderRadius: '10px',
                '& .MuiListItemSecondaryAction-root': {
                    top: 22
                },
                '& .MuiDivider-root': {
                    my: 0
                },
                '& .list-container': {
                    pl: 7
                }
            }}
        >
            {filteredNotifications.map(item => (
                <div key={item.notificationId} onClick={() => onClickNotification(item)}>
                <ListItemWrapper>

                    <ListItem alignItems="center">
                        <ListItemAvatar>
                            <Avatar
                                sx={{
                                    color: theme.palette.success.dark,
                                    backgroundColor: theme.palette.success.light,
                                    border: 'none',
                                    borderColor: theme.palette.success.main
                                }}
                            >
                                <IconBuildingStore stroke={1.5} size="20" />
                            </Avatar>
                        </ListItemAvatar>
                        <ListItemText primary={<Typography variant="subtitle1" sx={{ paddingTop: '10px' }}>{item.title}</Typography>} />
                    </ListItem>

                    <Grid container direction="column" className="list-container">
                        <Grid item xs={12} sx={{ pb: 2 }}>
                            <Typography variant="subtitle2">{item.shortDescription}</Typography>
                        </Grid>
                        <Grid item xs={12}>
                            <Grid container justifyContent="space-between">

                                {item.tagNew === true && (
                                    <Grid item>
                                        <Chip label="New" sx={chipWarningSX} />
                                    </Grid>
                                )}

                                {item.tagUnread === true && (
                                    <Grid item>
                                        <Chip label="Unread" sx={chipErrorSX} />
                                    </Grid>
                                )}

                                <Grid item>
                                    <Typography variant="caption">
                                        {item.creationTimeAgo}
                                    </Typography>
                                </Grid>
                                
                            </Grid>
                        </Grid>
                    </Grid>

                </ListItemWrapper>
                
                <Divider />

                </div>
            ))}

        </List>
    );
};

export default NotificationList;
