import { emptySplitApi } from './index'

export const inquiryLetterApi = emptySplitApi.injectEndpoints({
    endpoints: (builder) => ({
        getStepList: builder.query({
            query: () => ({
                url: `api/v1/document-generator-service/inquiry-letter/steps`,
                method: "GET"
            }),
        }),
        insertUpdateInquiry: builder.mutation({
            query: (model) => ({
                url: `api/v1/document-generator-service/inquiry-letter`,
                method: "POST",
                body: model
            }),
        }),
        getInquiryLetter: builder.mutation({
            query: (userProjectId) => ({
                url: `api/v1/document-generator-service/inquiry-letter/${userProjectId}`,
                method: "GET",
            }),
        }),
    }),
    overrideExisting: false,
});

export const { 
    useGetStepListQuery,
    useInsertUpdateInquiryMutation,
    useGetInquiryLetterMutation
} = inquiryLetterApi;
