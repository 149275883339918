import { createSlice } from "@reduxjs/toolkit";

export const initialState = {
    usersList: [],
    licenseAmount: 0,
    usersAmount: 0,
    loadingList: false,
    searchTerm: '',
    isDialogOpen: false,
    isDialogRemoveOpen: false,
    userId: null,
    userName: {
        value: '',
        error: ''
    },
    firstName: {
        value: '',
        error: ''
    },
    lastName: {
        value: '',
        error: ''
    },
    status: {
        value: true,
        error: ''
    },
    collaborationAll: {
        value: false,
        error: ''
    },
    collaborationParent: {
        value: false,
        error: ''
    },
};

const additionalUsersSlice = createSlice({
    name: "additional-users",
    initialState,
    reducers: {
        handleAddNewUser: (state, action) => {
            state.isDialogOpen = true;
            state.userId = null;
            state.userName.value = '';
            state.userName.error = '';
            state.firstName.value = '';
            state.firstName.error = '';
            state.lastName.value = '';
            state.lastName.error = '';
            state.status.value = true;
            state.status.error = '';
            state.collaborationAll.value = false;
            state.collaborationAll.error = '';
            state.collaborationParent.value = false;
            state.collaborationParent.error = '';
        },
        handleEditUser: (state, action) => {
            state.isDialogOpen = true;
            state.userId = action.payload.id;
            state.userName.value = action.payload.userName;
            state.userName.error = '';
            state.firstName.value = action.payload.firstName;
            state.firstName.error = '';
            state.lastName.value = action.payload.lastName;
            state.lastName.error = '';
            state.status.value = action.payload.status;
            state.status.error = '';
            state.collaborationAll.value = action.payload.collaborationAll;
            state.collaborationAll.error = '';
            state.collaborationParent.value = action.payload.collaborationParent;
            state.collaborationParent.error = '';
        },
        handleChangeSearch: (state, action) => {
            state[action.payload.field] = action.payload.value;            
        },
        handleChangeForm: (state, action) => {
            state[action.payload.field].error = '';            
            state[action.payload.field].value = action.payload.value;            
        },
        handleModelErrors: (state, action) => {
            Object.entries(action.payload).forEach(([field, errors]) => { 
                state[field].error = errors[0];
            });
        },
        handleInsertSuccess: (state, action) => {
            state.usersList.push({
                id: action.payload.id,
                userName: state.userName.value,
                firstName: state.firstName.value,
                lastName: state.lastName.value,
                status: state.status.value,
                collaborationAll: state.collaborationAll.value,
                collaborationParent: state.collaborationParent.value,
                email: state.userName.value,
            });

            state.usersAmount = action.payload.usersAmount;
            state.licenseAmount = action.payload.licenseAmount;
            state.userId = null;
            state.userName.value = '';
            state.userName.error = '';
            state.firstName.value = '';
            state.firstName.error = '';
            state.lastName.value = '';
            state.lastName.error = '';
            state.status.value = true;
            state.status.error = true;
            state.collaborationAll.value = false;
            state.collaborationAll.error = '';
            state.collaborationParent.value = false;
            state.collaborationParent.error = '';
            state.isDialogOpen = false;
        },
        handleUpdateSuccess: (state, action) => {

            state.usersList.forEach(value => {
                if (value.id === state.userId) {
                    value.userName = state.userName.value;
                    value.firstName = state.firstName.value;
                    value.lastName = state.lastName.value;
                    value.status = state.status.value;
                    value.collaborationAll = state.collaborationAll.value;
                    value.collaborationParent = state.collaborationParent.value;
                }
            });
            
            state.userId = null;
            state.userName.value = '';
            state.firstName.value = '';
            state.lastName.value = '';
            state.status.value = true;
            state.collaborationAll.value = false;
            state.collaborationParent.value = false;
            state.isDialogOpen = false;
        },
        setLoading: (state, action) => {
            
            if (action.payload)
                state.usersList = [];

            state.loadingList = action.payload;
        },
        fillList: (state, action) => {
            state.usersList = action.payload.additionalUsers;
            state.licenseAmount = action.payload.licenseAmount;
            state.usersAmount = action.payload.usersAmount;
        },
        closeDialog: (state, action) => {
            state.isDialogOpen = false;
        },
        handleClearSearch: (state, action) => {
            state.searchTerm = '';
        },
        handleOpenDialogRemove: (state, action) => {
            state.isDialogRemoveOpen = true;
            state.userId = action.payload;
        },
        handleCloseDialogRemove: (state, action) => {
            state.isDialogRemoveOpen = false;
            state.userId = null;
        }
    },
});

export const { 
    handleAddNewUser,
    handleEditUser,
    handleChangeSearch,
    handleChangeForm,
    setLoading,
    fillList,
    closeDialog,
    handleModelErrors,
    handleInsertSuccess,
    handleUpdateSuccess,
    handleClearSearch,
    handleOpenDialogRemove,
    handleCloseDialogRemove
} = additionalUsersSlice.actions;

export default additionalUsersSlice.reducer;
