import { emptySplitApi } from './index'

export const subscriptionPlanApi = emptySplitApi.injectEndpoints({
  endpoints: (builder) => ({
    getPublicPlans: builder.query({
        query: () => `api/v1/auth-service/plan/public` ,
    }),
    getPlans: builder.query({
        query: () => `api/v1/auth-service/plan` ,
    }),
    createSubscriptionPlan : builder.mutation({
      query: (model) => ({
          url: `api/v1/auth-service/plan/create-subscription-plan`,
          method: "POST",
          body: model
      })
    }),
    getSubscriptionPlan: builder.mutation({
      query: (subscriptionPlanId) => ({
          url: `api/v1/auth-service/plan/subscription-plan/${subscriptionPlanId}`,
          method: "GET",
      }),
    }),
    getSubscriptionPlanFormatted: builder.mutation({
      query: (subscriptionPlanId) => ({
          url: `api/v1/auth-service/plan/subscription-plan-formatted/${subscriptionPlanId}`,
          method: "GET",
      }),
    }),
    updateSubscriptionPlan: builder.mutation({
      query: (model) => ({
          url: `api/v1/auth-service/plan/edit-subscription-plan/`,
          method: "PATCH",
          body: model
      }),
    }),
    getAllSubscriptionPlansPagedList: builder.mutation({
      query: (model) => ({
          url: `api/v1/auth-service/plan/all-subscription-plan?`
              + `searchTerm=${model?.searchTerm || ''}`
              + `&status=${model?.status}`
              + `&pageNumber=${model?.pageNumber || 0}`
              + `&pageSize=${model?.pageSize || ''}`
              + `&orderField=${model?.orderField || 'subscriptionPlanName'}`
              + `&orderDirection=${model?.orderDirection || 'asc'}`,
          method: "GET"
      }),
  }),
  deleteSubscriptionPlan: builder.mutation({
    query: (subscriptionPlanId) => ({
      url: `api/v1/auth-service/plan/${subscriptionPlanId}`,
      method: "DELETE",
    }),
  }),
  applySubscriptionPlanTax: builder.mutation({
    query: (model) => ({
      url: `api/v1/auth-service/plan/apply-subscription-plan-tax`,
      method: "POST",
      body: model
    }),
  }),

  }),
  overrideExisting: false,
});

export const { 
      useGetPublicPlansQuery, 
      useGetPlansQuery, 
      useCreateSubscriptionPlanMutation,
      useGetSubscriptionPlanMutation,
      useGetSubscriptionPlanFormattedMutation,
      useUpdateSubscriptionPlanMutation,
      useGetAllSubscriptionPlansPagedListMutation,
      useDeleteSubscriptionPlanMutation,
      useApplySubscriptionPlanTaxMutation
} = subscriptionPlanApi;
