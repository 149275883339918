import { emptySplitApi } from './index'

const letterHeadApi = emptySplitApi.injectEndpoints({
  endpoints: (builder) => ({
    saveLetterHead: builder.mutation({
      query: ({sharedLetterHeadType, model}) => ({
        url: `api/v1/auth-service/letter-head/${sharedLetterHeadType}`,
        method: "POST",
        body: model,
      }),
    }),
    getLetterHead: builder.mutation({
      query: (sharedLetterHeadType) => ({
        url: `api/v1/auth-service/letter-head/${sharedLetterHeadType}`,
        method: "GET",
      }),
    }),
    saveLetterHeadFooterOnly: builder.mutation({
        query: ({ sharedLetterHeadType, letterType }) => ({
            url: `api/v1/auth-service/letter-head/${sharedLetterHeadType}/save-type/${letterType}`,
            method: "POST",
        }),
    }),
  }),
  overrideExisting: false,
});

export const { 
    useSaveLetterHeadMutation, 
    useGetLetterHeadMutation,
    useSaveLetterHeadFooterOnlyMutation
} = letterHeadApi;
