import React from 'react';
import NumberFormat from 'react-number-format';

const NumberFormatWrapper = React.forwardRef(function NumberFormatWrapper(props, ref) {
    const { onChange, ...other } = props;
    return (
      <NumberFormat
        {...other}
        getInputRef={ref}
        onValueChange={(values) => {
            onChange({
                target: {
                    name: props.name,
                    value: values.value,
                },
            });
        }}
        thousandSeparator
        prefix= { props.prefix }
        decimalScale={0}
        allowNegative={false}
        isNumericString={false}
        allowEmptyFormatting={true}
        type="tel"
      />
    );

});
  
export default NumberFormatWrapper;