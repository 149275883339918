import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";

export const emptySplitApi = createApi({
  reducerPath: "grantAdvanceApi",
  baseQuery: fetchBaseQuery({
    baseUrl: process.env.REACT_APP_API_URL,
    prepareHeaders: (headers, { getState }) => {
      
      //const rawToken = getState().componentsState?.token || null;
      
      const rawToken = localStorage.getItem("token") || null;

      headers.append("ClientTimeZone", new Date().getTimezoneOffset());

      if (rawToken !== null) {
        headers.append("Authorization", `Bearer ${rawToken}`);
      }
      else {
        headers.append("Access-Control-Allow-Origin", "*");
      }

      return headers;
    }
  }),
  endpoints: () => ({}),
});


export const previewSplitApi = createApi({
    reducerPath: "grantAdvancePreviewApi",
    baseQuery: fetchBaseQuery({
      baseUrl: process.env.REACT_APP_PREVIEW_API_URL,
      prepareHeaders: (headers, { getState }) => {
        
        //const rawToken = getState().componentsState?.token || null;
        
        const rawToken = localStorage.getItem("token") || null;
  
        if (rawToken !== null) {
          headers.append("Authorization", `Bearer ${rawToken}`);
        }
        else {
          headers.append("Access-Control-Allow-Origin", "*");
        }
  
        return headers;
      }
    }),
    endpoints: () => ({}),
  });