import i18n from "i18next";
import { initReactI18next } from "react-i18next";
import LanguageDetector from "i18next-browser-languagedetector";
 
import { TRANSLATIONS_EN_CA } from './en-CA/translations';
import { TRANSLATIONS_EN_US } from "./en-US/translations";
import { TRANSLATIONS_ES_MX } from "./es-MX/translations";
import { TRANSLATIONS_FR_CA } from "./fr-CA/translations";

const lang = localStorage.getItem('i18nextLng');

i18n
 .use(LanguageDetector)
 .use(initReactI18next)
 .init({
   lng: "en-CA",
   resources: {
     "en-CA": {
       translation: TRANSLATIONS_EN_CA
     },
     "en-US": {
      translation: TRANSLATIONS_EN_US
    },
    "es-MX": {
      translation: TRANSLATIONS_ES_MX
    },
    "fr-CA": {
      translation: TRANSLATIONS_FR_CA
    },
     
   }
 });
 
 if (lang) {
  i18n.changeLanguage(lang); 
 }

//i18n.changeLanguage("fr-CA");
//i18n.changeLanguage("es-MX");