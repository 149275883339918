import { useRef } from "react";
import {
  Stack,
  Button,
  Typography,
  Tooltip,
} from "@mui/material";
import { Cancel, HelpOutline } from "@mui/icons-material";

const FileUploadView = ({
    children,
    documents,
    onDocumentChange,
    accept="*",
    tips,
    multiple = false,
    incremental = false
}) => {

    const inputRef = useRef();

    const handleSetSelectedDocuments = (element) => {
        const files = element.target.files;

        if (files != null && files.length > 0) {
            const _documents = Array.from(files).map(file => ({
                previewUrl: URL.createObjectURL(file),
                blob: file,
            }));

            if(incremental)
                onDocumentChange([...documents, ..._documents]);
            else
                onDocumentChange([..._documents]);
        }
    }

    return (
        <div>
            <Stack spacing={1} alignItems="flex-start">
                <input 
                    ref={inputRef} 
                    accept={accept} 
                    type="file"
                    multiple={multiple} 
                    style={{ display: "none" }} 
                    onChange={(e) => handleSetSelectedDocuments(e)} 
                />
                <Button 
                    variant="outlined"
                    size="small" 
                    onClick={() => inputRef.current?.click()}
                >
                    {children}
                </Button>
                {tips}
            </Stack>
            {
                documents.length > 0 &&
                <Stack direction="row" gap={1} mt={2} flexWrap="wrap">
                    {
                        documents.map((document, i) => (
                            <Button 
                                key={document.previewUrl + i} 
                                size="small" 
                                variant="outlined"
                                endIcon={<Cancel onClick={e => { 
                                        e.stopPropagation();
                                        onDocumentChange([...documents.filter(o => o !== document)])}
                                    }
                                />} 
                                disableRipple
                                onClick={() => window.open(document.previewUrl)}
                                sx={{ maxWidth: 200 }}
                            >
                                <Typography 
                                    variant="h6" 
                                    color="grey.500" 
                                    sx={{ 
                                        whiteSpace: 'nowrap', 
                                        overflow: 'hidden', 
                                        textOverflow: 'ellipsis'}}
                                    >
                                        {document.blob.name}
                                </Typography>
                            </Button>
                        ))
                    }
                </Stack>
            }
        </div>
    )
}

export default FileUploadView;