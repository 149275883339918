import { emptySplitApi } from './index'

export const signatureApi = emptySplitApi.injectEndpoints({
  endpoints: (builder) => ({
    getSignature: builder.mutation({
      query: () => ({
        url: `api/v1/auth-service/signature`,
        method: "GET",
      }),
      
    }),
    saveSignature: builder.mutation({
      query: (model) => ({
        url: `api/v1/auth-service/signature`,
        method: "POST",
        body: model,
      }),
    }),
  }),
  overrideExisting: false,
});

export const { useGetSignatureMutation, useSaveSignatureMutation } = signatureApi;
