import { createSlice } from "@reduxjs/toolkit";
import { feedbackApi } from "Services/FeedbackService";

export const initialState = {
    loadingList: false,
    pagedList: {
        pageNumber: 0,
        pageSize: 10,
        pageCount: 0,
        totalCount: 0,
        orderField: 'reviewed',
        orderDirection: 'asc',
        items: []
    },
    search: {
        searchTerm: '',
        feedbackType: 'All'
    },
};

const manageFeedbackSlice = createSlice({
    name: "manage-feedback",
    initialState,
    reducers: {
        handleSearchChange: (state, action) => {
            state.search[action.payload.field] = action.payload.value;
        },
        handleModelErrors: (state, action) => {
            Object.entries(action.payload).forEach(([field, errors]) => { 
                state.couponValues[field].error = errors[0];
            });
        },
    },
    extraReducers: (builder) => {
        builder.addMatcher(
            feedbackApi.endpoints.getFeedbackPagedList.matchFulfilled, (state, action) => {
                state.loadingList = false;
                state.pagedList.pageNumber = action.payload.pageNumber;
                state.pagedList.pageSize = action.payload.pageSize;
                state.pagedList.pageCount = action.payload.pageCount;
                state.pagedList.totalCount = action.payload.totalCount;
                state.pagedList.orderField = action.payload.orderField;
                state.pagedList.orderDirection = action.payload.orderDirection;
                state.pagedList.items = action.payload.items;
            }
        ).addMatcher(
            feedbackApi.endpoints.getFeedbackPagedList.matchPending, (state, action) => {
                state.loadingList = true;
            }
        )
        .addMatcher(
            feedbackApi.endpoints.getFeedbackPagedList.matchRejected, (state, action) => {
                state.loadingList = false;
            }
        )
    }
});

export const {
    handleModelErrors,
    handleSearchChange,
} = manageFeedbackSlice.actions;

export default manageFeedbackSlice.reducer;