import { createSlice } from "@reduxjs/toolkit";

export const initialState = {
    document: '',
    currentSignature: '',
    firstTime: true
};

const signatureSlice = createSlice({
    name: "signature",
    initialState,
    reducers: {
        handleChange: (state, action) => {
            state.document = action.payload;

            if (state.firstTime) {
                state.currentSignature = action.payload;
                state.firstTime = false;
            }
        },
        handleGet: (state, action) => {
            state.document = action.payload;
            state.currentSignature = action.payload;
            state.firstTime = true;
        },
        handleSaveSuccess: (state, action) => {
            state.document = action.payload.data;
            state.currentSignature = action.payload.data;
            state.firstTime = true;
        }
    },
});

export const { 
    handleChange,
    handleSaveSuccess,
    handleGet
} = signatureSlice.actions;

export default signatureSlice.reducer;
