import 'react-quill/dist/quill.snow.css';

import { useSelector, useDispatch } from 'react-redux';
import LoadingButton from '@mui/lab/LoadingButton';
import { Skeleton, Grid } from '@mui/material';
import ReactQuill, { Quill } from 'react-quill';
import QuillResize from 'quill-resize-module';

import { useGetSignatureMutation, useSaveSignatureMutation } from "Services/SignatureService";
import { handleChange, handleSaveSuccess, handleGet } from "./Signature.Slice";
import { showSuccessToaster, showErrorToaster } from "store/Shared.Slice";
import { useTranslation } from "react-i18next";
import { useEffect } from 'react';
import { usePrompt } from 'react-router-dom';

Quill.register('modules/resize', QuillResize);

const SignaturePage = () => {

    const { document, currentSignature } = useSelector((state) => state.signatureState);
    const dispatch = useDispatch();
    const [getSignature, { isLoading }] = useGetSignatureMutation();
    const [saveSignature, { isLoading: isSaving }] = useSaveSignatureMutation();
    const { t } = useTranslation();

    const modules = {
        toolbar: [
            [{ 'header': [1, 2, false] }],
            ['bold', 'italic', 'underline','strike', 'blockquote'],
            [{'list': 'ordered'}, {'list': 'bullet'}, {'indent': '-1'}, {'indent': '+1'}],
            ['link', 'image'],
            ['clean']
        ],
        resize: { modules: [ 'Resize' ]}
    };
    
    const formats = [
        'header',
        'bold', 'italic', 'underline', 'strike', 'blockquote',
        'list', 'bullet', 'indent',
        'link', 'image'
    ];

    usePrompt(
        "You have unsaved changes. Are you sure to discard all changes made?",
        currentSignature !== document
    );
    
    
    const handleSaveChanges = async () => {

        try {
            const model = new FormData();
            model.append('document', document);

            const response = await saveSignature(model).unwrap();

            dispatch(handleSaveSuccess(response));
            dispatch(showSuccessToaster(response.message));

        }
        catch (error) {
            // Bad Request
            if (error.data?.message != null) {
                dispatch(showErrorToaster(error.data?.message));
            }
        }
    };

    const handleGetSignature = async() => {
        const response = await getSignature().unwrap();
        dispatch(handleGet(response.data));
    }

    useEffect(() => {
        handleGetSignature();
        // eslint-disable-next-line react-hooks/exhaustive-deps 
    }, []);

    return(
        <>
            <LoadingButton
                sx={{ mb: 4 }}
                disableElevation
                size="large"
                type="button"
                variant="contained"
                color="secondary"
                onClick={handleSaveChanges}
                loading={isLoading || isSaving}
            >
                {t('buttons.save_changes')}
            </LoadingButton>

            {isLoading &&
                <Grid container>
                    <Grid item xs={12} sm={12} md={12}>
                        <Skeleton height={200} />
                    </Grid>
                </Grid>
            }

            <ReactQuill 
                theme="snow" 
                value={document} 
                onChange={(value) => dispatch(handleChange(value))}
                modules={modules}
                formats={formats}
            />
        </>
    )

}

export default SignaturePage;