import { createSlice } from "@reduxjs/toolkit";
import { userProjectApi } from "Services/UserProjectService";
import { inquiryLetterApi } from "Services/InquiryLetterService";

export const initialState = {
    id: null,
    userProject: {
        id: null,
        value: null,
        label: null
    },
    userProjectToChange: {
        id: null,
        value: null,
        label: null
    },
    allProjectList: [],
    activeStep: 0,
    inquiryDocument: {
        introduction: '',
        describeOrganization: '',
        needStatement: '',
        methodology: '',
        otherFunding: '',
        summary: ''
    },
    stepList: [],
    forceDisabled: false,
    autosaveEnabled: false,
    timeoutId: null,
};

const inquirySlice = createSlice({
    name: "document-generator-inquiry",
    initialState,
    reducers: {
        handleChangeUserProject: (state, action) => {
            if (action.payload !== null) {
                state.userProjectToChange = state.allProjectList.find(o => o.id === action.payload.id);
            }
        },
        handleConfirmChange: (state, action) => {
            state.userProject = state.userProjectToChange;
        },
        handleStep: (state, action) => {
            state.activeStep = action.payload;
        },
        handleChangeDocument: (state, action) => {
            state.inquiryDocument[action.payload.stepName] = action.payload.document;
        },
        handleSaveSuccess: (state, action) => {
            state.id = action.payload.data;
        },
        handleAutoSaveEnabled: (state, action) => {
            state.autosaveEnabled = action.payload;
        },
        handleClear: (state, action) => {
            state.id = null;
            state.activeStep = 0;
            state.userProject.id = null;
            state.userProject.value = null;
            state.userProject.label = null;
            state.autosaveEnabled = false;
            state.inquiryDocument.introduction = "";
            state.inquiryDocument.describeOrganization = "";
            state.inquiryDocument.needStatement = "";
            state.inquiryDocument.methodology = "";
            state.inquiryDocument.otherFunding = "";
            state.inquiryDocument.summary = "";
        },
        handleFillAllProjects: (state, action) => {
            const { selectedProject, projects } = action.payload;
            state.forceDisabled = action.payload.length === 0;
            state.autosaveEnabled = false;
            state.allProjectList = projects ?? [];
            state.userProject = selectedProject;
        },
    },
    extraReducers: (builder) => {
        builder
        .addMatcher(
            inquiryLetterApi.endpoints.getStepList.matchFulfilled, (state, action) => {
                state.stepList = action.payload;
            }
        )
        .addMatcher(
            inquiryLetterApi.endpoints.getInquiryLetter.matchFulfilled, (state, action) => {
                state.id = action.payload.id;
                state.inquiryDocument.introduction = action.payload.introduction;
                state.inquiryDocument.describeOrganization = action.payload.describeOrganization;
                state.inquiryDocument.needStatement = action.payload.needStatement;
                state.inquiryDocument.methodology = action.payload.methodology;
                state.inquiryDocument.otherFunding = action.payload.otherFunding;
                state.inquiryDocument.summary = action.payload.summary;
            }
        )
    }

});

export const { 
    handleChangeUserProject,
    handleStep,
    handleChangeDocument,
    handleSaveSuccess,
    handleAutoSaveEnabled,
    handleClear,
    handleConfirmChange,
    handleFillAllProjects,
} = inquirySlice.actions;

export default inquirySlice.reducer;
