import { createSlice } from "@reduxjs/toolkit";
import { projectBudgetApi } from "Services/ProjectBudgetService";

export const initialState = {
    id: null,
    projectDescription: '',
    budgetDescription: '',
    description: {
        value: '',
        error: ''
    },
    categoryDescription: {
        value: '',
        error: ''
    },
    amount: {
        value: '',
        error: ''
    },
    checkAll: false,
    openBudgetDialog: false,
    openResetDialog: false,
    openDeleteSelectedDialog: false,
    openDeleteAllDialog: false,
    openDeleteOneDialog: false,
    incomePagedList: {
        pageNumber: 0,
        pageSize: 10,
        pageCount: 0,
        totalCount: 0,
        orderField: 'description',
        orderDirection: 'desc',
        items: []
    },
    incomeSelected: [],
    excludedSelected: []
};

const incomeSlice = createSlice({
    name: "project-income",
    initialState,
    reducers: {
        setProjectDescription: (state, action) => {
            state.projectDescription = action.payload;
        },
        handleOpenBudgetDialog: (state, action) => {
            state.openBudgetDialog = action.payload;
        },
        handleCloseDialog: (state, action) => {
            state.openBudgetDialog = false;
            state.openResetDialog = false;
            state.openDeleteSelectedDialog = false;
            state.openDeleteAllDialog = false;
            state.openDeleteOneDialog = false;
        },
        handleChange: (state, action) => {
            state[action.payload.field].error = '';
            state[action.payload.field].value = action.payload.value; 
        },
        handleNewBudget: (state, action) => {
            state.id = null;
            state.description.value = '';
            state.description.error = '';
            state.categoryDescription.value = '';
            state.categoryDescription.error = '';
            state.amount.value = '';
            state.amount.error = '';
            state.openBudgetDialog = true;
        },
        handleModelErrors: (state, action) => {
            Object.entries(action.payload).forEach(([field, errors]) => { 
                state[field].error = errors[0];
            });
        },
        handleOpenResetDialog: (state, action) => {
            state.openResetDialog = true;
        },
        handleOpenDeleteSelectedDialog: (state, action) => {
            state.openDeleteSelectedDialog = true;
        },
        handleOpenDeleteAllDialog: (state, action) => {
            state.openDeleteAllDialog = true;
        },
        handleOpenDeleteOneDialog: (state, action) => {
            state.id = action.payload.id;
            state.budgetDescription = action.payload.description;
            state.openDeleteOneDialog = true;
        },
        handleOpenEditDialog: (state, action) => {
            state.id = action.payload.id;
            state.description.value = action.payload.description;
            state.description.error = '';
            state.categoryDescription.value = action.payload.categoryDescription || '';
            state.categoryDescription.error = '';
            state.amount.value = action.payload.amount;
            state.amount.error = '';
            state.openBudgetDialog = true;
        },
        handleClearSelection : (state, action) => {
            state.incomeSelected = [];
            state.excludedSelected = [];
            state.checkAll = false;
        },
        handleSelectItem: (state, action) => {

            if (state.incomeSelected.indexOf(action.payload) === -1) {
                state.incomeSelected.push(action.payload);
            }
            else {
                state.incomeSelected = state.incomeSelected.filter(item => item !== action.payload);
            }

            if (state.checkAll) {
                const indeterminated = state.incomeSelected.length > 0 && state.incomeSelected.length < state.incomePagedList.totalCount;

                if (indeterminated) {
                    state.excludedSelected.push(action.payload);
                }
            }
            
        },
        handleToggleAll: (state, action) => {
            let copySelected = [...state.incomeSelected];
            const excludedItems = [];

            state.incomePagedList.items.forEach(item => {
                const existsIndex = copySelected.indexOf(item.id);
                
                if (existsIndex === -1) {
                    copySelected.push(item.id);
                }
                else {
                    copySelected.splice(existsIndex, 1);
                    excludedItems.push(item.id);
                }
            });

            if (copySelected.length === state.incomePagedList.totalCount) {
                state.checkAll = !state.checkAll;
            }

            const indeterminated = state.checkAll && copySelected.length > 0 && copySelected.length < state.incomePagedList.totalCount;

            if (indeterminated) {
                excludedItems.forEach(item => {
                    state.excludedSelected.push(item);
                });
            }

            state.incomeSelected = copySelected;
        },
        handleToggleCheckExclusive: (state, action) => {
            const toggleCheck = !state.checkAll;
        
            if (toggleCheck) {
                const copyData = state.incomePagedList.items.map(item => (
                    item.id
                ));
        
                const length = copyData.length;
        
                for (let index = 0; index < state.incomePagedList.totalCount - length; index++) {
                    copyData.push('');
                };

                state.incomeSelected = copyData;
            }
            else {
                state.incomeSelected = [];
            }

            state.excludedSelected = [];
            state.checkAll = toggleCheck;
        },
        handlePageChangeWithCheckAll: (state, action) => {
            const copySelected = [...state.incomeSelected];

            state.incomePagedList.items.forEach(item => {
                const emptyIndex = copySelected.indexOf('');

                if (emptyIndex !== -1) {
                    copySelected[emptyIndex] = item.id;
                } 
            });

            state.incomeSelected = copySelected;
        },
    },
    extraReducers: (builder) => {
        builder.addMatcher(
            projectBudgetApi.endpoints.getAllIncomePagedList.matchFulfilled, (state, action) => {
                state.incomePagedList.pageNumber = action.payload.pageNumber;
                state.incomePagedList.pageSize = action.payload.pageSize;
                state.incomePagedList.pageCount = action.payload.pageCount;
                state.incomePagedList.totalCount = action.payload.totalCount;
                state.incomePagedList.orderField = action.payload.orderField;
                state.incomePagedList.orderDirection = action.payload.orderDirection;
                state.incomePagedList.items = action.payload.items;
            }
        )
    }

});

export const { 
    setProjectDescription,
    handleOpenBudgetDialog,
    handleCloseDialog,
    handleChange,
    handleNewBudget,
    handleModelErrors,
    handleBudgetSelection,
    handleOpenResetDialog,
    handleOpenDeleteSelectedDialog,
    handleOpenDeleteAllDialog,
    handleOpenDeleteOneDialog,
    handleOpenEditDialog,
    handleClearSelection,
    handleCheckAllSelection,
    handleSelectItem,
    handleToggleAll,
    handleToggleCheckExclusive,
    handlePageChangeWithCheckAll
} = incomeSlice.actions;

export default incomeSlice.reducer;
