import { createSlice } from "@reduxjs/toolkit";
import { subscriptionPlanApi } from 'Services/SubscriptionPlanService';
import { countryApi } from 'Services/CountryService';

export const initialState = {
    openPopupPayment: false,
    userNameOrEmail: {
      value: '',
      error: ''
    },
    password: {
      value: '',
      error: ''
    },
    coupon: null,
    subscriptionPlanList: [],
    countryList: [],
    selectedCountry: {
        id: null,
        code: null,
        description: null,
        provinceOrStateList: []
    },
    selectedCountryBilling: {
        id: null,
        code: null,
        description: null,
        provinceOrStateList: []
    },
    selectedProvinceOrState: {
        id: null,
        code: null,
        description: null,
    },
    selectedProvinceOrStateBilling: {
        id: null,
        code: null,
        description: null,
    },
    firstName: {
        value: '',
        error: ''
    },
    lastName: {
        value: '',
        error: ''
    },
    phoneNumber: {
        value: '',
        error: ''
    },
    userNameRegister: {
        value: '',
        error: ''
    },
    passwordRegister: {
      value: '',
      error: ''
    },
    email: {
        value: '',
        error: ''
    },
    subscriptionPlanName: {
        value: '',
        error: '',
    },
    companyName: {
        value: '',
        error: ''
    },
    addressLine: {
        value: '',
        error: ''
    },
    addressLine2: {
        value: '',
        error: ''
    },
    city: {
        value: '',
        error: ''
    },
    provinceOrState: {
        value: null,
        error: ''
    },
    postalCode: {
        value: '',
        error: ''
    },
    country: {
        value: '',
        error: ''
    },
    county: {
      value: '',
      error: ''
    },
    addressLineBilling: {
      value: '',
      error: ''
    },
    addressLine2Billing: {
      value: '',
      error: ''
    },
    countryBilling: {
      value: '',
      error: ''
    },
    countyBilling: {
      value: '',
      error: ''
    },
    postalCodeBilling: {
      value: '',
      error: ''
    },
    provinceOrStateBilling: {
      value: '',
      error: ''
    },
    cityBilling: {
      value: '',
      error: ''
    },
    useSameAddress: {
      value: false,
      error: ''
    },
    userNameRecovery: {
        value: '',
        error: ''
    },
    emailRecovery: {
        value: '',
        error: ''
    },
    newPassword: {
      value: '',
      error: ''
    },
    confirmPassword: {
        value: '',
        error: ''
    }
};

const authSlice = createSlice({
  name: "auth",
  initialState,
  reducers: {
    handleOpenPopupPayment: (state, action) => {
        state.openPopupPayment = true;
    }, 
    handleClosePopupPayment: (state, action) => {
        state.openPopupPayment = false;
    },
    handleChange: (state, action) => {
      state[action.payload.field].error = '';
      state[action.payload.field].value = action.payload.value
    },
    handleModelErrors: (state, action) => {
      Object.entries(action.payload).forEach(([field, errors]) => { 
          state[field].error = errors[0];
      });
    },
    handleChangeCountry: (state, action) => {
        state.selectedCountry = state.countryList.find(o => o.description === action.payload);
        state.country.value = state.countryList.find(o => o.description === action.payload).description;
        state.selectedProvinceOrState = initialState.selectedProvinceOrState;
        state.provinceOrState = initialState.provinceOrState;
    },
    handleChangeCountryBilling: (state, action) => {
        state.selectedCountryBilling = state.countryList.find(o => o.description === action.payload);
        state.countryBilling.value = state.countryList.find(o => o.description === action.payload).description;
        state.selectedProvinceOrStateBilling = initialState.selectedProvinceOrStateBilling;
        state.provinceOrStateBilling = initialState.provinceOrStateBilling;
        
    },
    handleChangeProvinceOrState: (state, action) => {
        if (action.payload?.value) {
            state.selectedProvinceOrState = state.selectedCountry.provinceOrStateList.find(o => o.description === action.payload.value || '');
            state.provinceOrState.value = state.selectedCountry.provinceOrStateList.find(o => o.description === action.payload.value || '').description;
            state.provinceOrState.error = '';
        }
    },
    handleChangeProvinceOrStateBilling: (state, action) => {
        if (action.payload?.value) {
            state.selectedProvinceOrStateBilling = state.selectedCountryBilling.provinceOrStateList.find(o => o.description === action.payload.value || '');
            state.provinceOrStateBilling.value = state.selectedCountryBilling.provinceOrStateList.find(o => o.description === action.payload.value || '').description;
        }
    },
    handleChangeUseSameAddress: (state, action) => {
        state.useSameAddress.value = action.payload;
        state.useSameAddress.error = '';

        if (state.useSameAddress.value === true) {
            state.addressLineBilling.value = state.addressLine.value;
            state.addressLineBilling.error = '';

            state.addressLine2Billing.value = state.addressLine2.value;
            state.addressLine2Billing.error = '';

            state.selectedCountryBilling = { ...state.selectedCountry };
            state.countryBilling.value = state.country.value;
            state.countryBilling.error = '';
            
            state.postalCodeBilling.value = state.postalCode.value;
            state.postalCodeBilling.error = '';
            
            state.selectedProvinceOrStateBilling = { ...state.selectedProvinceOrState };
            state.provinceOrStateBilling.value = state.provinceOrState.value;
            state.provinceOrStateBilling.error = '';

            state.cityBilling.value = state.city.value;
            state.cityBilling.error = '';
        }
    },
    clearErrors: (state, action) => {
        Object.keys(initialState).forEach(field => {
            if (state[field]?.error !== undefined) {
                state[field].error = '';    
            }
        });
    },
    formReset: (state, action) => {
        state.firstName.value = '';
        state.firstName.error = '';
            
        state.lastName.value = '';
        state.lastName.error = '';
            
        state.phoneNumber.value = '';
        state.phoneNumber.error = '';

        state.userNameRegister.value = '';
        state.userNameRegister.error = '';

        state.passwordRegister.value = '';
        state.passwordRegister.error = '';
          
        state.email.value = '';
        state.email.error = '';
        
        state.subscriptionPlanName.value = '';
        state.subscriptionPlanName.error = '';

        state.companyName.value = '';
        state.companyName.error = '';

        state.addressLine.value = '';
        state.addressLine.error = '';

        state.addressLine2.value = '';
        state.addressLine2.error = '';

        state.city.value = '';
        state.city.error = '';

        state.provinceOrState.value = '';
        state.provinceOrState.error = '';

        state.postalCode.value = '';
        state.postalCode.error = '';

        state.country.value = '';
        state.country.error = '';

        state.county.value = '';
        state.county.error = '';

        state.addressLineBilling.value = '';
        state.addressLineBilling.error = '';

        state.addressLine2Billing.value = '';
        state.addressLine2Billing.error = '';

        state.countryBilling.value = '';
        state.countryBilling.error = '';

        state.countyBilling.value = '';
        state.countyBilling.error = '';

        state.postalCodeBilling.value = '';
        state.postalCodeBilling.error = '';

        state.provinceOrStateBilling.value = '';
        state.provinceOrStateBilling.error = '';

        state.cityBilling.value = '';
        state.cityBilling.error = '';

        state.useSameAddress.value = false;
        state.useSameAddress.error = '';

        state.selectedProvinceOrState = initialState.selectedProvinceOrState;
        state.selectedProvinceOrStateBilling = initialState.selectedProvinceOrStateBilling;
    },
    clearPasswordRecovery: (state, action) => {
        state.userNameRecovery.value = '';
        state.userNameRecovery.error = '';
        state.emailRecovery.value = '';
        state.emailRecovery.error = '';
        state.confirmPassword.value = '';
        state.confirmPassword.error = '';
        state.newPassword.value = '';
        state.newPassword.error = '';
    },
    setSubscriptionPlanList: (state, action) => {
        state.subscriptionPlanList = action.payload;
    },
    setCoupon: (state, action) => {
        state.coupon = action.payload;
    }
  },
  extraReducers: (builder) => {
    builder.addMatcher(
        subscriptionPlanApi.endpoints.getPublicPlans.matchFulfilled, (state, action) => {
            state.subscriptionPlanList = action.payload;
        }
    )
    .addMatcher(
        countryApi.endpoints.getCountryList.matchFulfilled, (state, action) => {
            state.countryList = action.payload;
        }
    )
  }
});

export const {
    handleOpenPopupPayment,
    handleClosePopupPayment, 
    handleChange, 
    handleModelErrors,
    handleChangeUseSameAddress,
    clearErrors,
    formReset,
    clearPasswordRecovery,
    handleChangeCountry,
    handleChangeCountryBilling,
    handleChangeProvinceOrState,
    handleChangeProvinceOrStateBilling,
    setSubscriptionPlanList,
    setCoupon,
} = authSlice.actions;

export default authSlice.reducer;
