import { createSlice } from "@reduxjs/toolkit";
import {subscriptionPlanApi} from "Services/SubscriptionPlanService"

export const initialState = {
    loadingList: false,
    openEnableDisableDialog: false,
    search: {
        searchTerm: '',
        status: ''
    },
    pagedList: {
        pageNumber: 0,
        pageSize: 10,
        pageCount: 0,
        totalCount: 0,
        orderField: 'createdAt',
        orderDirection: 'desc',
        items: []
    }
  };

const manageSubscriptionPlanSlice = createSlice({
    name: "manage-subscription-plans",
    initialState,
    reducers: {
        handleChange: (state, action) => {
            state.search[action.payload.field] = action.payload.value;
        },
        handleChangeField: (state, action) => {
            state[action.payload.field].error = '';
            state[action.payload.field].value = action.payload.value;            
        },
        handleOpenEnableDisableDialog: (state, action) => {
            state.openEnableDisableDialog = true;
        },
             
        handleModelErrors: (state, action) => {
            Object.entries(action.payload).forEach(([field, errors]) => { 
                state[field].error = errors[0];
            });
        },
    },
    extraReducers: (builder) => {
        builder.addMatcher(
            subscriptionPlanApi.endpoints.getAllUsersPagedList.matchFulfilled, (state, action) => {
                state.loadingList = false;
                state.pagedList.pageNumber = action.payload.pageNumber;
                state.pagedList.pageSize = action.payload.pageSize;
                state.pagedList.pageCount = action.payload.pageCount;
                state.pagedList.totalCount = action.payload.totalCount;
                state.pagedList.orderField = action.payload.orderField;
                state.pagedList.orderDirection = action.payload.orderDirection;
                state.pagedList.items = action.payload.items;
            }
        )
        .addMatcher(
            subscriptionPlanApi.endpoints.getPlans.matchPending, (state, action) => {
                state.loadingList = true;
            }
        )
        .addMatcher(
            subscriptionPlanApi.endpoints.getPlans.matchRejected, (state, action) => {
                state.loadingList = false;
            }
        )
    }

});

export const { 
    handleChange,
    handleChangeField,
    handleModelErrors,
    handleOpenEnableDisableDialog
} = manageSubscriptionPlanSlice.actions;

export default manageSubscriptionPlanSlice.reducer;