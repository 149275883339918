import { emptySplitApi } from "./index";

export const couponApi = emptySplitApi.injectEndpoints({
    endpoints: (builder) => ({
        getCouponPagedList: builder.mutation({
            query: (model) => ({
                url: `api/v1/auth-service/coupon?`
                        + `&ownCoupon=${model?.ownCoupon || false}`
                        + `&pageNumber=${model?.pageNumber || 0}`
                        + `&pageSize=${model?.pageSize || 10}`
                        + `&orderField=${model?.orderField || 'Name'}`
                        + `&orderDirection=${model?.orderDirection || 'asc'}`,
                method: "GET",
              }),
        }),
        getTemplateCouponList: builder.mutation({
            query: () => `api/v1/auth-service/coupon/templates`
        }),
        insertCoupon: builder.mutation({
            query: (model) => ({
                url: `api/v1/auth-service/coupon`,
                method: "POST",
                body: model,
            }),
        }),
        updateCoupon: builder.mutation({
            query: ({id, ...model}) => ({
                url: `api/v1/auth-service/coupon/${id}`,
                method: "PATCH",
                body: model,
            }),
        }),
        deleteCoupon: builder.mutation({
            query: (id) => ({
                url: `api/v1/auth-service/coupon/${id}`,
                method: "DELETE",
            }),
        }),
        pauseCoupon: builder.mutation({
            query: ({id, ...model}) => ({
                url: `api/v1/auth-service/coupon/${id}/paused`,
                method: "PATCH",
                body: model
            })
        }),
        getCouponPlans: builder.query({
            query: () => `api/v1/auth-service/coupon/plans`
        }),
    }),
    overrideExisting: false,
});

export const {
    useGetCouponPagedListMutation,
    useGetTemplateCouponListMutation,
    useInsertCouponMutation,
    useUpdateCouponMutation,
    useDeleteCouponMutation,
    usePauseCouponMutation,
    useGetCouponPlansQuery,
} = couponApi;
