import { emptySplitApi } from "./index";

export const alertNotificationApi = emptySplitApi.injectEndpoints({
    endpoints: (builder) => ({
        getNotifications: builder.mutation({
            query: () => ({
                url: `api/v1/auth-service/alert-notifications`,
                method: "GET",
            }),
        }),
        saveNotification: builder.mutation({
            query: (model) => ({
                url: `api/v1/auth-service/alert-notifications`,
                method: "POST",
                body: model,
            }),
        }),
        
    }),
    overrideExisting: false,
});

export const {
    useGetNotificationsMutation,
    useSaveNotificationMutation   
} = alertNotificationApi;
