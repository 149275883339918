import { createSlice } from "@reduxjs/toolkit";
import { countryApi } from 'Services/CountryService';
import { organizationProfileApi } from 'Services/OrganizationProfileService';

export const initialState = {
    countryList: [],
    selectedCountry: {
        id: null,
        code: null,
        description: null,
        provinceOrStateList: []
    },
    selectedProvinceOrState: {
        id: null,
        code: null,
        description: null,
    },
    companyName: {
        value: "",
        error: "",
    },
    phoneNumber: {
        value: "",
        error: "",
    },
    businessEin: {
        value: "",
        error: "",
    },
    addressLine: {
        value: "",
        error: "",
    },
    addressLine2: {
        value: "",
        error: "",
    },
    city: {
        value: "",
        error: "",
    },
    provinceOrState: {
        value: "",
        error: "",
    },
    country: {
        value: "",
        error: "",
    },
    county: {
        value: "",
        error: "",
    },
    postalCode: {
        value: "",
        error: "",
    },
    showPageLeave: false,
    currentProfileInfo: {
        companyName: '',
        phoneNumber: '',
        businessEin: '',
        addressLine: '',
        addressLine2: '',
        country: '',
        provinceOrState: '',
        city: '',
        postalCode: '',
    }
};

const organizationProfileSlice = createSlice({
    name: "organization-profile",
    initialState,
    reducers: {
        handleChange: (state, action) => {
            state[action.payload.field].error = "";
            state[action.payload.field].value = action.payload.value;
        },
        handleModelErrors: (state, action) => {
            Object.entries(action.payload).forEach(([field, errors]) => { 
                state[field].error = errors[0];
            });
        },
        handleGet : (state, action) => {
            Object.entries(action.payload).forEach(([field, value]) => { 
                if (state[field]) {
                    state[field].value = value || '';
                    state[field].error = '';
                }
            });

            state.currentProfileInfo = action.payload;
        },
        handleChangeCountry: (state, action) => {
            state.selectedCountry = state.countryList.find(o => o.description === action.payload);
            state.country.value = state.countryList.find(o => o.description === action.payload).description;
            state.selectedProvinceOrState = initialState.selectedProvinceOrState;
            state.provinceOrState = initialState.provinceOrState;
        },
        handleChangeProvinceOrState: (state, action) => {
            if (action.payload?.value) {
                state.selectedProvinceOrState = state.selectedCountry.provinceOrStateList.find(o => o.description === action.payload.value || '');
                state.provinceOrState.value = state.selectedCountry.provinceOrStateList.find(o => o.description === action.payload.value || '').description;
            }
        },
        handleFillCountryAndState: (state, action) => {
            const selectedCountry = state.countryList.find(o => o.description === action.payload.country);

            if (selectedCountry) {
                state.selectedCountry = selectedCountry;
                const selectedProvinceOrState = selectedCountry.provinceOrStateList.find(o => o.description === action.payload.provinceOrState);
                if (selectedProvinceOrState) {
                    state.selectedProvinceOrState = selectedProvinceOrState;
                }
            }
        },
    },
    extraReducers: (builder) => {
        builder.addMatcher(
            countryApi.endpoints.getCountryList.matchFulfilled, (state, action) => {
                state.countryList = action.payload;
            }
        )
        .addMatcher(
            organizationProfileApi.endpoints.updateOrganizationProfile.matchFulfilled, (state, action) => {
                state.currentProfileInfo.companyName = state.companyName.value;
                state.currentProfileInfo.phoneNumber = state.phoneNumber.value;
                state.currentProfileInfo.businessEin = state.businessEin.value;
                state.currentProfileInfo.addressLine = state.addressLine.value;
                state.currentProfileInfo.addressLine2 = state.addressLine2.value;
                state.currentProfileInfo.country = state.country.value;
                state.currentProfileInfo.provinceOrState = state.provinceOrState.value;
                state.currentProfileInfo.city = state.city.value;
                state.currentProfileInfo.postalCode = state.postalCode.value;
            }
        )
    }
});

export const { 
    handleChange,
    handleModelErrors,
    handleGet,
    handleChangeCountry,
    handleChangeProvinceOrState,
    handleFillCountryAndState,
    handleShowPageLeave
} = organizationProfileSlice.actions;

export default organizationProfileSlice.reducer;
