import { createSlice } from "@reduxjs/toolkit";
import { adminApi } from 'Services/AdminService'

export const initialState = {
    loadingList: false,
    openEnableDisableDialog: false,
    openExpirationDateDialog: false,
    openPipedriveImportDialog: false,
    userId: '',
    pipedriveOrganizationCode: {
        value: '',
        error: ''
    },
    isMasterUser: false,
    expirationDateDialog: {
        licenseExpirationDate: '',
        licenseDescription: '',
        expired: false,
        subscriptionPlanName: {
            value: '',
            error: ''
        },
        subscriptionPlanList: []
    },
    search: {
        searchTerm: '',
        status: ''
    },
    pagedList: {
        pageNumber: 0,
        pageSize: 10,
        pageCount: 0,
        totalCount: 0,
        orderField: 'createdAt',
        orderDirection: 'desc',
        items: []
    },
    additionalUsersList: [0,1,2,3,4,5,10,20],
    bestowAccount: {
        openPopup: false,
        userId: '',
        userName: '',
        organizationName: '',
        additionalUsersAmount: 0,
        licenseAmount: 0
    },
    restoreAccount: {
        openPopup: false,
        userId: '',
        userName: '',
    },
    reportUsage: {
        openPopup: false,
        userName: '',
    },
};

const manageAccountSlice = createSlice({
    name: "manage-account",
    initialState,
    reducers: {
        handleChange: (state, action) => {
            state.search[action.payload.field] = action.payload.value;
        },
        handleChangeField: (state, action) => {
            state[action.payload.field].error = '';
            state[action.payload.field].value = action.payload.value;            
        },
        handleOpenExpirationDateDialog: (state, action) => {
            state.userId = action.payload;
            state.openExpirationDateDialog = true;
        },
        handleCloseExpirationDateDialog: (state, action) => {
            state.userId = ''
            state.openExpirationDateDialog = false;
        },
        handleChangeSubscriptionPlanDialog: (state, action) => {
            state.expirationDateDialog.subscriptionPlanName.value = action.payload;
            state.expirationDateDialog.subscriptionPlanName.error = '';
            state.expirationDateDialog.expired = false;
            state.expirationDateDialog.licenseExpirationDate = state.expirationDateDialog.subscriptionPlanList.find(o => (o.subscriptionPlanName === action.payload)).expirationDate;
        },
        handleOpenEnableDisableDialog: (state, action) => {
            state.userId = action.payload.id;
            state.isMasterUser = action.payload.isMasterUser;
            state.openEnableDisableDialog = true;
        },
        handleCloseEnableDisableDialog: (state, action) => {
            state.userId = '';
            state.isMasterUser = false;
            state.openEnableDisableDialog = false;
        },
        handleOpenPipedriveImportDialog: (state, action) => {
            state.pipedriveOrganizationCode.value = '';
            state.pipedriveOrganizationCode.error = '';
            state.openPipedriveImportDialog = true;
        },
        handleClosePipedriveImportDialog: (state, action) => {
            state.openPipedriveImportDialog = false;
        },
        handleModelErrors: (state, action) => {
            Object.entries(action.payload).forEach(([field, errors]) => { 
                state[field].error = errors[0];
            });
        },
        handleOpenBestowAccount: (state, action) => {
            state.bestowAccount.openPopup = true;
            state.bestowAccount.userId = action.payload.id;
            state.bestowAccount.userName = action.payload.userName;
            state.bestowAccount.organizationName = action.payload.organization
            state.bestowAccount.licenseAmount = action.payload.licenseAmount;
            state.bestowAccount.additionalUsersAmount = action.payload.additionalUsersAmount;
        },
        handleCloseBestowAccount: (state, action) => {
            state.bestowAccount.openPopup = false;
            
        },
        handleOpenRestoreAccountDialog: (state, action) => {
            state.restoreAccount.userId = action.payload.id;
            state.restoreAccount.userName = action.payload.userName;
            state.restoreAccount.openPopup = true;
        },
        handleCloseRestoreAccountDialog: (state, action) => {
            state.restoreAccount.userId = '';
            state.restoreAccount.userName = '';
            state.restoreAccount.openPopup = false;
        },
        handleOpenUsageReportDialog: (state, action) => {
            state.reportUsage.openPopup = true;
            state.reportUsage.userName = action.payload.internalUserName;
        },
        handleCloseUsageReportDialog: (state, action) => {
            state.reportUsage.openPopup = false;
            state.reportUsage.userName = ''
        }
    },
    extraReducers: (builder) => {
        builder.addMatcher(
            adminApi.endpoints.getAllUsersPagedList.matchFulfilled, (state, action) => {
                state.loadingList = false;
                state.pagedList.pageNumber = action.payload.pageNumber;
                state.pagedList.pageSize = action.payload.pageSize;
                state.pagedList.pageCount = action.payload.pageCount;
                state.pagedList.totalCount = action.payload.totalCount;
                state.pagedList.orderField = action.payload.orderField;
                state.pagedList.orderDirection = action.payload.orderDirection;
                state.pagedList.items = action.payload.items;
            }
        )
        .addMatcher(
            adminApi.endpoints.getAllUsersPagedList.matchPending, (state, action) => {
                state.loadingList = true;
            }
        )
        .addMatcher(
            adminApi.endpoints.getAllUsersPagedList.matchRejected, (state, action) => {
                state.loadingList = false;
            }
        )
        .addMatcher(
            adminApi.endpoints.getExpirationDateDetails.matchFulfilled, (state, action) => {
                state.expirationDateDialog.licenseExpirationDate = action.payload.licenseExpirationDate;
                state.expirationDateDialog.licenseDescription = action.payload.licenseDescription;
                state.expirationDateDialog.expired = action.payload.expired;
                state.expirationDateDialog.subscriptionPlanName.value = action.payload.subscriptionPlanName;
                state.expirationDateDialog.subscriptionPlanName.error = '';
                state.expirationDateDialog.subscriptionPlanList = action.payload.subscriptionPlanList;
            }
        )
    }

});

export const { 
    handleChange,
    handleChangeField,
    handleCloseExpirationDateDialog,
    handleOpenExpirationDateDialog,
    handleChangeSubscriptionPlanDialog,
    handleOpenEnableDisableDialog,
    handleCloseEnableDisableDialog,
    handleOpenPipedriveImportDialog,
    handleClosePipedriveImportDialog,
    handleModelErrors,
    handleOpenBestowAccount,
    handleCloseBestowAccount,
    handleOpenRestoreAccountDialog,
    handleCloseRestoreAccountDialog,
    handleOpenUsageReportDialog,
    handleCloseUsageReportDialog
} = manageAccountSlice.actions;

export default manageAccountSlice.reducer;