import PropTypes from 'prop-types';
import { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';

// material-ui
import { useTheme } from '@mui/material/styles';
import {
    Avatar,
    ButtonBase,
    Drawer,
    Fab,
    FormControl,
    FormControlLabel,
    FormLabel,
    Grid,
    IconButton,
    Radio,
    RadioGroup,
    Slider,
    Stack,
    Switch,
    TextField,
    Tooltip,
    Typography,
    ListItemText,
    InputLabel,
    Select,
    MenuItem
} from '@mui/material';
import { IconChecks, IconSettings } from '@tabler/icons';

// third-party
import PerfectScrollbar from 'react-perfect-scrollbar';

// project imports
import SubCard from 'ui-component/cards/SubCard';
import AnimateButton from 'ui-component/extended/AnimateButton';
import { MENU_TYPE, SET_FONT_FAMILY } from 'store/actions';
import { gridSpacing } from 'store/constant';
import Flags from 'country-flag-icons/react/1x1'
import i18n from "i18next";
import Cookies from 'universal-cookie';

// color import
/*import colors from 'assets/scss/_themes-vars.module.scss';
import theme1 from 'assets/scss/_theme1.module.scss';
import theme2 from 'assets/scss/_theme2.module.scss';
import theme3 from 'assets/scss/_theme3.module.scss';
import theme4 from 'assets/scss/_theme4.module.scss';
import theme5 from 'assets/scss/_theme5.module.scss';
import theme6 from 'assets/scss/_theme6.module.scss';
import theme7 from 'assets/scss/_theme7.module.scss';
import theme8 from 'assets/scss/_theme8.module.scss';*/

// concat 'px'
function valueText(value) {
    return `${value}px`;
}

const PresetColor = ({ color, presetColor, setPresetColor }) => (
    <Grid item>
        <ButtonBase sx={{ borderRadius: '12px' }} onClick={() => setPresetColor(color.id)}>
            <Avatar
                variant="rounded"
                color="inherit"
                sx={{
                    background: `linear-gradient(135deg, ${color.primary} 50%, ${color.secondary} 50%)`,
                    opacity: presetColor === color.id ? 0.6 : 1
                }}
            >
                {presetColor === color.id && <IconChecks color="#fff" />}
            </Avatar>
        </ButtonBase>
    </Grid>
);

PresetColor.propTypes = {
    color: PropTypes.shape({
        id: PropTypes.string,
        primary: PropTypes.string,
        secondary: PropTypes.string
    }),
    presetColor: PropTypes.string,
    setPresetColor: PropTypes.func
};

// ==============================|| LIVE CUSTOMIZATION ||============================== //

const Customization = () => {
    const [currentLang, setCurrentLang] = useState(localStorage.getItem('i18nextLng'));
    const theme = useTheme();
    const dispatch = useDispatch();
    const customization = useSelector((state) => state.customization);

    const setCookieVal = (key, value) => {
        const cookies = new Cookies();
        cookies.set(key, value, { maxAge: 1000000, path: '/', sameSite: true });
     }
     const mapFontFamily = new Map([
        [`'Inter', sans-serif'`, 'Inter'],
        [`'Poppins', sans-serif`, 'Poppins'],
        [`'Roboto', sans-serif`, 'Roboto'],
      ]); 
    
    // drawer on/off
    const [open, setOpen] = useState(false);
    const handleToggle = () => {
        setOpen(!open);
    };

    // state - layout type
    const [navType, setNavType] = useState(customization.navType);

    const handleNavType = (value) => {
        setCookieVal('MENU_TYPE', value);
        setNavType(value);
    }

    
    useEffect(() => {
       const cookies = new Cookies();
       if(cookies.get('MENU_TYPE') !== undefined)
            setNavType(cookies.get('MENU_TYPE')) 

        dispatch({ type: MENU_TYPE, navType });
    }, [dispatch, navType]);
   
    if (customization.rtlLayout) {
        document?.querySelector('html')?.setAttribute('dir', 'rtl');
    } else {
        document?.querySelector('html')?.removeAttribute('dir');
    }

    let customizationFontFamily = customization.fontFamily;
    const cookies = new Cookies();
    let fontFamilyChange = cookies.get('SET_FONT_FAMILY') !== undefined ? cookies.get('SET_FONT_FAMILY') : customizationFontFamily;
     
    let initialFont = mapFontFamily.get(fontFamilyChange);

    // state - font family
    const [fontFamily, setFontFamily] = useState(initialFont);

    const handleFontFamily = (value) => {
        const key = getKeyByValue(mapFontFamily, value);
        setCookieVal('SET_FONT_FAMILY', key);
        setFontFamily(value);
    }

     // Function to get the key by value
     const getKeyByValue = (map, value) => {
        for (let [key, val] of map.entries()) {
        if (val === value) {
            return key;
        }
        }
        return null; // Return null if the value is not found
    };

    useEffect(() => {
        let newFont;
        
        switch (fontFamily) {
            case 'Inter':
                newFont = `'Inter', sans-serif`;
                break;
            case 'Poppins':
                newFont = `'Poppins', sans-serif`;
                break;
            case 'Roboto':
            default:
                newFont = `'Roboto', sans-serif`;
                break;
        }

        dispatch({ type: SET_FONT_FAMILY, fontFamily: newFont });
    }, [dispatch, fontFamily]);

	const changeLanguage = (event) => {
        const lng = event.target.value;
        setCurrentLang(lng)
        i18n.changeLanguage(lng);

        setCookieVal('SET_LANGUAGE', lng);
    }

    useEffect(() => {
        const cookies = new Cookies();
        if(cookies.get('SET_LANGUAGE') !== undefined){
            setCurrentLang(cookies.get('SET_LANGUAGE')) 
            i18n.changeLanguage(cookies.get('SET_LANGUAGE'));
        }
 
     }, [dispatch, currentLang]);

    return (
        <>
            {/* toggle button */}
            <Tooltip title="Live Customize">
                <Fab
                    component="div"
                    onClick={handleToggle}
                    size="medium"
                    variant="circular"
                    color="secondary"
                    sx={{
                        borderRadius: 0,
                        borderTopLeftRadius: '50%',
                        borderBottomLeftRadius: '50%',
                        borderTopRightRadius: '50%',
                        borderBottomRightRadius: '4px',
                        top: '25%',
                        position: 'fixed',
                        right: 10,
                        zIndex: theme.zIndex.speedDial,
                        boxShadow: theme.customShadows.secondary
                    }}
                >
                    <IconButton color="inherit" size="large" disableRipple>
                        <IconSettings />
                    </IconButton>
                    
                </Fab>
            </Tooltip>

            <Drawer
                anchor="right"
                onClose={handleToggle}
                open={open}
                PaperProps={{
                    sx: {
                        width: 280
                    }
                }}
            >
                <PerfectScrollbar component="div">
                    <Grid container spacing={gridSpacing} sx={{ p: 3 }}>
                        <Grid item xs={12}>
                            {/* layout type */}
                            <SubCard title="Layout">
                                <FormControl component="fieldset">
                                    <FormLabel component="legend">Mode</FormLabel>
                                    <RadioGroup
                                        row
                                        aria-label="layout"
                                        value={navType}
                                        onChange={(e) => handleNavType(e.target.value)}
                                        name="row-radio-buttons-group"
                                    >
                                        <FormControlLabel
                                            value="light"
                                            control={<Radio />}
                                            label="Light"
                                            sx={{
                                                '& .MuiSvgIcon-root': { fontSize: 28 },
                                                '& .MuiFormControlLabel-label': { color: theme.palette.grey[900] }
                                            }}
                                        />
                                        <FormControlLabel
                                            value="dark"
                                            control={<Radio />}
                                            label="Dark"
                                            sx={{
                                                '& .MuiSvgIcon-root': { fontSize: 28 },
                                                '& .MuiFormControlLabel-label': { color: theme.palette.grey[900] }
                                            }}
                                        />
                                    </RadioGroup>
                                </FormControl>
                            </SubCard>
                        </Grid>
                        <Grid item xs={12}>
                            {/* font family */}
                            <SubCard title="Font Family">
                                <FormControl>
                                    <RadioGroup
                                        aria-label="font-family"
                                        value={fontFamily}
                                        onChange={(e) => handleFontFamily(e.target.value)}
                                        name="row-radio-buttons-group"
                                    >
                                        <FormControlLabel
                                            value="Roboto"
                                            control={<Radio />}
                                            label="Roboto"
                                            sx={{
                                                '& .MuiSvgIcon-root': { fontSize: 28 },
                                                '& .MuiFormControlLabel-label': { color: theme.palette.grey[900] }
                                            }}
                                        />
                                        <FormControlLabel
                                            value="Poppins"
                                            control={<Radio />}
                                            label="Poppins"
                                            sx={{
                                                '& .MuiSvgIcon-root': { fontSize: 28 },
                                                '& .MuiFormControlLabel-label': { color: theme.palette.grey[900] }
                                            }}
                                        />
                                        <FormControlLabel
                                            value="Inter"
                                            control={<Radio />}
                                            label="Inter"
                                            sx={{
                                                '& .MuiSvgIcon-root': { fontSize: 28 },
                                                '& .MuiFormControlLabel-label': { color: theme.palette.grey[900] }
                                            }}
                                        />
                                    </RadioGroup>
                                </FormControl>
                            </SubCard>
                        </Grid>
                        <Grid item xs={12}>
                            {/* Language switcher */}
                            <SubCard title="Language">
                                <Grid item xs={12} container alignItems="center" mt={1}>
                                    <FormControl fullWidth>
                                        <InputLabel id="labelLang">Select</InputLabel>
                                        <Select
                                            id="lang"
                                            labelId="labelLang"
                                            label="Select"
                                            value={currentLang}
                                            onChange={changeLanguage}
                                        >
                                            <MenuItem value="en-CA">
                                                <Grid container justifyContent="space-between">
                                                    <Grid item>
                                                        <Avatar>
                                                            <Flags.CA title="Canadian - English" />
                                                        </Avatar>
                                                    </Grid>
                                                    <Grid item alignSelf="center">
                                                        <ListItemText>English</ListItemText>
                                                    </Grid>
                                                </Grid>
                                            </MenuItem>

                                            <MenuItem value="en-US">
                                                <Grid container justifyContent="space-between">
                                                    <Grid item>
                                                        <Avatar>
                                                            <Flags.US title="United States - English" />
                                                        </Avatar>
                                                    </Grid>
                                                    <Grid item alignSelf="center">
                                                        <ListItemText>English</ListItemText>
                                                    </Grid>
                                                </Grid>
                                            </MenuItem>

                                            <MenuItem value="es-MX">
                                                <Grid container justifyContent="space-between">
                                                    <Grid item>
                                                        <Avatar>
                                                            <Flags.MX title="Mexican - Spanish" />
                                                        </Avatar>
                                                    </Grid>
                                                    <Grid item alignSelf="center">
                                                        <ListItemText>Spanish</ListItemText>
                                                    </Grid>
                                                </Grid>
                                            </MenuItem>

                                            <MenuItem value="fr-CA">
                                                <Grid container justifyContent="space-between">
                                                    <Grid item>
                                                        <Avatar>
                                                            <Flags.CA title="Canadian - French" />
                                                        </Avatar>
                                                    </Grid>
                                                    <Grid item alignSelf="center">
                                                        <ListItemText>French</ListItemText>
                                                    </Grid>
                                                </Grid>
                                            </MenuItem>

                                        </Select>
                                    </FormControl>

                                </Grid>
                            </SubCard>
                        </Grid>
                    </Grid>
                </PerfectScrollbar>
            </Drawer>
        </>
    );
};

export default Customization;
