import { emptySplitApi } from './index'

export const organizationProfileApi = emptySplitApi.injectEndpoints({
  endpoints: (builder) => ({
    getOrganizationProfile: builder.mutation({
      query: () => ({
        url: `api/v1/auth-service/organization/profile`,
        method: "GET"
      }),
    }),
    getEmailsNotification: builder.mutation({
      query: () => ({
        url: `api/v1/auth-service/organization/profile/emails-notification`,
        method: "GET",
          
      }),
    }),
    updateOrganizationProfile: builder.mutation({
      query: (model) => ({
        url: `api/v1/auth-service/organization/profile`,
        method: "PATCH",
        body: model
      }),
    }),
  }),
  overrideExisting: false,
});

export const { useGetOrganizationProfileMutation, useUpdateOrganizationProfileMutation,  useGetEmailsNotificationMutation } = organizationProfileApi;
