import { emptySplitApi } from './index'

export const favoriteFundersApi = emptySplitApi.injectEndpoints({
    endpoints: (builder) => ({
        getFavoriteFundersPagedList: builder.mutation({
            query: (model) => ({
                url: `api/v1/foundation-service/favorite-funders?`
                    + `favoriteId=${model?.favoriteId || ''}`
                    + `&pageNumber=${model?.pageNumber || 0}`
                    + `&pageSize=${model?.pageSize || ''}`
                    + `&orderField=${model?.orderField || 'userName'}`
                    + `&orderDirection=${model?.orderDirection || 'asc'}`,
                method: "GET",
            }),
        }),
        moveFavoriteFunders: builder.mutation({
            query: ({destinationFavoriteId, favoriteFundersListId }) => ({
                url: `api/v1/foundation-service/favorite-funders/move${destinationFavoriteId === null ? '' : '/' + destinationFavoriteId}`,
                method: "POST",
                body: [...favoriteFundersListId]
            }),
        }),
        insertFavoriteFunders: builder.mutation({
            query: (model) => ({
                url: `api/v1/foundation-service/favorite-funders`,
                method: "POST",
                body: model
            }),
        }),
        deleteFavoriteFunders: builder.mutation({
            query: ({favoriteId, profileIdList}) => ({
                url: `api/v1/foundation-service/favorite-funders/${favoriteId}`,
                method: "DELETE",
                body: [...profileIdList]
            }),
        }),
        updateQualifyFunder: builder.mutation({
            query: (model) => ({
                url: `api/v1/foundation-service/favorite-funders/qualify/${model.favoriteFunderId}/${model.isQualified}`,
                method: "POST",
            }),
        }),
    }),
    overrideExisting: false,
});

export const { 
    useGetFavoriteFundersPagedListMutation,
    useInsertFavoriteFundersMutation,
    useDeleteFavoriteFundersMutation,
    useMoveFavoriteFundersMutation,
    useUpdateQualifyFunderMutation
} = favoriteFundersApi;
