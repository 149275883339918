import { emptySplitApi } from './index'

export const blockedFundersApi = emptySplitApi.injectEndpoints({
    endpoints: (builder) => ({
        getBlockedFundersList: builder.mutation({
            query: (model) => ({
                url: `api/v1/foundation-service/blocked-funders?`
                    + `userProjectId=${model?.userProjectId || ''}`
                    + `&pageNumber=${model?.pageNumber || 0}`
                    + `&pageSize=${model?.pageSize || ''}`
                    + `&orderField=${model?.orderField || 'userName'}`
                    + `&orderDirection=${model?.orderDirection || 'asc'}`,
                method: "GET",
            }),
        }),
        insertBlockedFunders: builder.mutation({
            query: (model) => ({
                url: `api/v1/foundation-service/blocked-funders`,
                method: "POST",
                body: model
            }),
        }),
        deleteBlockedFunders: builder.mutation({
            query: ({userProjectId, blockedFundersIdList}) => ({
                url: `api/v1/foundation-service/blocked-funders/${userProjectId}`,
                method: "DELETE",
                body: [...blockedFundersIdList]
            }),
        }),
        deleteAllBlockedFunders: builder.mutation({
            query: (userProjectId) => ({
                url: `api/v1/foundation-service/blocked-funders/delete-all/${userProjectId}`,
                method: "DELETE",
            }),
        }),
    }),
    overrideExisting: false,
});

export const { 
    useGetBlockedFundersListMutation,
    useInsertBlockedFundersMutation,
    useDeleteBlockedFundersMutation,
    useDeleteAllBlockedFundersMutation
} = blockedFundersApi;
