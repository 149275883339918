import { createSlice } from "@reduxjs/toolkit";
import { subscriptionPlanApi } from 'Services/SubscriptionPlanService'

export const initialState = {
    roleList: ['Admin', 'User', 'SubUser', 'supervisor', 'member', 'Demo', 'Sales', 'SuperAdmin', 'IT'],
    subscriptionPlanName: {
        value: '',
        error: ''
    },
    planDescription: {
        value: '',
        error: ''
    },
    displayplan: {
        value: '',
        error: ''
    },
    amountYears: {
        value: '',
        error: ''
    },
    amountMonths: {
        value: '',
        error: ''
    },
    amountDays: {
        value: '',
        error: ''
    },
    amountLicenses: {
        value: '',
        error: ''
    },
    amountPlan: {
        value: '',
        error: ''
    }
};

const createSubscriptionPlanSlice = createSlice({
    name: "create-subscription-plan",
    initialState,
    reducers: {
        handleChange: (state, action) => {
            state[action.payload.field].error = '';
            state[action.payload.field].value = action.payload.value;            
        },
        handleModelErrors: (state, action) => {
            Object.entries(action.payload).forEach(([field, errors]) => { 
                state[field].error = errors[0];
            });
        },
        handleChangeSubscriptionPlan: (state, action) => {
            state.subscriptionPlanName.value = action.payload;
            state.subscriptionPlanName.error = '';
            
        },   
        handleChangeDisplayPlan: (state, action) => {
            state.displayplan = action.payload;
        },    
        clearErrors: (state, action) => {
            Object.keys(initialState).forEach(field => {
                if (state[field]?.error !== undefined) {
                    state[field].error = '';    
                }
            });
        },
        clearForm: (state, action) => {
            state.subscriptionPlanName.value = '';
            state.subscriptionPlanName.error = '';

            state.planDescription.value = '';
            state.planDescription.error = '';

            state.displayplan.value = '';
            state.displayplan.error = '';
            
            state.amountYears.value = '';
            state.amountYears.error = '';

            state.amountMonths.value = '';
            state.amountMonths.error = '';

            state.amountDays.value = '';
            state.amountDays.error = '';

            state.amountLicenses.value = '';
            state.amountLicenses.error = '';

            state.amountPlan.value = '';
            state.amountPlan.error = '';
        }
    },
    extraReducers: (builder) => {
        builder.addMatcher(
            subscriptionPlanApi.endpoints.getPlans.matchFulfilled, (state, action) => {
                state.subscriptionPlanList = action.payload;
            }
        )
    }
});

export const { 
    handleChange,
    handleModelErrors,
    clearForm,
    clearErrors,
    handleChangeDisplayPlan
} = createSubscriptionPlanSlice.actions;

export default createSubscriptionPlanSlice.reducer;