import { createSlice } from "@reduxjs/toolkit";

export const initialState = {
    notificationList: [],
};

const alertNotificationSlice = createSlice({
    name: "alert-notification",
    initialState,
    reducers: {
        handleCheck: (state, action) => {
            state.notificationList.forEach(element => {
                if (element.id === action.payload.id) {
                    element.enabled = action.payload.enabled;
                }
            });
        },
        handleGet: (state, action) => {
            state.notificationList = action.payload;
        }
    },
});

export const { 
    handleCheck,
    handleGet
} = alertNotificationSlice.actions;

export default alertNotificationSlice.reducer;
