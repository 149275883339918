import { emptySplitApi } from './index'

export const userProfileApi = emptySplitApi.injectEndpoints({
  endpoints: (builder) => ({
    getUserProfile: builder.query({
      query: () => ({
        url: `api/v1/auth-service/user-profile`,
        method: "GET"
      }),
    }),
    updateUserProfile: builder.mutation({
      query: (model) => ({
        url: `api/v1/auth-service/user-profile`,
        method: "PATCH",
        body: model
      }),
    }),
  }),
  overrideExisting: false,
});

export const { useGetUserProfileQuery, useUpdateUserProfileMutation } = userProfileApi;
