import { createSlice } from "@reduxjs/toolkit";
import { searchEngineApi } from "Services/SearchEngineService";
import { userProjectApi } from "Services/UserProjectService";
import { countryApi } from "Services/CountryService";
import { sectorApi } from "Services/SectorService";
import { projectFundersApi } from "Services/ProjectFundersService";

export const initialState = {
    collapseFilters: false,
    toggleAdvancedSearch: false,
    toggleExpandSearchBar: false,
    userProject: {
        id: null,
        value: null,
        label: null
    },
    userProjectList: [],
    searchBar: {
        any: '',
        all: '',
        none: '',
        directorName: '',
        foundationName: '',
        yearList: [],
        stemmingSearch: false,
        showFundersOnce: false,
        showMultiGrant: false,
        searchButton: '',
        customDatabaseFileId: '',
        customDatabaseContactName: '',
        customDatabaseOrganizationName: ''
    },
    searchFilters: {
        fromCountry: [],
        toCountry: [],
        provinceOrState: [],
        city: [],
        years: [],
        sector: [],
        subSector: [],
        foundation: ['Public & Private'],
        avgGrant: [],
        ratingValue: 0,
        grantSize: [0, 6],
        assertSize: [0, 6],
    },
    additionalFilters: {
        hasOnlineApplication: false,
        hasAdditionalInformation: false,
        doesNotHaveWebsite: false,
        doesHaveWebsite: false,
        hasPhoneNumber: false,
        hasEmail: false,
        showDesignedGiven: false
    },
    foundationList: [
        { id: 0, name: 'All', checked: false },
        { id: 1, name: 'Public & Private', checked: true },
        { id: 2, name: 'Public', checked: false },
        { id: 3, name: 'Private', checked: false },
        { id: 4, name: 'Charitable Trust', checked: false },
    ],
    countryList: [],
    provinceOrStateList: [],
    cityList: [],
    sectorList: [],
    subSectorList: [],
    avgGrantList: [
        { id: 2, name: '12 or more', checked: false },
        { id: 3, name: '8 or more', checked: false },
        { id: 4, name: '4 or more', checked: false },
    ],
    yearList: [
        ...Array(2021 - 2014 + 1)
        .fill()
        .map((_, idx) => ({ 
            id: idx,
            name: (2014 + idx).toString(),
            checked: false
        }))
    ],
    categoryList: [
        { id: 1, name: 'Religion', checked: false },
        { id: 2, name: 'Social & Human Services', checked: false },
        { id: 3, name: 'Education', checked: false },
        { id: 4, name: 'Community', checked: false },
        { id: 5, name: 'Art & Culture', checked: false },
        { id: 6, name: 'Sports & Recreation', checked: false },
        { id: 7, name: 'Animal Welfare', checked: false },
    ],
    pagedList: {
        pageNumber: 1,
        pageSize: 10,
        pageCount: 0,
        totalCount: 0,
        orderField: 'doneename',
        orderDirection: 'desc',
        items: []
    },
    searchByNamePagedList: {
        pageNumber: 0,
        pageSize: 10,
        pageCount: 0,
        totalCount: 0,
        orderField: 'name',
        orderDirection: 'asc',
        items: []
    },
    searchByDirectorPagedList: {
        pageNumber: 0,
        pageSize: 10,
        pageCount: 0,
        totalCount: 0,
        orderField: 'firstName',
        orderDirection: 'desc',
        items: []
    },
    searchByYearPagedList: {
        pageNumber: 0,
        pageSize: 10,
        pageCount: 0,
        totalCount: 0,
        orderField: 'accountName',
        orderDirection: 'asc',
        items: []
    },
    searchByRatingPagedList: {
        pageNumber: 0,
        pageSize: 10,
        pageCount: 0,
        totalCount: 0,
        orderField: 'accountName',
        orderDirection: 'asc',
        items: []
    },
    searchByCustomPagedList: {
        pageNumber: 0,
        pageSize: 10,
        pageCount: 0,
        totalCount: 0,
        orderField: 'firstName',
        orderDirection: 'asc',
        items: []
    },
    projectFundersProfileIdList: []
};

const searchEngineSlice = createSlice({
    name: "search-engine",
    initialState,
    reducers: {
        handleChangeUserProject: (state, action) => {
            if (action.payload !== null) {
                state.userProject = state.userProjectList.find(o => o.id === action.payload.id);
            }
        },
        handleChangeCountry: (state, action) => {
            state.countryList.forEach(country => {
                if (country.code === action.payload.code) {
                    country.checked = action.payload.checked;

                    if (action.payload.checked) {
                        state.searchFilters.fromCountry.push(action.payload.code);
                    }
                    else {
                        state.searchFilters.fromCountry = state.searchFilters.fromCountry.filter(o => o !== action.payload.code);        
                    }
                }
            });
        },
        handleChangeToCountry: (state, action) => {
            state.countryList.forEach(country => {
                if (country.code === action.payload.code) {
                    country.checked = action.payload.checked;

                    if (action.payload.checked) {
                        state.searchFilters.toCountry.push(action.payload.code);
                    }
                    else {
                        state.searchFilters.toCountry = state.searchFilters.toCountry.filter(o => o !== action.payload.code);        
                    }
                }
            });
        },
        handleChangeProvinceOrState: (state, action) => {
            state.provinceOrStateList.forEach(provinceOrState => {
                if (provinceOrState.code === action.payload.code) {
                    provinceOrState.checked = action.payload.checked;

                    if (action.payload.checked) {
                        state.searchFilters.provinceOrState.push(action.payload.code);
                    }
                    else {
                        state.searchFilters.provinceOrState = state.searchFilters.provinceOrState.filter(o => o !== action.payload.code);        
                    }
                }
            });
        },
        handleChangeCity: (state, action) => {
            state.cityList.forEach(city => {
                if (city.name === action.payload.name) {
                    city.checked = action.payload.checked;

                    if (action.payload.checked) {
                        state.searchFilters.city.push(action.payload.name);
                    }
                    else {
                        state.searchFilters.city = state.searchFilters.city.filter(o => o !== action.payload.name);        
                    }
                }
            });
        },
        handleChangeSector: (state, action) => {
            state.sectorList.forEach(sector => {
                if (sector.sectorName === action.payload.sectorName) {
                    sector.checked = action.payload.checked;

                    if (action.payload.checked) {
                        state.searchFilters.sector.push(action.payload.sectorName);
                    }
                    else {
                        state.searchFilters.sector = state.searchFilters.sector.filter(o => o !== action.payload.sectorName);        
                    }
                }
            });
        },
        handleChangeSubSector: (state, action) => {
            state.subSectorList.forEach(sector => {
                if (sector.subSectorName === action.payload.subSectorName) {
                    sector.checked = action.payload.checked;

                    if (action.payload.checked) {
                        state.searchFilters.subSector.push(action.payload.subSectorName);
                    }
                    else {
                        state.searchFilters.subSector = state.searchFilters.subSector.filter(o => o !== action.payload.subSectorName);        
                    }
                }
            });
        },
        setCollapseFilters: (state, action) => {
            state.collapseFilters = action.payload;
        },
        handleChangeSearchBar: (state, action) => {
            state.searchBar[action.payload.field] = action.payload.value;
        },
        handleCheckFilters: (state, action) => {

            if (action.payload.checked) {
                state.searchFilters[action.payload.field].push(action.payload.value);
            }
            else {
                state.searchFilters[action.payload.field] = state.searchFilters[action.payload.field].filter(o => o !== action.payload.value);
            }
        },
        handleCheckYearList: (state, action) => {
            state.searchBar.yearList = action.payload;
        },
        handleChangeAdditionalFilters: (state, action) => {
            state.additionalFilters[action.payload.field] = action.payload.checked;
        },
        handleToggleAdvancedSearch: (state, action) => {
            state.toggleAdvancedSearch = action.payload;
        },
        handleToggleExpandSearchBar: (state, action) => {
            state.toggleExpandSearchBar = action.payload;
        },
        handleRatingValue: (state, action) => {
            state.searchFilters.ratingValue = action.payload;
        },
        handleModelErrors: (state, action) => {
            Object.entries(action.payload).forEach(([field, errors]) => { 
                state[field].error = errors[0];
            });
        },
        handleAssertSize: (state, action) => {
            state.searchFilters.assertSize = action.payload;
        },
        handleGrantSize: (state, action) => {
            state.searchFilters.grantSize = action.payload;
        },
        handleClearForm: (state, action) => {
            state.searchBar = {...initialState.searchBar };
            state.searchFilters = {...initialState.searchFilters};
            state.additionalFilters = {...initialState.additionalFilters};

            state.countryList = state.countryList.map(item => ({
                ...item,
                checked: false
            }));

            state.provinceOrStateList = state.provinceOrStateList.map(item => ({
                ...item,
                checked: false
            }));

            state.cityList = state.cityList.map(item => ({
                ...item,
                checked: false
            }));

            state.sectorList = state.sectorList.map(item => ({
                ...item,
                checked: false
            }));

            state.subSectorList = state.subSectorList.map(item => ({
                ...item,
                checked: false
            }));

            state.foundationList = [
                { id: 0, name: 'All', checked: false },
                { id: 1, name: 'Public & Private', checked: true },
                { id: 2, name: 'Public', checked: false },
                { id: 3, name: 'Private', checked: false },
                { id: 4, name: 'Charitable Trust', checked: false },
            ];
        },
        handleLoadingFields: (state, action) => {
            state.collapseFilters = true;
            state.searchBar = {...initialState.searchBar, ...action.payload.searchBar };
            state.searchFilters = {...initialState.searchFilters, ...action.payload.searchFilters };
            state.additionalFilters = {...initialState.additionalFilters, ...action.payload.additionalFilters };

            const grantSize = action.payload.searchFilters?.grantSize || [0, 50];
            const assertSize = action.payload.searchFilters?.assertSize || [0, 50];

            if (action.payload.searchBar.all !== '') {
                state.toggleExpandSearchBar = true;
            }

            if (action.payload.searchBar.none !== '') {
                state.toggleExpandSearchBar = true;
            }

            if (action.payload.searchBar.foundationName !== '') {
                state.toggleExpandSearchBar = true;
            }

            if (action.payload.searchFilters.foundation?.length > 0) {
                state.toggleAdvancedSearch = true;
            }

            if (grantSize[0] !== 0 || grantSize[1] !== 50) {
                state.toggleAdvancedSearch = true;
            }

            if (assertSize[0] !== 0 || assertSize[1] !== 50) {
                state.toggleAdvancedSearch = true;
            }

            if (action.payload.searchFilters.avgGrant?.length > 0) {
                state.toggleAdvancedSearch = true;
            }
        },
        handleClearList: (state, action) => {
            state.pagedList = { ...initialState.pagedList };
            state.searchByNamePagedList = { ...initialState.searchByNamePagedList };
            state.searchByYearPagedList = { ...initialState.searchByYearPagedList };
            state.searchByRatingPagedList = { ...initialState.searchByRatingPagedList };
            state.searchByCustomPagedList = { ...initialState.searchByCustomPagedList };
        },
        handleAddFundersToProject: (state, action) => {
            action.payload.forEach(projectFunders => {
                state.projectFundersProfileIdList.push(projectFunders.profileId);
            });
        },

    },
    extraReducers: (builder) => {
        builder.addMatcher(
            searchEngineApi.endpoints.searchBySearchType.matchFulfilled, (state, action) => {
                state.pagedList.pageNumber = action.payload.pageNumber;
                state.pagedList.pageSize = action.payload.pageSize;
                state.pagedList.pageCount = action.payload.pageCount;
                state.pagedList.totalCount = action.payload.totalCount;
                state.pagedList.orderField = action.payload.orderField;
                state.pagedList.orderDirection = action.payload.orderDirection;
                state.pagedList.items = action.payload.items;
            }
        )
        .addMatcher(
            searchEngineApi.endpoints.searchByGivingHistory.matchFulfilled, (state, action) => {
                state.pagedList.pageNumber = action.payload.pageNumber;
                state.pagedList.pageSize = action.payload.pageSize;
                state.pagedList.pageCount = action.payload.pageCount;
                state.pagedList.totalCount = action.payload.totalCount;
                state.pagedList.orderField = action.payload.orderField;
                state.pagedList.orderDirection = action.payload.orderDirection;
                state.pagedList.items = action.payload.items;
            }
        )
        .addMatcher(
            searchEngineApi.endpoints.searchByName.matchFulfilled, (state, action) => {
                state.searchByNamePagedList.pageNumber = action.payload.pageNumber;
                state.searchByNamePagedList.pageSize = action.payload.pageSize;
                state.searchByNamePagedList.pageCount = action.payload.pageCount;
                state.searchByNamePagedList.totalCount = action.payload.totalCount;
                state.searchByNamePagedList.orderField = action.payload.orderField;
                state.searchByNamePagedList.orderDirection = action.payload.orderDirection;
                state.searchByNamePagedList.items = action.payload.items;
            }
        )
        .addMatcher(
            searchEngineApi.endpoints.searchByDirector.matchFulfilled, (state, action) => {
                state.searchByDirectorPagedList.pageNumber = action.payload.pageNumber;
                state.searchByDirectorPagedList.pageSize = action.payload.pageSize;
                state.searchByDirectorPagedList.pageCount = action.payload.pageCount;
                state.searchByDirectorPagedList.totalCount = action.payload.totalCount;
                state.searchByDirectorPagedList.orderField = action.payload.orderField;
                state.searchByDirectorPagedList.orderDirection = action.payload.orderDirection;
                state.searchByDirectorPagedList.items = action.payload.items;
            }
        )
        .addMatcher(
            searchEngineApi.endpoints.searchByYear.matchFulfilled, (state, action) => {
                state.searchByYearPagedList.pageNumber = action.payload.pageNumber;
                state.searchByYearPagedList.pageSize = action.payload.pageSize;
                state.searchByYearPagedList.pageCount = action.payload.pageCount;
                state.searchByYearPagedList.totalCount = action.payload.totalCount;
                state.searchByYearPagedList.orderField = action.payload.orderField;
                state.searchByYearPagedList.orderDirection = action.payload.orderDirection;
                state.searchByYearPagedList.items = action.payload.items;
            }
        )
        .addMatcher(
            searchEngineApi.endpoints.searchByRating.matchFulfilled, (state, action) => {
                state.searchByRatingPagedList.pageNumber = action.payload.pageNumber;
                state.searchByRatingPagedList.pageSize = action.payload.pageSize;
                state.searchByRatingPagedList.pageCount = action.payload.pageCount;
                state.searchByRatingPagedList.totalCount = action.payload.totalCount;
                state.searchByRatingPagedList.orderField = action.payload.orderField;
                state.searchByRatingPagedList.orderDirection = action.payload.orderDirection;
                state.searchByRatingPagedList.items = action.payload.items;
            }
        )
        .addMatcher(
            searchEngineApi.endpoints.searchByCustom.matchFulfilled, (state, action) => {
                state.searchByCustomPagedList.pageNumber = action.payload.pageNumber;
                state.searchByCustomPagedList.pageSize = action.payload.pageSize;
                state.searchByCustomPagedList.pageCount = action.payload.pageCount;
                state.searchByCustomPagedList.totalCount = action.payload.totalCount;
                state.searchByCustomPagedList.orderField = action.payload.orderField;
                state.searchByCustomPagedList.orderDirection = action.payload.orderDirection;
                state.searchByCustomPagedList.items = action.payload.items;
            }
        )
        .addMatcher(
            userProjectApi.endpoints.getCurrentList.matchFulfilled, (state, action) => {
                state.userProjectList = [];
                state.userProject = initialState.userProject;

                if (action.payload.length !== 0) {

                    state.userProjectList = action.payload.map(item => ({
                        id: item.id,
                        value: item.name,
                        label: item.name
                    }));

                    state.userProject = state.userProjectList[0];
                }
            }
        )
        .addMatcher(
            countryApi.endpoints.getCountryList.matchFulfilled, (state, action) => {
                state.countryList = action.payload.map(item => ({
                    ...item,
                    checked: false
                }));

                action.payload.forEach(country => {
                    country.provinceOrStateList.forEach(provinceOrState => {
                        state.provinceOrStateList.push({
                            ...provinceOrState,
                            checked: false
                        });
                    });
                });
            }
        )
        .addMatcher(
            projectFundersApi.endpoints.getAllProjectFundersProfileId.matchFulfilled, (state, action) => {
                state.projectFundersProfileIdList = action.payload;
            }
        ) 
        .addMatcher(
            countryApi.endpoints.getCityListByProvinceOrStates.matchFulfilled, (state, action) => {
                state.cityList = action.payload.map(item => ({
                    ...item,
                    checked: false
                }));
            }
        )
        .addMatcher(
            sectorApi.endpoints.getSectorList.matchFulfilled, (state, action) => {
                state.sectorList = action.payload.map(item => ({
                    ...item,
                    checked: false
                }));
            }
        )
        .addMatcher(
            sectorApi.endpoints.getSubSectorListBySectorIds.matchFulfilled, (state, action) => {
                state.subSectorList = action.payload.map(item => ({
                    ...item,
                    checked: false
                }));
            }
        )
    }
});

export const {
    handleChangeUserProject,
    handleChangeCountry,
    handleChangeProvinceOrState,
    handleChangeCity,
    handleChangeSector,
    handleChangeSubSector,
    handleToggleAdvancedSearch,
    handleToggleExpandSearchBar,
    handleRatingValue,
    handleChangeSearchBar,
    handleCheckFilters,
    handleChangeAdditionalFilters,
    handleModelErrors,
    handleAssertSize,
    handleGrantSize,
    handleLoadingFields,
    handleClearForm,
    handleClearList,
    setCollapseFilters,
    handleCheckYearList,
    handleAddFundersToProject
} = searchEngineSlice.actions;

export default searchEngineSlice.reducer;
