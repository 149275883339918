// assets
import { 
    IconFileText, 
    IconReceipt,
    IconId,
    IconTrafficLights,
    IconStars,
    IconBox,
    IconKeyboard,
    IconWorld
} from '@tabler/icons';

// constant
export const icons = {
    IconFileText,
    IconReceipt,
    IconId,
    IconTrafficLights,
    IconStars,
    IconBox,
    IconKeyboard,
    IconWorld
};

const searchEngine = {
    id: 'search-engine',
    title: 'side_menu.search_engine',
    type: 'group',
    roles: ['user', 'subuser', 'member', 'supervisor', 'admin', 'demo', 'sales', 'superadmin', 'it'],
    collapse: false,
    children: [
        {
            id: 'by-giving-history',
            title: 'side_menu.by_giving_history',
            type: 'item',
            url: 'main/search/by-giving-history',
            icon: 'IconFileText', //icons.IconFileText,
            breadcrumbs: false,
            roles: ['accessus'],
        },
        {
            id: 'by-keyword',
            title: 'side_menu.by_keyword',
            type: 'item',
            url: 'main/search/by-keyword',
            icon: 'IconKeyboard', //icons.IconBox,
            breadcrumbs: false,
            roles: ['accessus'],
        },
        {
            id: 'by-name',
            title: 'side_menu.by_name',
            type: 'item',
            url: 'main/search/by-name',
            icon: 'IconReceipt', //icons.IconReceipt,
            breadcrumbs: false,
            roles: ['accessus'],
        },
        {
            id: 'by-director',
            title: 'side_menu.by_director',
            type: 'item',
            url: 'main/search/by-director',
            icon: 'IconId', //icons.IconId,
            breadcrumbs: false,
            roles: ['accessus'],
        },
        {
            id: 'by-year',
            title: 'side_menu.by_year',
            type: 'item',
            url: 'main/search/by-year',
            icon: 'IconTrafficLights', //icons.IconTrafficLights,
            breadcrumbs: false,
            roles: ['accessus'],
        },
        /*{
            id: 'by-rating',
            title: 'side_menu.by_rating',
            type: 'item',
            url: 'main/search/by-rating',
            icon: icons.IconStars,
            breadcrumbs: false,
            roles: ['user', 'subuser'],
        },*/
        {
            id: 'by-giving-history',
            title: 'side_menu.by_giving_history',
            type: 'item',
            url: 'main/search/giving-history-ca',
            icon: 'IconFileText', //icons.IconFileText,
            breadcrumbs: false,
            roles: ['accesscanada'],
        },
        {
            id: 'by-name',
            title: 'side_menu.by_name',
            type: 'item',
            url: 'main/search/name-ca',
            icon: 'IconReceipt', //icons.IconReceipt,
            breadcrumbs: false,
            roles: ['accesscanada'],
        },
        {
            id: 'by-director',
            title: 'side_menu.by_director',
            type: 'item',
            url: 'main/search/directors-ca',
            icon: 'IconId', //icons.IconId,
            breadcrumbs: false,
            roles: ['accesscanada'],
        },
        {
            id: 'by-year',
            title: 'side_menu.by_year',
            type: 'item',
            url: 'main/search/year-ca',
            icon: 'IconTrafficLights', //icons.IconTrafficLights,
            breadcrumbs: false,
            roles: ['accesscanada'],
        },
        /*{
            id: 'by-rating',
            title: 'side_menu.by_rating',
            type: 'item',
            url: 'main/search/by-rating',
            icon: icons.IconStars,
            breadcrumbs: false,
            roles: ['user', 'subuser'],
        },*/
        {
            id: 'by-custom',
            title: 'side_menu.by_custom',
            type: 'item',
            url: 'main/search/by-custom',
            icon: 'IconBox', //icons.IconBox,
            breadcrumbs: false,
            roles: ['user', 'subuser', 'member', 'supervisor', 'admin', 'demo', 'sales', 'superadmin', 'it'],
        },
        {
            id: 'by-international-funding',
            title: 'side_menu.by_international_funding',
            type: 'item',
            url: 'main/search/by-international-funding',
            icon: 'IconWorld', //icons.IconTrafficLights,
            breadcrumbs: false,
            roles: ['accessus', 'pro', 'admin', 'sales', 'superadmin'],
        },
    ]
};

export default searchEngine;
