import { emptySplitApi } from './index'

export const userProjectApi = emptySplitApi.injectEndpoints({
  endpoints: (builder) => ({
    getAll: builder.mutation({
      query: (model) => ({
        url: `api/v1/project-service/user-project?`
                + `&pageNumber=${model?.pageNumber || 0}`
                + `&pageSize=${model?.pageSize || 10}`
                + `&searchTerm=${model?.searchTerm || ''}`
                + `&orderField=${model?.orderField || '`name`'}`
                + `&showArchived=${model?.showArchived || 'false'}`
                + `&orderDirection=${model?.orderDirection || 'asc'}`,
        method: "GET",
      }),
    }),
    insertUserProject: builder.mutation({
        query: (model) => ({
            url: `api/v1/project-service/user-project`,
            method: "POST",
            body: model,
        }),
    }),
    updateUserProject: builder.mutation({
        query: ({id, ...model}) => ({
            url: `api/v1/project-service/user-project/${id}`,
            method: "PATCH",
            body: model,
        }),
    }),
    deleteUserProject: builder.mutation({
        query: (id) => ({
            url: `api/v1/project-service/user-project/${id}`,
            method: "DELETE",
        }),
    }),
    archiveProject: builder.mutation({
        query: (id) => ({
            url: `api/v1/project-service/user-project/archive/${id}`,
            method: "PATCH",
        }),
    }),
    unarchiveProject: builder.mutation({
        query: (id) => ({
            url: `api/v1/project-service/user-project/unarchive/${id}`,
            method: "PATCH",
        }),
    }),
    getCurrentList: builder.query({
        query: () => ({
          url: `api/v1/project-service/user-project/current-list`,
          method: "GET",
        }),
    }),
    getCurrentListWithTrigger: builder.mutation({
        query: () => ({
          url: `api/v1/project-service/user-project/current-list`,
          method: "GET",
        }),
    }),
    updateUserProjectToActiveAsync: builder.mutation({
        query: (id) => ({
          url: `api/v1/project-service/user-project/active/${id}`,
          method: "PATCH",
        }),
    }),
    updateUserProjectToCompleted: builder.mutation({
        query: (id) => ({
          url: `api/v1/project-service/user-project/completed/${id}`,
          method: "PATCH",
        }),
    }),
  }),
  overrideExisting: false,
});

export const { 
    useGetAllMutation,
    useInsertUserProjectMutation,
    useUpdateUserProjectMutation,
    useDeleteUserProjectMutation,
    useArchiveProjectMutation,
    useUnarchiveProjectMutation,
    useGetCurrentListQuery,
    useGetCurrentListWithTriggerMutation,
    useUpdateUserProjectToActiveAsyncMutation,
    useUpdateUserProjectToCompletedMutation,
} = userProjectApi;
