import { useDispatch, useSelector } from "react-redux";
import { Close } from "@mui/icons-material"
import LoadingButton from "@mui/lab/LoadingButton";
import { 
    Grid, 
    Dialog, 
    useTheme,
    IconButton,
    DialogActions,
    DialogTitle,
    DialogContent
} from "@mui/material";
import { showErrorToaster, showSuccessToaster } from "store/Shared.Slice"
import { useTranslation } from "react-i18next";
import { useInsertAdditionalEmailMutation, useGetAdditionalEmailPagedListMutation } from "Services/OrganizationAdditionalEmailService";
import { 
    handleChange, 
    handleModelErrors,
    closeDialog 
} from "./OrganizationAdditionalEmail.Slice";
import FormControlWrapper from "components/FormControlWrapper";

const OrganizationAdditionalEmailPopup = () => {

    const { 
        isDialogOpen,
        newEmailAddress,
        additionalEmailListPagedData: {
            pageNumber,
            pageSize
        }
    } = useSelector((state) => state.organizationAdditionalEmailState);

    const dispatch = useDispatch();
    const theme = useTheme();
    const { t } = useTranslation();
    const [insertAdditionalEmail, { isLoading: isInserting }] = useInsertAdditionalEmailMutation();
    const [getAdditionalEmailList] = useGetAdditionalEmailPagedListMutation();
    
    const handleInsert = async () => {
        try {
            const response = await insertAdditionalEmail(newEmailAddress.value).unwrap();
            await getAdditionalEmailList({pageNumber, pageSize});

            dispatch(closeDialog());
            dispatch(showSuccessToaster(response.message));
        }
        catch(error) {

            // Model Errors
            if (error.data?.errors != null) {
                dispatch(showErrorToaster(t("errors.model_error")));
                dispatch(handleModelErrors(error.data?.errors));
            }

            // Bad Request
            if (error.data?.message != null) {
                dispatch(showErrorToaster(error.data?.message));
            }
        }
    }

    return (
        <Dialog open={isDialogOpen} onClose={() => dispatch(closeDialog())} fullWidth maxWidth="xs">
            <DialogTitle sx={{ fontSize: "1rem" }}>{`${t('labels.add_new_additional_email')}`}</DialogTitle>
            <IconButton
                aria-label="close"
                onClick={() => dispatch(closeDialog())}
                sx={{
                    position: 'absolute',
                    right: 8,
                    top: 15,
                    color: (theme) => theme.palette.grey[500],
                }}
            >
                <Close />
            </IconButton>
            <DialogContent dividers>
               
                <Grid container>
                    <Grid item xs={12}>
                        <FormControlWrapper 
                            name="newEmailAddress"
                            value={newEmailAddress.value}
                            label={t('labels.new_email_address')}
                            type="email"
                            maxLength="100"
                            error={newEmailAddress.error}
                            theme={theme}
                            onChange={(element) => dispatch(handleChange({ field: "newEmailAddress", value: element.target.value }))}
                            required
                        />
                    </Grid>
                </Grid>
            </DialogContent>
            <DialogActions>
                <LoadingButton
                    disableElevation
                    size="large"
                    type="button"
                    variant="outlined"
                    color="secondary"
                    onClick={() => dispatch(closeDialog())}
                >
                    Close
                </LoadingButton>

                <LoadingButton
                    disableElevation
                    size="large"
                    type="button"
                    variant="contained"
                    color="secondary"
                    loading={isInserting}
                    onClick={handleInsert}
                >
                    {t('buttons.add_email')}
                </LoadingButton>
            </DialogActions>
        </Dialog>
    )

}

export default OrganizationAdditionalEmailPopup;