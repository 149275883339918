import { emptySplitApi } from './index'

export const customLetterApi = emptySplitApi.injectEndpoints({
    endpoints: (builder) => ({
        getCustomStepList: builder.query({
            query: () => ({
                url: `api/v1/document-generator-service/custom-letter/steps`,
                method: "GET"
            }),
        }),
        insertUpdateCustom: builder.mutation({
            query: (model) => ({
                url: `api/v1/document-generator-service/custom-letter`,
                method: "POST",
                body: model
            }),
        }),
        getCustomLetter: builder.mutation({
            query: (userProjectId) => ({
                url: `api/v1/document-generator-service/custom-letter/${userProjectId}`,
                method: "GET",
            }),
        }),
    }),
    overrideExisting: false,
});

export const {
    useGetCustomStepListQuery,
    useInsertUpdateCustomMutation,
    useGetCustomLetterMutation
} = customLetterApi;
