import { createContext, useEffect } from 'react';
import LogRocket from 'logrocket';
import { useSelector } from 'react-redux';

const LogRocketContext = createContext();

export const LogRocketProvider = ({ children }) => {
    const { userToken } = useSelector(state => state.sharedState);

    useEffect(() => {
        if(process.env.NODE_ENV === 'development' || userToken == null) return;

        LogRocket.init('f5sb9k/grant-advance-frontend');
        
        LogRocket.identify(userToken.Id, {
            name: userToken.UserName,
            email: userToken.email,
        });
    }, [userToken]);

    return (
        <LogRocketContext.Provider value={null}>
            {children}
        </LogRocketContext.Provider>
    );
};
