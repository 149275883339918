import { emptySplitApi } from './index'

export const impactReportLetterApi = emptySplitApi.injectEndpoints({
    endpoints: (builder) => ({
        getImpactReportStepList: builder.query({
            query: () => ({
                url: `api/v1/document-generator-service/impact-report-letter/steps`,
                method: "GET"
            }),
        }),
        insertUpdateImpactReportLetter: builder.mutation({
            query: (model) => ({
                url: `api/v1/document-generator-service/impact-report-letter`,
                method: "POST",
                body: model
            }),
        }),
        getImpactReportLetter: builder.mutation({
            query: (userProjectId) => ({
                url: `api/v1/document-generator-service/impact-report-letter/${userProjectId}`,
                method: "GET",
            }),
        }),
    }),
    overrideExisting: false,
});

export const { 
    useGetImpactReportStepListQuery,
    useInsertUpdateImpactReportLetterMutation,
    useGetImpactReportLetterMutation
} = impactReportLetterApi;
