import { useDispatch, useSelector } from "react-redux";
import {
    Box,
    Grid,
    Skeleton,
    Typography,
    useMediaQuery,
    useTheme,
  } from "@mui/material";

import LoadingButton from '@mui/lab/LoadingButton';
import { useGetOrganizationProfileMutation, useUpdateOrganizationProfileMutation } from "Services/OrganizationProfileService";
import { useGetCountryListQuery } from "Services/CountryService";
import { handleChange, handleModelErrors, handleGet, handleChangeCountry, handleChangeProvinceOrState, handleFillCountryAndState } from "./OrganizationProfileInfo.Slice";
import { showSuccessToaster, showErrorToaster } from "store/Shared.Slice";
import { useTranslation } from "react-i18next";
import { useEffect } from "react";
import FormControlWrapper from "components/FormControlWrapper";
import AutocompleteWrapper from "components/AutocompleteWrapper";
import SelectWrapper from "components/SelectWrapper";
import MaskedInputWrapper from "components/MaskedInputWapper";
import { usePrompt } from "react-router-dom";

const OrganizationProfileInfoPage = () => {

    const {
        countryList,
        companyName,
        phoneNumber,
        businessEin,
        addressLine,
        addressLine2,
        city,
        provinceOrState,
        country,
        county,
        postalCode,
        selectedCountry,
        selectedProvinceOrState,
        currentProfileInfo
    } = useSelector((state) => state.organizationProfileState);

    const theme = useTheme();
    const dispatch = useDispatch();
    const matchDownSM = useMediaQuery(theme.breakpoints.down("md"));
    const { t } = useTranslation();
    const [getOrganizationProfile, { data, isLoading: isLoadingOrganizationProfile }] = useGetOrganizationProfileMutation();
    const { isLoading: isLoadingCountry } = useGetCountryListQuery();
    const [updateOrganizationProfile, { isLoading: isUpdating }] = useUpdateOrganizationProfileMutation();

    usePrompt(
        "You have unsaved changes. Are you sure to discard all changes made?",
        companyName.value !== currentProfileInfo.companyName
        || phoneNumber.value !== currentProfileInfo.phoneNumber
        || businessEin.value !== (currentProfileInfo.businessEin ?? "")
        || addressLine.value !== currentProfileInfo.addressLine
        || addressLine2.value !== currentProfileInfo.addressLine2
        || city.value !== currentProfileInfo.city
        || provinceOrState.value !== currentProfileInfo.provinceOrState
        || country.value !== currentProfileInfo.country
        || postalCode.value !== currentProfileInfo.postalCode
    );

    const handleUpdateProfile = async() => {

        try {
            const model = {
                companyName: companyName.value,
                phoneNumber: phoneNumber.value,
                businessEin: businessEin.value,
                addressLine: addressLine.value,
                addressLine2: addressLine2.value,
                city: city.value,
                provinceOrState: provinceOrState.value,
                county: county.value,
                postalCode: postalCode.value,
            };

            const response = await updateOrganizationProfile(model).unwrap();
            dispatch(showSuccessToaster(response.message));
        }
        catch(error) {
            // Model Errors
            if (error.data?.errors != null) {
                dispatch(showErrorToaster(t("errors.model_error")));
                dispatch(handleModelErrors(error.data?.errors));
            }

            // Bad Request
            if (error.data?.message != null) {
                dispatch(showErrorToaster(error.data?.message));
            }
        }
    }


    const handleGetOrganizationProfile = async() => {
        const response = await getOrganizationProfile().unwrap();
        dispatch(handleGet(response));
    };

    useEffect(() => {
        handleGetOrganizationProfile();
        // eslint-disable-next-line react-hooks/exhaustive-deps 
    }, []);

    useEffect(() => {
        if (!isLoadingCountry && !isLoadingOrganizationProfile) {
            if (data) {
                dispatch(handleFillCountryAndState(data));
            }
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps 
    }, [isLoadingOrganizationProfile, isLoadingCountry]);
 
    return (
        <>
            {isLoadingOrganizationProfile 
            ?  
            <Grid container>
                <Grid item xs={12} sm={12} md={12}>
                    <Skeleton height={200} />
                </Grid>
            </Grid>
            :
            <div>
            <Grid container columnSpacing={2} alignItems="center">
                <Grid item xs={12} sm={12} md={12} sx={{mb: 1 }}>
                    <Typography variant="h4">{t('labels.company_information')}</Typography>
                </Grid>

                <Grid item xs={12} sm={7} md={7}>
                    <FormControlWrapper 
                        name="companyName"
                        value={companyName.value}
                        label={t('labels.company_organization')}
                        type="text"
                        maxLength="200"
                        error={companyName.error}
                        theme={theme}
                        onChange={(element) => dispatch(handleChange({ field: "companyName", value: element.target.value }))}
                        required
                    />
                </Grid>

                <Grid item xs={12} sm={5} md={3}>
                    <FormControlWrapper 
                        name="businessEin" 
                        value={businessEin.value}
                        label={"Business/EIN"}
                        onChange={(element) => dispatch(handleChange({ field: "businessEin", value: element.target.value }))}
                        error={businessEin.error}
                        theme={theme}
                        placeholder={`(${country.value === "Canada" ? "CA" : "US"}) e.g., ${country.value === "Canada" ? "123456789RR0001" : "12-3456789"}`}
                    />
                </Grid>

                <Grid item xs={12} sm={6} md={2}>
                    <MaskedInputWrapper 
                        name="phoneNumber" 
                        value={phoneNumber.value}
                        label={t('labels.phone')}
                        onChange={(element) => dispatch(handleChange({ field: "phoneNumber", value: element.target.value }))}
                        maskType="phone" 
                        error={phoneNumber.error}
                        theme={theme}
                        required
                    />
                </Grid>

                <Grid item xs={12} sm={6} md={6}>
                    <FormControlWrapper 
                        name="addressLine"
                        value={addressLine.value}
                        label={t('labels.address')}
                        type="text"
                        maxLength="200"
                        error={addressLine.error}
                        theme={theme}
                        onChange={(element) => dispatch(handleChange({ field: "addressLine", value: element.target.value }))}
                        required
                    />
                </Grid>

                <Grid item xs={12} sm={6} md={6}>
                    <FormControlWrapper 
                        name="addressLine2"
                        value={addressLine2.value}
                        label={t('labels.complementary_address')}
                        type="text"
                        maxLength="200"
                        error={addressLine2.error}
                        theme={theme}
                        onChange={(element) => dispatch(handleChange({ field: "addressLine2", value: element.target.value }))}
                    />
                </Grid>

                <Grid item xs={12} sm={6} md={3}>
                    <SelectWrapper 
                        name="country"
                        value={country.value}
                        label={t('labels.country')}
                        error={country.error}
                        required
                        disabled
                        theme={theme}
                        loading={isLoadingCountry}
                        data={countryList.map(country => ({
                            key: country.id,
                            value: country.description,
                            text: country.description,
                            extraInfo: country.code 
                        }))}
                    />
                   
                </Grid>

                <Grid item xs={12} sm={6} md={3}>
                    <AutocompleteWrapper 
                        id="provinceOrState" 
                        label="State"
                        value={selectedProvinceOrState.description} 
                        onChange={(event, newValue) => dispatch(handleChangeProvinceOrState(newValue))}
                        error={provinceOrState.error} 
                        theme={theme}
                        options={selectedCountry.provinceOrStateList.map(state => ({
                            id: state.id,
                            value: state.description,
                            label: state.description 
                        }))}
                    />
                </Grid>

                <Grid item xs={12} sm={6} md={3}>
                    <FormControlWrapper 
                        name="city"
                        value={city.value}
                        label={t('labels.city')}
                        type="text"
                        maxLength="100"
                        error={city.error}
                        theme={theme}
                        onChange={(element) => dispatch(handleChange({ field: "city", value: element.target.value }))}
                        required
                    />
                </Grid>

                <Grid item xs={12} sm={6} md={3}>
                   <FormControlWrapper 
                        name="postalCode"
                        value={postalCode.value}
                        label={t('labels.postal_code')}
                        type="text"
                        maxLength="20"
                        error={postalCode.error}
                        theme={theme}
                        onChange={(element) => dispatch(handleChange({ field: "postalCode", value: element.target.value }))}
                        required
                    />
                </Grid>

            </Grid>

            <Box sx={{ mt: 2 }}>
                <LoadingButton
                    disableElevation
                    size="large"
                    type="button"
                    variant="contained"
                    color="secondary"
                    loading={isUpdating}
                    onClick={handleUpdateProfile}
                >
                    {t('buttons.update_profile')}
                </LoadingButton>
            </Box>
            </div>
            }
        </>
    )

};


export default OrganizationProfileInfoPage;