// assets
import { IconTools, IconRepeat } from '@tabler/icons';

// constant
export const icons = {
    IconTools,
    IconRepeat
};

const userProject = {
    id: 'user-project',
    title: 'side_menu.project_manager',
    type: 'group',
    roles: ['user', 'subuser', 'member', 'supervisor', 'admin', 'demo', 'sales', 'superadmin', 'it'],
    collapse: false,
    children: [
        {
            id: 'user-project-list',
            title: 'side_menu.project_list',
            type: 'item',
            url: 'main/user-project/list',
            icon: 'IconTools', //icons.IconTools,
            breadcrumbs: false,
            roles: ['user', 'subuser', 'member', 'supervisor', 'admin', 'demo', 'sales', 'superadmin', 'it'],
        },
        {
            id: 'user-project-archive',
            title: 'side_menu.project_archive',
            type: 'item',
            url: 'main/user-project/archive',
            icon: 'IconRepeat', //icons.IconRepeat,
            breadcrumbs: false,
            roles: ['user', 'subuser', 'member', 'supervisor', 'admin', 'demo', 'sales', 'superadmin', 'it'],
        }
    ]
};

export default userProject;
