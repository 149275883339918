import { useDispatch, useSelector } from "react-redux";
import {
    Box,
    FormControl,
    Grid,
    Skeleton,
    useMediaQuery,
    useTheme,
    FormLabel,
    FormGroup,
    FormControlLabel,
    Switch,
  } from "@mui/material";

import {  
    useGetNotificationsMutation,
    useSaveNotificationMutation 
} from "Services/AlertNotificationService";  

import LoadingButton from '@mui/lab/LoadingButton';
import AnimateButton from "ui-component/extended/AnimateButton";

import { handleCheck, handleGet } from "./AlertNotification.Slice";
import { showErrorToaster, showSuccessToaster } from "store/Shared.Slice";
import { useEffect } from "react";
import { useTranslation } from "react-i18next";

const AlertNotificationPage = () => {

    const { t } = useTranslation();
    const theme = useTheme();
    const matchDownSM = useMediaQuery(theme.breakpoints.down("md"));
    const [getNotifications, { isLoading: isLoadingList }] = useGetNotificationsMutation();
    const [saveNotifications, { isLoading: isSaving }] = useSaveNotificationMutation();

    const { notificationList } = useSelector((state) => state.alertNotificationState);
    const dispatch = useDispatch();

    const handleChange = (element, value) => {
        dispatch(handleCheck({
            ...value,
            enabled: element.target.checked
        }));
    };

    const handleSaveNotifications = async () => {

        try {
            const response = await saveNotifications(notificationList).unwrap();
            dispatch(showSuccessToaster(response.message));
        }
        catch(error) {
            // Bad Request
            if (error.data?.message != null) {
                dispatch(showErrorToaster(error.data?.message));
            }
        }

    };

    const handleGetNotifications = async() => {

        const response = await getNotifications().unwrap();
        dispatch(handleGet(response));

    };

    useEffect(() => {
        handleGetNotifications();
        // eslint-disable-next-line react-hooks/exhaustive-deps 
    }, []);


    return(
        <Grid container spacing={matchDownSM ? 0 : 2}>
            <Grid item xs={12} sm={12} md={12} sx={{ mb: 2 }}>
                <FormControl component="fieldset" variant="standard">
                    <FormLabel component="legend" sx={{ mb: 2 }}>{t('labels.enable_disable_notifications')}</FormLabel>
                    <FormGroup>
                        {isLoadingList && <Skeleton variant="rectangular" height={100} />}
                        {notificationList.map((value, index) => 
                            <FormControlLabel
                                key={index}
                                control={
                                    <Switch checked={value.enabled} onChange={(element) => handleChange(element, value)} name={value.name} />
                                }
                                label={value.description}
                            />
                        )}
                    </FormGroup>
                </FormControl>
            </Grid>
            <Box sx={{ mt: 2, ml: 2 }}>
                <AnimateButton>
                    <LoadingButton
                        disableElevation
                        size="large"
                        type="button"
                        variant="contained"
                        color="secondary"
                        loading={isSaving}
                        onClick={handleSaveNotifications}
                    >
                        {t('buttons.save_changes')}
                    </LoadingButton>
                </AnimateButton>
            </Box>
        </Grid>
    );

};

export default AlertNotificationPage;