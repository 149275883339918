import { Link } from 'react-router-dom';

// material-ui
import { ButtonBase } from '@mui/material';

// project imports
import config from 'config';
//import Logo from 'ui-component/Logo';
import Logo from '../../../assets/images/ga-logo-sm.png';

// ==============================|| MAIN LOGO ||============================== //

const LogoSection = () => (
    <ButtonBase disableRipple component={Link} to={config.defaultPath}>
        <img src={Logo} alt="" style={{ width: "75%" }} />
    </ButtonBase>
);

export default LogoSection;
