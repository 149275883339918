// assets
import { 
    IconHeart,
    IconHandOff,
    IconDatabaseImport
} from '@tabler/icons';

// constant
export const icons = {
    IconHeart,
    IconHandOff,
    IconDatabaseImport
};

const manageFunders = {
    id: 'manage-funders',
    title: 'side_menu.manage_funders',
    type: 'group',
    roles: ['user', 'subuser', 'member', 'supervisor', 'admin', 'demo', 'sales', 'superadmin', 'it'],
    collapse: false,
    children: [
        {
            id: 'favorites',
            title: 'side_menu.favorites',
            type: 'item',
            url: 'main/manage-funders/favorites',
            icon: 'IconHeart', //icons.IconHeart,
            breadcrumbs: false,
            roles: ['user', 'subuser', 'member', 'supervisor', 'admin', 'demo', 'sales', 'superadmin', 'it'],
        },
        {
            id: 'blocked-funders',
            title: 'side_menu.blocked_funders',
            type: 'item',
            url: 'main/manage-funders/blocked-funders',
            icon: 'IconHandOff', //icons.IconHandOff,
            breadcrumbs: false,
            roles: ['user', 'subuser', 'member', 'supervisor', 'admin', 'demo', 'sales', 'superadmin', 'it'],
        },
        {
            id: 'custom-databases',
            title: 'side_menu.custom_databases',
            type: 'item',
            url: 'main/manage-funders/custom-databases',
            icon: 'IconDatabaseImport', //icons.IconDatabaseImport,
            breadcrumbs: false,
            roles: ['user', 'subuser', 'member', 'supervisor', 'admin', 'demo', 'sales', 'superadmin', 'it'],
        },
    ]
};

export default manageFunders;
