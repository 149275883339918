import { TextField } from '@mui/material';
import MaskedInput from 'react-text-mask';
import { useTranslation } from "react-i18next";

const MaskedInputWrapper = ({ 
    name, 
    value, 
    label, 
    onChange, 
    maskType, 
    error, 
    theme, 
    required
}) => {

    const { t } = useTranslation();

    const masks = {
        phone: ['+', '1', ' ', '(', /[1-9]/, /\d/, /\d/, ')', ' ', /\d/, /\d/, /\d/, '-', /\d/, /\d/, /\d/, /\d/],
        twoNumbers: [/[0-9]/, /\d/],
        fourNumbers: [/[0-9]/, /[0-9]/, /[0-9]/, /[0-9]/]
    }

    return(
        <MaskedInput
            mask={masks[maskType]}
            className="form-control"
            guide={false}
            id={name}
            onChange={onChange}
            render={(ref, props) => (
                <TextField 
                    fullWidth 
                    label={label} 
                    inputRef={ref} {...props} 
                    value={value}
                    variant="outlined"
                    {...error && {helperText: t(error), error: true}}
                    required={required}
                    sx={{ ...theme.typography.customInput }} 
                />
            )}
        />
    )

}

export default MaskedInputWrapper;