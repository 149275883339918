import { emptySplitApi } from './index'

export const favoritesApi = emptySplitApi.injectEndpoints({
    endpoints: (builder) => ({
        getFavoritesPagedList: builder.mutation({
            query: (model) => ({
                url: `api/v1/foundation-service/favorites?`
                    + `pageNumber=${model?.pageNumber || 0}`
                    + `&pageSize=${model?.pageSize || ''}`
                    + `&orderField=${model?.orderField || 'userName'}`
                    + `&orderDirection=${model?.orderDirection || 'asc'}`,
                method: "GET",
            }),
        }),
        getFavoritesPagedSharedList: builder.mutation({
            query: (model) => ({
                url: `api/v1/foundation-service/shared-favorites?`
                    + `pageNumber=${model?.pageNumber || 0}`
                    + `&pageSize=${model?.pageSize || ''}`
                    + `&orderField=${model?.orderField || 'userName'}`
                    + `&orderDirection=${model?.orderDirection || 'asc'}`,
                method: "GET",
            }),
        }),
        getFavoritesList: builder.mutation({
            query: (model) => ({
                url: `api/v1/foundation-service/favorites/list`,
                method: "GET",
            }),
        }),
        getSharedFavorites: builder.mutation({
            query: () => ({
                url: `api/v1/foundation-service/shared-favorites`,
                method: "GET",
            }),
        }),
        insertFavorites: builder.mutation({
            query: (model) => ({
                url: `api/v1/foundation-service/favorites`,
                method: "POST",
                body: model
            }),
        }),
        duplicateFavorites: builder.mutation({
            query: ({id, ...model}) => ({
                url: `api/v1/foundation-service/favorites/${id}`,
                method: "POST",
                body: model
            }),
        }),
        updateFavorites: builder.mutation({
            query: ({id, ...model}) => ({
                url: `api/v1/foundation-service/favorites/${id}`,
                method: "PATCH",
                body: model
            }),
        }),
        deleteFavorites: builder.mutation({
            query: ({id, ...model}) => ({
                url: `api/v1/foundation-service/favorites/${id}`,
                method: "DELETE",
                body: model
            }),
        }),
        deleteSharedFavorites: builder.mutation({
            query: ({id, ...model}) => ({
                url: `api/v1/foundation-service/favorites/${id}`,
                method: "DELETE",
                body: model
            }),
        }),
    }),
    overrideExisting: false,
});

export const { 
    useGetFavoritesListMutation,
    useGetFavoritesPagedListMutation,
    useInsertFavoritesMutation,
    useUpdateFavoritesMutation,
    useDeleteFavoritesMutation,
    useDuplicateFavoritesMutation,
    useGetFavoritesPagedSharedListMutation,
    useDeleteSharedFavoritesMutation,
    useGetSharedFavoritesMutation,
} = favoritesApi;
