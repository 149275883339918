// assets
import { IconFileInfo, IconLicense, IconBusinessplan, IconMailOpened, IconThumbUp, IconReportAnalytics } from '@tabler/icons';

// constant
export const icons = {
    IconFileInfo,
    IconLicense,
    IconBusinessplan,
    IconMailOpened,
    IconThumbUp,
    IconReportAnalytics
};

const documentGenerator = {
    id: 'document-generator',
    title: 'side_menu.document_generator',
    type: 'group',
    roles: ['user', 'subuser', 'member', 'supervisor', 'admin', 'demo', 'sales', 'superadmin', 'it'],
    collapse: false,
    children: [
        {
            id: 'inquiry',
            title: 'side_menu.inquiry',
            type: 'item',
            url: 'main/document-generator/inquiry',
            icon: 'IconFileInfo', //icons.IconFileInfo,
            breadcrumbs: false,
            roles: ['user', 'subuser', 'member', 'supervisor', 'admin', 'demo', 'sales', 'superadmin', 'it'],
        },
        {
            id: 'proposal',
            title: 'side_menu.proposal',
            type: 'item',
            url: 'main/document-generator/proposal',
            icon: 'IconLicense', //icons.IconLicense,
            breadcrumbs: false,
            roles: ['user', 'subuser', 'member', 'supervisor', 'admin', 'demo', 'sales', 'superadmin', 'it'],
        },
        {
            id: 'thank-you-letter',
            title: 'side_menu.thank_you',
            type: 'item',
            url: 'main/document-generator/thank-you',
            icon: 'IconThumbUp', //icons.IconThumbUp,
            breadcrumbs: false,
            roles: ['user', 'subuser', 'member', 'supervisor', 'admin', 'demo', 'sales', 'superadmin', 'it'],
        },
        {
            id: 'impact-report-letter',
            title: 'side_menu.impact_report',
            type: 'item',
            url: 'main/document-generator/impact-report',
            icon: 'IconReportAnalytics', //icons.IconReportAnalytics,
            breadcrumbs: false,
            roles: ['user', 'subuser', 'member', 'supervisor', 'admin', 'demo', 'sales', 'superadmin', 'it'],
        },
        {
            id: 'custom-letter',
            title: 'side_menu.custom_letter',
            type: 'item',
            url: 'main/document-generator/custom-letter',
            icon: 'IconMailOpened', //icons.IconMailOpened,
            breadcrumbs: false,
            roles: ['user', 'subuser', 'member', 'supervisor', 'admin', 'demo', 'sales', 'superadmin', 'it'],
        }
    ]
};

export default documentGenerator;
