import { createSlice } from "@reduxjs/toolkit";
import { organizationAdditionalEmailApi } from "Services/OrganizationAdditionalEmailService";

export const initialState = {
    isDialogOpen: false,
    loadingList: false,
    additionalEmailListPagedData: {
        pageNumber: 1,
        pageSize: 5,
        pageCount: 0,
        totalCount: 0,
        items: []
    },
    newEmailAddress: {
        value: '',
        error: ''
    },
    emailAddress: {
        value: '',
        error: ''
    },
    previousEmail: ''
};

const organizationAdditionalEmailSlice = createSlice({
    name: "organization-additional-email",
    initialState,
    reducers: {
        handleChange: (state, action) => {
            state[action.payload.field].error = "";
            state[action.payload.field].value = action.payload.value;            
        },
        handleModelErrors: (state, action) => {
            Object.entries(action.payload).forEach(([field, errors]) => { 
                state[field].error = errors[0];
            });
        },
        closeDialog: (state, action) => {
            state.isDialogOpen = false;
        },
        openDialog: (state, action) => {
            state.isDialogOpen = true;
            state.newEmailAddress.value = '';
            state.newEmailAddress.error = '';
        },
    },
    extraReducers: (builder) => {
        builder.addMatcher(
            organizationAdditionalEmailApi.endpoints.getAdditionalEmailPagedList.matchFulfilled, (state, action) => {

                state.emailAddress.value = action.payload.mainEmailAddress || '';
                state.previousEmail = action.payload.mainEmailAddress || '';
                state.loadingList = false;
    
                state.additionalEmailListPagedData.pageNumber = action.payload.pagedData.pageNumber;
                state.additionalEmailListPagedData.pageSize = action.payload.pagedData.pageSize;
                state.additionalEmailListPagedData.pageCount = action.payload.pagedData.pageCount;
                state.additionalEmailListPagedData.totalCount = action.payload.pagedData.totalCount;
                state.additionalEmailListPagedData.items = action.payload.pagedData.items;
            }
        )
        .addMatcher(
            organizationAdditionalEmailApi.endpoints.getAdditionalEmailPagedList.matchPending, (state, action) => {
                state.loadingList = true;
            }
        )
    }
});

export const { 
    handleChange,
    handleModelErrors,
    closeDialog,
    openDialog,
} = organizationAdditionalEmailSlice.actions;

export default organizationAdditionalEmailSlice.reducer;
