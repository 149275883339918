import { useRef } from "react";
import { v4 as uuidv4 } from "uuid";
import { useDispatch, useSelector } from "react-redux";
import {
    Box,
    Grid,
    Skeleton,
    Typography,
    useMediaQuery,
    useTheme,
  } from "@mui/material";

import LoadingButton from '@mui/lab/LoadingButton';
import { useGetUserProfileQuery, useUpdateUserProfileMutation } from "Services/UserProfileService";
import { handleChange, handleModelErrors } from "./ProfileInfo.Slice";
import { showSuccessToaster, showErrorToaster } from "store/Shared.Slice";
import { useTranslation } from "react-i18next";
import FormControlWrapper from "components/FormControlWrapper";
import MaskedInputWrapper from "components/MaskedInputWapper";
import { usePrompt } from "react-router-dom";


const ProfileInfoPage = () => {

    const {
        email,
        userName,
        firstName,
        lastName,
        position,
        phoneNumber,
        userNameEmail,
        currentProfileInfo
    } = useSelector((state) => state.userProfileState);

    const sessionId = useRef(uuidv4());
    const theme = useTheme();
    const dispatch = useDispatch();
    const matchDownSM = useMediaQuery(theme.breakpoints.down("md"));
    const { t } = useTranslation();
    const { isLoading: isLoadingUserProfile } = useGetUserProfileQuery(sessionId.current);
    const [updateUserProfile, { isLoading: isUpdating }] = useUpdateUserProfileMutation();

    usePrompt(
        "You have unsaved changes. Are you sure to discard all changes made?",
        userName.value !== currentProfileInfo.userName
        || firstName.value !== currentProfileInfo.firstName
        || lastName.value !== currentProfileInfo.lastName
        || (position.value ?? '') !== (currentProfileInfo.position ?? '')
        || phoneNumber.value !== currentProfileInfo.phoneNumber
    );

    const handleUpdateProfile = async() => {

        try {
            const model = {
                email: email.value,
                userName: userName.value,
                firstName: firstName.value,
                lastName: lastName.value,
                position: position.value,
                phoneNumber: phoneNumber.value
            };

            const response = await updateUserProfile(model).unwrap();
            dispatch(showSuccessToaster(response.message));
        }
        catch(error) {
            // Model Errors2
            if (error.data?.errors != null) {
                dispatch(showErrorToaster(t("errors.model_error")));
                dispatch(handleModelErrors(error.data?.errors));
            }

            // Bad Request
            if (error.data?.message != null) {
                dispatch(showErrorToaster(error.data?.message));
            }
        }
    }

    return (
        <>
            {isLoadingUserProfile 
            ?  
            <Grid container>
                <Grid item xs={12} sm={12} md={12}>
                    <Skeleton height={200} />
                </Grid>
            </Grid>
            :
            <div>
            <Grid container spacing={matchDownSM ? 0 : 2}>
                <Grid item xs={12} sm={12} md={12}>
                    <Typography variant="h4">{t('labels.user_information')}</Typography>
                </Grid>
                <Grid item xs={4} sm={4} md={4}>
                    <FormControlWrapper 
                        name="firstName"
                        value={firstName.value}
                        label={t('labels.first_name')}
                        type="text"
                        maxLength="100"
                        error={firstName.error}
                        theme={theme}
                        onChange={(element) => dispatch(handleChange({ field: "firstName", value: element.target.value }))}
                        required
                    />
                </Grid>

                <Grid item xs={4} sm={4} md={4}>
                    <FormControlWrapper 
                        name="lastName"
                        value={lastName.value}
                        label={t('labels.last_name')}
                        type="text"
                        maxLength="100"
                        error={lastName.error}
                        theme={theme}
                        onChange={(element) => dispatch(handleChange({ field: "lastName", value: element.target.value }))}
                        required
                    />
                </Grid>

                <Grid item xs={4} sm={4} md={4}>
                    <MaskedInputWrapper 
                        name="phoneNumber" 
                        value={phoneNumber.value}
                        label={t('labels.phone')}
                        onChange={(element) => dispatch(handleChange({ field: "phoneNumber", value: element.target.value }))}
                        maskType="phone" 
                        error={phoneNumber.error}
                        theme={theme}
                        required
                    />
                </Grid>
            </Grid>

            <Grid container spacing={matchDownSM ? 0 : 2}>
                <Grid item xs={8} sm={8} md={8}>
                    <FormControlWrapper 
                        name="userName"
                        value={userNameEmail.value}
                        label="Login E-mail"
                        type="text"
                        maxLength="20"
                        error={userNameEmail.error}
                        theme={theme}
                        onChange={(element) => dispatch(handleChange({ field: "userName", value: element.target.value }))}
                        disabled
                    />
                </Grid>

                <Grid item xs={4} sm={4} md={4}>
                    <FormControlWrapper 
                        name="position"
                        value={position.value}
                        label={t('labels.position')}
                        type="text"
                        maxLength="100"
                        error={position.error}
                        theme={theme}
                        onChange={(element) => dispatch(handleChange({ field: "position", value: element.target.value }))}
                    />
                </Grid>
            </Grid>

            <Box sx={{ mt: 2 }}>
                <LoadingButton
                    disableElevation
                    size="large"
                    type="button"
                    variant="contained"
                    color="secondary"
                    loading={isUpdating}
                    onClick={handleUpdateProfile}
                >
                    {t('buttons.update_profile')}
                </LoadingButton>
            </Box>
            </div>
            }
        </>
    )

};


export default ProfileInfoPage;