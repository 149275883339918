import { emptySplitApi } from './index'

export const projectBudgetApi = emptySplitApi.injectEndpoints({
  endpoints: (builder) => ({
        getAllIncomePagedList: builder.mutation({
            query: (model) => ({
                url: `api/v1/project-service/project-budget?`
                    + `userProjectId=${model?.userProjectId || ''}`
                    + `&budgetType=Income`
                    + `&pageNumber=${model?.pageNumber || 0}`
                    + `&pageSize=${model?.pageSize || ''}`
                    + `&orderField=${model?.orderField || 'description'}`
                    + `&orderDirection=${model?.orderDirection || 'asc'}`,
                method: "GET"
            }),
        }),
        getAllExpensePagedList: builder.mutation({
            query: (model) => ({
                url: `api/v1/project-service/project-budget?`
                    + `userProjectId=${model?.userProjectId || ''}`
                    + `&budgetType=Expense`
                    + `&pageNumber=${model?.pageNumber || 0}`
                    + `&pageSize=${model?.pageSize || ''}`
                    + `&orderField=${model?.orderField || 'description'}`
                    + `&orderDirection=${model?.orderDirection || 'asc'}`,
                method: "GET"
            }),
        }),
        insertBudget: builder.mutation({
            query: (model) => ({
                url: `api/v1/project-service/project-budget`,
                method: "POST",
                body: model
            }),
        }),
        resetBudget: builder.mutation({
            query: (model) => ({
                url: `api/v1/project-service/project-budget/reset/${model.budgetType}/${model.userProjectId}`,
                method: "PUT",
            }),
        }),
        deleteBudget: builder.mutation({
            query: ({ userProjectId, budgetType, incomeSelected }) => ({
                url: `api/v1/project-service/project-budget/${budgetType}/${userProjectId}`,
                method: "DELETE",
                body: incomeSelected
            }),
        }),
        deleteExclusiveBudget: builder.mutation({
            query: ({ userProjectId, budgetType, incomeSelected }) => ({
                url: `api/v1/project-service/project-budget/${budgetType}/exclusive/${userProjectId}`,
                method: "DELETE",
                body: incomeSelected
            }),
        }),
        updateBudget: builder.mutation({
            query: ({ id, ...model }) => ({
                url: `api/v1/project-service/project-budget/${id}`,
                method: "PATCH",
                body: model
            }),
        }),
    }),
  overrideExisting: false,
});

export const { 
    useGetAllIncomePagedListMutation,
    useGetAllExpensePagedListMutation,
    useInsertBudgetMutation,
    useResetBudgetMutation,
    useDeleteBudgetMutation,
    useDeleteExclusiveBudgetMutation,
    useUpdateBudgetMutation
} = projectBudgetApi;
