// assets
import { IconWoman } from '@tabler/icons';

// constant
export const icons = {
    IconWoman
};

const grantSecretary = {
    id: 'grant-secretary',
    type: 'group',
    title: 'side_menu.grant_secretary',
    roles: ['user', 'subuser', 'member', 'supervisor', 'admin', 'demo', 'sales', 'superadmin', 'it'],
    collapse: false,
    /*children: [
        {
            id: 'grant-secretary-1',
            title: 'side_menu.grant_secretary',
            type: 'item',
            url: 'main/grant-secretary',
            icon: 'IconWoman', //icons.IconWoman,
            breadcrumbs: false,
            roles: ['user', 'subuser', 'admin', 'demo', 'sales', 'superadmin', 'it'],
        },
    ]*/
};

export default grantSecretary;
