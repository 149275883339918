import {
    FormControl,
    FormHelperText,
    InputLabel,
    CircularProgress,
    Select,
    MenuItem,
    ListItemText,
    Typography,
    Grid
  } from "@mui/material";

  import { useTranslation } from "react-i18next";
  
  const SelectWrapper = ({
      name, 
      value,
      label, 
      error, 
      required,
      theme, 
      onChange,
      loading,
      data = [],
      disabled,
      sx,
  }) => {

    const { t } = useTranslation();
  
    return(
        <FormControl 
            fullWidth 
            error={error !== ""}
            required={required}
            sx={{ ...theme.typography.customAutocomplete, ...sx }}
            disabled={disabled}
        >
            <InputLabel id={`${name}Label`}>
                {label}
            </InputLabel>
            {loading && 
                <CircularProgress color="inherit" size={20} style={{position: 'absolute', zIndex: 999, top: '20px', right: '40px'}} /> 
            }
            <Select
                id={name}
                labelId={`${name}Label`}
                label={label}
                value={value}
                onChange={onChange}
            >  
            {data.map((item) => (
                <MenuItem key={item.key} value={item.value}>
                    <Grid container justifyContent="space-between">
                        <Grid item>
                            <ListItemText>{item.text}</ListItemText>
                        </Grid>
                        <Grid item>
                        {item.extraInfo &&
                            (<Typography variant="caption" sx={{ paddingRight: '20px' }}>
                                {item.extraInfo}
                            </Typography>)
                        }
                        </Grid>
                    </Grid>
                </MenuItem>
            ))}
            </Select>
            {error !== "" && (
                <FormHelperText error id={`${name}Error`}>
                    {t(error)}
                </FormHelperText>
            )}
        </FormControl>
        )
    }
  
  export default SelectWrapper;