import { emptySplitApi } from './index'

const authManagementApi = emptySplitApi.injectEndpoints({
  endpoints: (builder) => ({
    register: builder.mutation({
      query: (model) => ({
        url: `api/v1/auth-service/authManagement/register`,
        method: "POST",
        body: model,
      }),
    }),
    passwordRecovery: builder.mutation({
      query: (model) => ({
        url: `api/v1/auth-service/authManagement/password-recovery`,
        method: "POST",
        body: model,
      }),
    }),
    passwordReset: builder.mutation({
      query: (model) => ({
        url: `api/v1/auth-service/authManagement/password-reset`,
        method: "POST",
        body: model,
      }),
    }),
    settingUpAccount: builder.mutation({
        query: (model) => ({
          url: `api/v1/auth-service/authManagement/setting-up-account`,
          method: "POST",
          body: model,
        }),
      }),
    confirmRegisterUser: builder.mutation({
        query: (model) => ({
          url: `api/v1/auth-service/authManagement/confirm-register-user`,
          method: "POST",
          body: model,
        }),
      }),
    registerValidation: builder.mutation({
        query: (model) => ({
            url: `api/v1/auth-service/authManagement/register-validation`,
            method: "POST",
            body: model,
        }),
      }),
    updatePassword: builder.mutation({
      query: (model) => ({
        url: `api/v1/auth-service/authManagement/update-password`,
        method: "POST",
        body: model,
      })
    })
  }),
  overrideExisting: false,
});

export const { 
  useRegisterMutation, 
  usePasswordRecoveryMutation,
  usePasswordResetMutation,
  useSettingUpAccountMutation,
  useConfirmRegisterUserMutation,
  useRegisterValidationMutation,
  useUpdatePasswordMutation,
} = authManagementApi;
