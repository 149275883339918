
const startcase = (str, force = true) => {
    if(!str) return null;
    
    if(force === true)
        str = str.toLowerCase();

    return str.replace(/\b\w/g, match => match.toUpperCase());
}

export default startcase;