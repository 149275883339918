import { emptySplitApi } from './index'

export const customDatabaseApi = emptySplitApi.injectEndpoints({
  endpoints: (builder) => ({
        getRequiredOptionalFields: builder.mutation({
            query: (model) => ({
                url: `api/v1/foundation-service/custom-database/required-optional-fields`,
                method: "GET"
            }),
        }),
        confirmImportCustomDatabase: builder.mutation({
            query: (model) => ({
                url: `api/v1/foundation-service/custom-database/confirm-import`,
                method: "POST",
                body: model
            }),
        }),
        getCustomDatabaseList: builder.mutation({
            query: (model) => ({
                url: `api/v1/foundation-service/custom-database/list?`
                    + `pageNumber=${model?.pageNumber || 0}`
                    + `&pageSize=${model?.pageSize || ''}`
                    + `&orderField=${model?.orderField || 'importedDate'}`
                    + `&orderDirection=${model?.orderDirection || 'asc'}`,
                method: "GET"
            }),
        }),
        deleteCustomDatabase: builder.mutation({
            query: (customDatabaseId) => ({
                url: `api/v1/foundation-service/custom-database/delete/${customDatabaseId}`,
                method: "DELETE"
            }),
        }),
        getFileList: builder.mutation({
            query: () => ({
                url: `api/v1/foundation-service/custom-database/file-list`,
                method: "GET"
            }),
        }),
        getMappedFields: builder.mutation({
            query: (customItemId) => ({
                url: `api/v1/foundation-service/custom-database/mapped-fields/${customItemId}`,
                method: "GET"
            }),
        }),
    }),
  overrideExisting: false,
});

export const { 
    useGetRequiredOptionalFieldsMutation,
    useConfirmImportCustomDatabaseMutation,
    useGetCustomDatabaseListMutation,
    useDeleteCustomDatabaseMutation,
    useGetFileListMutation,
    useGetMappedFieldsMutation
} = customDatabaseApi;
