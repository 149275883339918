import { emptySplitApi } from "./index";

export const sectorApi = emptySplitApi.injectEndpoints({
    endpoints: (builder) => ({
        getSectorList: builder.query({
            query: () => ({
                url: `api/v1/search-engine-service/sector`,
                method: "GET",
            }),
        }),
        getSectorListV1: builder.mutation({
            query: () => ({
                url: `api/v1/search-engine-service/sector`,
                method: "GET",
            }),
        }),
        getSubSectorListBySectorIds: builder.mutation({
            query: (model) => ({
                url: `api/v1/search-engine-service/sector/sub-sector`,
                method: "POST",
                body: model,
            }),
        }),
    }),
    overrideExisting: false,
});

export const {
    useGetSectorListQuery,
    useGetSubSectorListBySectorIdsMutation,
    useGetSectorListV1Mutation
} = sectorApi;
