import { useState } from "react";
import {
  Box,
  Grid,
  Typography,
  useTheme,
} from "@mui/material";
import { useTranslation } from "react-i18next";
import FormControlWrapper from "components/FormControlWrapper";
import LoadingButton from '@mui/lab/LoadingButton';
import { useUpdatePasswordMutation } from "Services/AuthManagementService";
import { showSuccessToaster, showErrorToaster } from "store/Shared.Slice";
import { useDispatch } from "react-redux";
import PasswordInputWrapper from "components/PasswordInputWrapper";

const ResetPassword = () => {
  const [updatePassword, {isLoading}] = useUpdatePasswordMutation();
  const { t } = useTranslation();
  const theme = useTheme();
  const dispatch = useDispatch();

  const [currentPassword, setCurrentPassword] = useState({
    value: "",
    error: ""
  });
  const [newPassword, setNewPassword] = useState({
    value: "",
    error: ""
  });
  const [confirmPassword, setConfirmPassword] = useState({
    value: "",
    error: ""
  });

  const isButtonDisabled = [currentPassword, newPassword, confirmPassword]
  .some(({value, error}) => value.length === 0 || error.length > 0)

  const handleCurrentPasswordChange = (e) => {
    const {value} = e.target;
    const npValue = newPassword.value;

    setCurrentPassword({...currentPassword, value, error: value.length === 0 ? t("fieldRequired")  : ''});

    setNewPassword((state) => ({...state, 
      error: npValue.length > 0 && npValue === value 
      ? t("pickupNewPassword")
      : ''}));
  }

  const handleNewPasswordChange = (e) => {
    const {value} = e.target;    
    const valueLen = value.length;
    const cpValue = confirmPassword.value;
    const minimumCharaters = 6;

    let error = '';
    if(valueLen === 0){
      error = t("fieldRequired");
    }
    else if(valueLen < minimumCharaters){
      error = t("passwordMinCharacter", {minimumCharaters});
    }
    else if(value === currentPassword.value){
      error = t("pickupNewPassword");
    }

    setNewPassword({value, error});    
    setConfirmPassword((state) => ({...state, error: cpValue.length > 0 && value !== cpValue ? t("enterSameValueAgain") : ''}));
  }

  const handleConfirmPasswordChange = (e) => {
    const {value} = e.target;
    let error = '';
    if(value.length === 0){
      error = t("fieldRequired");
    }
    else if(value !== newPassword.value){
      error = t("enterSameValueAgain")
    }

    setConfirmPassword({value, error});
  }

  const handlePasswordReset = async (e) => {
    if(isButtonDisabled) return;
    
    try {
      const model = {
        oldPassword: currentPassword.value,
        newPassword: newPassword.value,
      };

      const response = await updatePassword(model).unwrap();
      dispatch(showSuccessToaster(response.message));

      //Reset states
      [setCurrentPassword, setNewPassword, setConfirmPassword]
      .forEach(f => f({value: "", error: ""}));
  }
  catch(error) {
      // Model Errors2
      if (error.data?.errors != null) {
          const errorList = Object.values(error.data?.errors);

          if(errorList.length > 0)
          {
            errorList.forEach(errValue => {
              dispatch(showErrorToaster(errValue));
            })
          }
          else{
            dispatch(showErrorToaster(t("errors.model_error")));
          }
      }

      // Bad Request
      if (error.data?.message != null) {
          dispatch(showErrorToaster(error.data?.message));
      }
    }
  }

  return (
    <>
      <Grid container spacing={2}>
        <Grid item xs={12} sm={12} md={12}>
          <Typography variant="h4">Reset your password</Typography>
        </Grid>
        <Grid item xs={12} sm={12} md={6}>
          <PasswordInputWrapper 
          name="password"
          value={currentPassword.value}
          label={t('labels.current_password')}
          type="password"
          maxLength="100"
          error={currentPassword.error}
          theme={theme}
          onChange={(e) => handleCurrentPasswordChange(e)}
          required
          />
        </Grid>
        <Grid container item spacing={2}>
          <Grid item xs={12} sm={12} md={6}>
            <PasswordInputWrapper 
              name="new-password"
              value={newPassword.value}
              label={t('labels.new_password')}
              maxLength="100"
              error={newPassword.error}
              theme={theme}
              onChange={(e) => handleNewPasswordChange(e)}
              required
            />
          </Grid>
          <Grid item xs={12} sm={12} md={6}>
            <PasswordInputWrapper 
              name="confirm-password"
              value={confirmPassword.value}
              label={t('labels.confirm_password')}
              maxLength="100"
              error={confirmPassword.error}
              theme={theme}
              onChange={(e) => handleConfirmPasswordChange(e)}
              required
            />
          </Grid>
        </Grid>
      </Grid>
      <Box sx={{ mt: 2 }}>
          <LoadingButton
              disabled={isButtonDisabled}
              disableElevation
              size="large"
              type="button"
              variant="contained"
              color="secondary"
              loading={isLoading}
              onClick={handlePasswordReset}
          >
              {t('buttons.reset_password')}
          </LoadingButton>
      </Box>
    </>
  );
};

export default ResetPassword;
