import { emptySplitApi } from "./index";

export const sectorCAApi = emptySplitApi.injectEndpoints({
    endpoints: (builder) => ({
        getSectorListCA: builder.query({
            query: () => ({
                url: `api/v1/search-engine-ca-service/sector`,
                method: "GET",
            }),
        }),
        getSubSectorListBySectorNamesCA: builder.mutation({
            query: (model) => ({
                url: `api/v1/search-engine-ca-service/sector/sub-sector`,
                method: "POST",
                body: model,
            }),
        }),
        getDonationsSubSectorListBySectorNamesCA: builder.mutation({
            query: (model) => ({
                url: `api/v1/search-engine-ca-service/sector/donations-sub-sector`,
                method: "POST",
                body: model,
            }),
        }),
    }),
    overrideExisting: false,
});

export const {
    useGetSectorListCAQuery,
    useGetSubSectorListBySectorNamesCAMutation,
    useGetDonationsSubSectorListBySectorNamesCAMutation
} = sectorCAApi;
