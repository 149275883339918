import { createSlice } from "@reduxjs/toolkit";
import { userProjectApi } from 'Services/UserProjectService'
import { sharedProjectApi } from 'Services/SharedProjectService'

export const initialState = {
    openFormDialog: false,
    openDeleteDialog: false,
    tabIndex: 0,
    pagedList: {
        pageNumber: 0,
        pageSize: 10,
        pageCount: 0,
        totalCount: 0,
        showArchived: false,
        orderField: 'projectStatus',
        orderDirection: 'asc',
        items: [],
        activeUserProjectId: ''
    },
    pagedSharedList: {
        pageNumber: 0,
        pageSize: 10,
        pageCount: 0,
        totalCount: 0,
        showArchived: false,
        orderField: 'projectStatus',
        orderDirection: 'asc',
        items: [],
        activeUserProjectId: ''
    },
    projectStatusList: [ 'Active', 'Completed', 'InProgress' ],
    id: null,
    searchTerm: {
        value: '',
        error: ''
    },
    name: {
        value: '',
        error: ''
    },
    startDate: {
        value: null,
        error: ''
    },
    endDate: {
        value: null,
        error: ''
    },
    projectCost: {
        value: '',
        error: ''
    },
    projectDescription: {
        value: '',
        error: ''
    },
    projectStatus: {
        value: '',
        error: ''
    }
};

const userProjectSlice = createSlice({
    name: "user-project",
    initialState,
    reducers: {
        handleCloseDialog: (state, action) => {
            state.openFormDialog = false;
            state.openDeleteDialog = false;
        },
        handleOpenDeleteDialog: (state, action) => {
            state.id = action.payload.id;
            state.openFormDialog = false;
            state.openDeleteDialog = true;
        },
        handleCreateNewProject: (state, action) => {
            state.openFormDialog = true;
            state.id = null;

            state.name.value = '';
            state.name.error = '';

            state.startDate.value = null;
            state.startDate.error = '';

            state.endDate.value = null;
            state.endDate.error = '';

            state.projectCost.value = '';
            state.projectCost.error = '';

            state.projectDescription.value = '';
            state.projectDescription.error = '';

            state.projectStatus.value = '';
            state.projectStatus.error = '';
        },
        handleEditUserProject: (state, action) => {
            state.openFormDialog = true;
            state.id = action.payload.id;

            state.name.value = action.payload.name;
            state.name.error = '';

            state.startDate.value = action.payload.startDate;
            state.startDate.error = '';

            state.endDate.value = action.payload.endDate;
            state.endDate.error = '';

            state.projectCost.value = action.payload.projectCost;
            state.projectCost.error = '';

            state.projectDescription.value = action.payload.projectDescription;
            state.projectDescription.error = '';

            state.projectStatus.value = '';
            state.projectStatus.error = '';
        },
        handleChange: (state, action) => {
            state[action.payload.field].error = '';
            state[action.payload.field].value = action.payload.value;            
        },
        handleModelErrors: (state, action) => {
            Object.entries(action.payload).forEach(([field, errors]) => { 
                state[field].error = errors[0];
            });
        },
        setTabIndex: (state, action) => {
            state.tabIndex = action.payload;
        },
        setSharedActiveProject: (state, action) => {
            state.pagedSharedList.activeUserProjectId = action.payload;
        }
    },
    extraReducers: (builder) => {
        builder.addMatcher(
            userProjectApi.endpoints.getAll.matchFulfilled, (state, action) => {
                state.pagedList.pageNumber = action.payload.pageNumber;
                state.pagedList.pageSize = action.payload.pageSize;
                state.pagedList.pageCount = action.payload.pageCount;
                state.pagedList.totalCount = action.payload.totalCount;
                state.pagedList.orderField = action.payload.orderField;
                state.pagedList.orderDirection = action.payload.orderDirection;
                state.pagedList.items = action.payload.items;
                state.pagedList.activeUserProjectId = action.payload.data.activeUserProjectId;
            }
        ).addMatcher(
            sharedProjectApi.endpoints.getAllShared.matchFulfilled, (state, action) => {
                state.pagedSharedList.pageNumber = action.payload.pageNumber;
                state.pagedSharedList.pageSize = action.payload.pageSize;
                state.pagedSharedList.pageCount = action.payload.pageCount;
                state.pagedSharedList.totalCount = action.payload.totalCount;
                state.pagedSharedList.orderField = action.payload.orderField;
                state.pagedSharedList.orderDirection = action.payload.orderDirection;
                state.pagedSharedList.items = action.payload.items;
            }
        )
    }
});

export const {
    handleCloseDialog, 
    handleChange,
    handleModelErrors,
    handleCreateNewProject,
    handleEditUserProject,
    handleOpenDeleteDialog,
    setTabIndex,
    setSharedActiveProject,
} = userProjectSlice.actions;

export default userProjectSlice.reducer;