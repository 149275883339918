import { createSlice } from "@reduxjs/toolkit";
import { customDatabaseApi } from "Services/CustomDatabaseService";

export const initialState = {
    activeStep: 0,
    userFields: [],
    requiredOptionalFields: [],
    deletedUserFields: []
};

const customDatabaseSlice = createSlice({
    name: "custom-database",
    initialState,
    reducers: {
        handleChangeActiveStep: (state, action) => {
            state.activeStep = action.payload;
        },
        handleImportUserFields: (state, action) => {
            state.userFields = action.payload;
            state.activeStep = 1;
            state.deletedUserFields = [];
            
            state.requiredOptionalFields.forEach(item => {
                item.userFieldId = null;
            });
        },
        handleClearAll: (state, action) => {
            state.userFields = [];
            state.activeStep = 0;
            state.deletedUserFields = [];
            
            state.requiredOptionalFields.forEach(item => {
                item.userFieldId = null;
            });
        },
        handleDropRequiredOptionalFields: (state, action) => {
            const requiredOptionalFieldIndex = state.requiredOptionalFields.findIndex(o => o.fieldId === action.payload.destinationId);
            
            const userFieldIndex = state.userFields.findIndex(o => o.fieldId === action.payload.sourceId);
            const userField = state.userFields.find(o => o.fieldId === action.payload.sourceId);

            if (userFieldIndex !== -1) {
                state.deletedUserFields.push(userField);
                state.userFields.splice(userFieldIndex, 1);
    
                state.requiredOptionalFields[requiredOptionalFieldIndex] = {
                    ...state.requiredOptionalFields[requiredOptionalFieldIndex], 
                    userFieldId: action.payload.sourceId
                };
            }

           
        },
        handleDropUserFields: (state, action) => {
            const requiredOptionalFieldIndex = state.requiredOptionalFields.findIndex(o => o.userFieldId === action.payload.sourceId);

            const deletedField = state.deletedUserFields.find(o => o.fieldId === action.payload.sourceId);
            const deletedFieldIndex = state.deletedUserFields.findIndex(o => o.fieldId === action.payload.sourceId);

            if (deletedFieldIndex !== -1) {
                state.deletedUserFields.splice(deletedFieldIndex, 1);
                state.userFields.push(deletedField);
    
                state.requiredOptionalFields[requiredOptionalFieldIndex] = {
                    ...state.requiredOptionalFields[requiredOptionalFieldIndex],
                    userFieldId: null
                };
            }
        }
    },
    extraReducers: (builder) => {
        builder.addMatcher(
            customDatabaseApi.endpoints.getRequiredOptionalFields.matchFulfilled, (state, action) => {
                state.requiredOptionalFields = action.payload.map(item => ({
                    ...item,
                    userFieldId: null
                }));
            }
        )
    }
});

export const { 
    handleChangeActiveStep,
    handleImportUserFields,
    handleDropRequiredOptionalFields,
    handleDropUserFields,
    handleClearAll
} = customDatabaseSlice.actions;

export default customDatabaseSlice.reducer;
