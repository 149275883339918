import { createSlice } from "@reduxjs/toolkit";
import { userProjectApi } from "Services/UserProjectService";
import { customLetterApi } from "Services/CustomLetterService";

export const initialState = {
    id: null,
    userProject: {
        id: null,
        value: null,
        label: null
    },
    userProjectToChange: {
        id: null,
        value: null,
        label: null
    },
    allProjectList: [],
    activeStep: 0,
    customDocument: {
        template: '',
        letter: ''
    },
    stepList: [],
    forceDisabled: false,
    autosaveEnabled: false,
};

const customSlice = createSlice({
    name: "document-generator-custom",
    initialState,
    reducers: {
        handleChangeUserProject: (state, action) => {
            if (action.payload !== null) {
                state.userProjectToChange = state.allProjectList.find(o => o.id === action.payload.id);
            }
        },
        handleConfirmChange: (state, action) => {
            state.userProject = state.userProjectToChange;
        },
        handleStep: (state, action) => {
            state.activeStep = action.payload;
        },
        handleChangeDocument: (state, action) => {
            state.customDocument[action.payload.stepName] = action.payload.document;
        },
        handleSaveSuccess: (state, action) => {
            state.id = action.payload.data;
        },
        handleAutoSaveEnabled: (state, action) => {
            state.autosaveEnabled = action.payload;
        },
        handleClear: (state, action) => {
            state.id = null;
            state.activeStep = 0;
            state.userProject.id = null;
            state.userProject.value = null;
            state.userProject.label = null;
            state.autosaveEnabled = false;
            state.customDocument.template = "";
            state.customDocument.letter = "";
        },
        handleFillAllProjects: (state, action) => {
            const { selectedProject, projects } = action.payload;
            state.forceDisabled = action.payload.length === 0;
            state.autosaveEnabled = false;
            state.allProjectList = projects ?? [];
            state.userProject = selectedProject;
        },
    },
    extraReducers: (builder) => {
        builder
        .addMatcher(
            customLetterApi.endpoints.getCustomStepList.matchFulfilled, (state, action) => {
                state.stepList = action.payload;
            }
        )
        .addMatcher(
            customLetterApi.endpoints.getCustomLetter.matchFulfilled, (state, action) => {
                state.id = action.payload.id;
                state.customDocument.template = action.payload.template;
                state.customDocument.letter = action.payload.letter;
            }
        )
    }

});

export const { 
    handleChangeUserProject,
    handleStep,
    handleChangeDocument,
    handleSaveSuccess,
    handleAutoSaveEnabled,
    handleClear,
    handleConfirmChange,
    handleFillAllProjects,
} = customSlice.actions;

export default customSlice.reducer;
