import { Skeleton, Typography } from "@mui/material";
import { useReadUserNotificationMutation } from "Services/UserNotificationService";
import DialogConfirmWrapper from "components/DialogConfirmWrapper";
import { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { showErrorToaster } from "store/Shared.Slice";

const NotificationPopup = ({
    openPopup,
    onClose,
    notificationId,
    onReadNotification
}) => {

    const dispatch = useDispatch();
    const [ readUserNotification, { isLoading: isReading } ] = useReadUserNotificationMutation();
    const [ userNotification, setUserNotification ] = useState({
        title: '',
        description: ''
    });

    const handleReadNotification = () => {

        readUserNotification(notificationId).unwrap().then(response => {
            setUserNotification({
                ...userNotification,
                title: response.title,
                description: response.description
            });

            onReadNotification({ 
                allNotifications: response.allNotifications,  
                unreadNotifications: response.unreadNotifications,  
            });

        }, error => {
            // Bad Request
            if (error.data?.message != null) {
                dispatch(showErrorToaster(error.data?.message));
            }
        })

    }

    useEffect(() => {
        if (openPopup) {
            handleReadNotification();
        }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [openPopup]);

    return (
        <DialogConfirmWrapper
            size="lg"
            openPopup={openPopup}
            onClose={onClose}
            cancelLabel="Close"
            titleMessage={
                <>
                  {isReading && (
                     <Skeleton height={50} width={200} />
                  )}
                  {!isReading && (
                     userNotification.title
                  )}
                </>
            }
            popupMessage={
                <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                  {isReading && (
                        <Skeleton height={320} width={400} />
                  )}
                  {!isReading && (
                        <Typography 
                            variant="body1" 
                            height="400px" 
                            dangerouslySetInnerHTML={{ __html: userNotification.description }}
                            sx={{ 
                                'p>br': { display: 'none' },
                                'p:not(:last-of-type)': { marginBottom: '1.5rem' },
                            }}  
                        />
                    )}
                </div>
            }
            showConfirm={false}
        />
    )

}

export default NotificationPopup;