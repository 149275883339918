import { emptySplitApi } from './index'

export const proposalLetterApi = emptySplitApi.injectEndpoints({
    endpoints: (builder) => ({
        getProposalStepList: builder.query({
            query: () => ({
                url: `api/v1/document-generator-service/proposal-letter/steps`,
                method: "GET"
            }),
        }),
        insertUpdateProposal: builder.mutation({
            query: (model) => ({
                url: `api/v1/document-generator-service/proposal-letter`,
                method: "POST",
                body: model
            }),
        }),
        getProposalLetter: builder.mutation({
            query: (userProjectId) => ({
                url: `api/v1/document-generator-service/proposal-letter/${userProjectId}`,
                method: "GET",
            }),
        }),
    }),
    overrideExisting: false,
});

export const { 
    useGetProposalStepListQuery,
    useInsertUpdateProposalMutation,
    useGetProposalLetterMutation
} = proposalLetterApi;
