import { useDispatch, useSelector } from "react-redux";
import {
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
    Paper,
    Skeleton,
    IconButton,
} from "@mui/material";

import Pagination from '@mui/material/Pagination';
import { DeleteForever } from "@mui/icons-material"
import { useTranslation } from "react-i18next";
import { showErrorToaster, showSuccessToaster } from "store/Shared.Slice";
import { useGetAdditionalEmailPagedListMutation, useDeleteAdditionalEmailMutation } from "Services/OrganizationAdditionalEmailService";

const OrganizationAdditionalEmailList = () => {

    const { 
        loadingList,
        additionalEmailListPagedData: {
            pageNumber,
            pageSize,
            pageCount,
            items
        }
    } = useSelector((state) => state.organizationAdditionalEmailState);

    const { t } = useTranslation();
    const dispatch = useDispatch();
    const [getAdditionalEmailList] = useGetAdditionalEmailPagedListMutation();
    const [deleteAdditionalEmail, { isLoading: isDeleting }] = useDeleteAdditionalEmailMutation();

    const handleChangePage = async (event, newPage) => {

        try {
            await getAdditionalEmailList({pageNumber: newPage, pageSize});
        }
        catch(error) {
            // Bad Request
            if (error.data?.message != null) {
                dispatch(showErrorToaster(error.data?.message));
            }
        }

    };

    const handleDelete = async(row) => {

        try {
            const response = await deleteAdditionalEmail(row.id).unwrap();
            await getAdditionalEmailList({pageNumber: 1, pageSize});
            dispatch(showSuccessToaster(response.message));
        }
        catch(error) {
            // Bad Request
            if (error.data?.message != null) {
                dispatch(showErrorToaster(error.data?.message));
            }
        }

    };

    return (
        <>
            <TableContainer component={Paper}>
                <Table>
                    <TableHead>
                        <TableRow>
                            <TableCell>E-mail</TableCell>
                            <TableCell>{t('labels.created_at')}</TableCell>
                            <TableCell>{t('labels.actions')}</TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {loadingList && 
                            <TableRow key={0} sx={{ '&:last-child td, &:last-child th': { border: 0 } }}>
                                <TableCell colSpan={3}>
                                    <Skeleton height={50} variant="rectangular" />
                                </TableCell>
                            </TableRow>
                        }
                        {items.map((row) => (
                            <TableRow key={row.id} sx={{ '&:last-child td, &:last-child th': { border: 0 } }}>
                                <TableCell>{row.emailAddress}</TableCell>
                                <TableCell>{row.createdAt}</TableCell>
                                <TableCell>
                                    <IconButton color="secondary" aria-label="Remove e-mail" onClick={() => handleDelete(row)} disabled={isDeleting}>
                                        <DeleteForever />
                                    </IconButton>
                                </TableCell>
                            </TableRow>
                        ))}
                    </TableBody>
                </Table>
            </TableContainer>

            <div style={{display: 'flex', justifyContent: 'end'}}>
                <Pagination count={pageCount} page={pageNumber} onChange={handleChangePage} />
            </div>

            
      </>
    );
};

export default OrganizationAdditionalEmailList;
