import { emptySplitApi } from './index'

export const notesApi = emptySplitApi.injectEndpoints({
    endpoints: (builder) => ({
        getNoteList: builder.mutation({
            query: (model) => ({
              url: `api/v1/foundation-service/notes?`
                    + `&profileId=${model?.profileId}`
                    + `&flagStatusFilter=${model?.flagStatusFilter || 'All'}`
                    + `&pageNumber=${model?.pageNumber || 0}`
                    + `&pageSize=${model?.pageSize || ''}`
                    + `&orderField=${model?.orderField || 'description'}`
                    + `&orderDirection=${model?.orderDirection || 'asc'}`,
              method: "GET",
            }),
        }),
        insertNote: builder.mutation({
            query: (model) => ({
              url: `api/v1/foundation-service/notes`,
              method: "POST",
              body: model
            }),
        }),
        updateNote: builder.mutation({
            query: (model) => ({
              url: `api/v1/foundation-service/notes/${model.id}`,
              method: "PATCH",
              body: model
            }),
        }),
        deleteNote: builder.mutation({
            query: (id) => ({
              url: `api/v1/foundation-service/notes/${id}`,
              method: "DELETE",
            }),
        }),
        togglePinNote: builder.mutation({
          query: (id) => ({
            url: `api/v1/foundation-service/notes/${id}/toggle-pin`,
            method: "POST",
          })
        }),
        getNoteDocuments: builder.mutation({
          query: (id) =>  `api/v1/foundation-service/notes-documents/${id}`,
        }),
        uploadNoteDocuments: builder.mutation({
          query: (model) => ({
            url: `api/v1/foundation-service/notes-documents`,
            method: "POST",
            body: model,
          })
        }),
        deleteNoteDocument: builder.mutation({
          query: ({id, documentId}) => ({
            url: `api/v1/foundation-service/notes-documents/${id}/document/${documentId}`,
            method: "DELETE",
          })
        }),
    }),
  overrideExisting: false,
});

export const {  
    useGetNoteListMutation,
    useInsertNoteMutation,
    useUpdateNoteMutation,
    useDeleteNoteMutation,
    useTogglePinNoteMutation,
    useUploadNoteDocumentsMutation,
    useGetNoteDocumentsMutation,
    useDeleteNoteDocumentMutation,
} = notesApi;
