import { emptySplitApi } from './index'

export const feedbackApi = emptySplitApi.injectEndpoints({
  endpoints: (builder) => ({
    getFeedbackPagedList: builder.mutation({
      query: (model) => ({
          url: `api/v1/auth-service/feedback?`
              + `&feedbackType=${model?.feedbackType || 'All'}`
              + `&searchTerm=${model?.searchTerm || ''}`
              + `&pageNumber=${model?.pageNumber || 0}`
              + `&pageSize=${model?.pageSize || ''}`
              + `&orderField=${model?.orderField || 'reviewed'}`
              + `&orderDirection=${model?.orderDirection || 'asc'}`,
          method: "GET"
      }),
    }),
    toggleReview: builder.mutation({
      query: (id) => ({
        url: `api/v1/auth-service/feedback/${id}/toggle-review`,
          method: "POST",
      })
    }),
    getFeedbackDocuments: builder.mutation({
      query: ({id, documentType}) => `api/v1/auth-service/feedback/${id}/${documentType}`
    }),
    
    //------ User --------
    submitFeedback: builder.mutation({
        query: (model) => ({
          url: `api/v1/auth-service/user-feedback`,
          method: "POST",
          body: model
        }),
    }),
  }),
  overrideExisting: false,
});

export const {
  useGetFeedbackPagedListMutation,
  useToggleReviewMutation,
  useSubmitFeedbackMutation,
  useGetFeedbackDocumentsMutation,
} = feedbackApi;
