import { emptySplitApi } from './index'

export const organizationAdditionalEmailApi = emptySplitApi.injectEndpoints({
  endpoints: (builder) => ({
    getAdditionalEmailPagedList: builder.mutation({
      query: ({pageNumber, pageSize}) => ({
        url: `api/v1/auth-service/organization/additional-email?pageNumber=${pageNumber}&pageSize=${pageSize}`,
        method: "GET"
      }),
    }),
    updateMainEmailAddress: builder.mutation({
      query: (emailAddress) => ({
        url: `api/v1/auth-service/organization/main-email?emailAddress=${emailAddress}`,
        method: "PATCH",
      }),
    }),
    insertAdditionalEmail: builder.mutation({
        query: (newEmailAddress) => ({
          url: `api/v1/auth-service/organization/additional-email/${newEmailAddress}`,
          method: "POST"
        }),
    }),
    deleteAdditionalEmail: builder.mutation({
        query: (id) => ({
          url: `api/v1/auth-service/organization/additional-email/${id}`,
          method: "DELETE"
        }),
    }),
  }),
  overrideExisting: false,
});

export const { 
    useGetAdditionalEmailPagedListMutation,
    useUpdateMainEmailAddressMutation,
    useInsertAdditionalEmailMutation,
    useDeleteAdditionalEmailMutation
} = organizationAdditionalEmailApi;
