import { 
    Dialog,
    DialogTitle,
    DialogContent,
    DialogActions,
    Grid,
    Typography,
    IconButton
} from "@mui/material"

import { LoadingButton } from "@mui/lab";
import { Close } from "@mui/icons-material"

const DialogConfirmWrapper = ({
    openPopup = false,
    onClose,
    titleMessage,
    popupMessage,
    confirmLabel,
    isLoading,
    onConfirm,
    showConfirm = true,
    size = 'xs',
    additionalButtonLabel = null,
    onAdditionalButtonClick = null,
    confirmButtonVisible = true,
    cancelButtonVisible = true,
    cancelLabel = "Cancel"
}) => {

    return (
            <Dialog open={openPopup} onClose={onClose} fullWidth maxWidth={size}>
                <DialogTitle sx={{ fontSize: "1rem" }}>{titleMessage}</DialogTitle>
                <IconButton
                    aria-label="close"
                    onClick={onClose}
                    sx={{
                        position: 'absolute',
                        right: 8,
                        top: 8,
                        color: (theme) => theme.palette.grey[500],
                    }}
                >
                    <Close />
                </IconButton>
                <DialogContent dividers>
                    <Grid container spacing={2}>
                        <Grid item xs={12}>
                            <Typography variant="h5">
                                {popupMessage}
                            </Typography>
                        </Grid>
                    </Grid>
                </DialogContent>
                <DialogActions sx={{ mt: 1, mb: 1 }}>

                    <LoadingButton
                        disableElevation
                        size="large"
                        type="button"
                        variant="outlined"
                        color="secondary"
                        onClick={onClose}
                        sx={{visibility: cancelButtonVisible ? "visible" : "hidden"}}
                    >
                        {cancelLabel}
                    </LoadingButton>

                    {showConfirm === true && (
                        <>
                            {additionalButtonLabel !== null && onAdditionalButtonClick !== null && (
                                <LoadingButton
                                    disableElevation
                                    size="large"
                                    type="button"
                                    variant="outlined"
                                    color="inherit"
                                    loading={isLoading}
                                    onClick={onAdditionalButtonClick}
                                    sx={{visibility: confirmButtonVisible ? "visible" : "hidden"}}
                                >
                                    {additionalButtonLabel}
                                </LoadingButton>
                            )}
                            
                            <LoadingButton
                                disableElevation
                                size="large"
                                type="button"
                                variant="contained"
                                color="secondary"
                                loading={isLoading}
                                onClick={onConfirm}
                                sx={{visibility: confirmButtonVisible ? "visible" : "hidden"}}
                            >
                                {confirmLabel}
                            </LoadingButton>
                        </>
                    )}
                    
                </DialogActions>
            </Dialog>
        )
}

export default DialogConfirmWrapper;