import { createSlice } from "@reduxjs/toolkit";
//import { emptySplitApi } from "Services";
import { userProfileApi } from "Services/UserProfileService";

export const initialState = {
    email: {
        value: "",
        error: "",
    },
    userName: {
        value: "",
        error: "",
    },
    firstName: {
        value: "",
        error: "",
    },
    lastName: {
        value: "",
        error: "",
    },
    position: {
        value: "",
        error: "",
    },
    phoneNumber: {
        value: "",
        error: "",
    },
    userNameEmail: {
        value: "",
        error: ""
    },
    currentProfileInfo: {
        email: '',
        userName: '',
        firstName: '',
        lastName: '',
        position: '',
        phoneNumber: ''
    }
};

const userProfileSlice = createSlice({
    name: "user-profile",
    initialState,
    reducers: {
        init: (state, action) => {
            state.init = {};
        },
        handleChange: (state, action) => {
            state[action.payload.field].error = "";
            state[action.payload.field].value = action.payload.value;            
        },
        handleModelErrors: (state, action) => {
            Object.entries(action.payload).forEach(([field, errors]) => { 
                state[field].error = errors[0];
            });
        },
    },
    extraReducers: (builder) => {
        builder.addMatcher(
            userProfileApi.endpoints.getUserProfile.matchFulfilled, (state, action) => {
                Object.entries(action.payload).forEach(([field, value]) => { 
                    state[field].value = value ?? '';
                    state[field].error = '';
                });

                state.currentProfileInfo = action.payload;
            }
        )
        .addMatcher(
            userProfileApi.endpoints.updateUserProfile.matchFulfilled, (state, action) => {
                state.currentProfileInfo.email = state.email.value;
                state.currentProfileInfo.userName = state.userName.value;
                state.currentProfileInfo.firstName = state.firstName.value;
                state.currentProfileInfo.lastName = state.lastName.value;
                state.currentProfileInfo.position = state.position.value;
                state.currentProfileInfo.phoneNumber = state.phoneNumber.value;   
            }
        )
    }
});

export const { 
    handleChange,
    handleModelErrors
} = userProfileSlice.actions;

export default userProfileSlice.reducer;
