import {
    Autocomplete,
    TextField,
  } from "@mui/material";

  import { useTranslation } from "react-i18next";

const AutocompleteWrapper = ({
    id, 
    label, 
    value, 
    onChange, 
    error, 
    sx, 
    theme, 
    options,
    disabled,
    loading = false,
    groupBy,
    size = "medium",
    required
}) => {

    const { t } = useTranslation();

    return(

        <Autocomplete
            autoComplete
            id={id}
            value={value}
            isOptionEqualToValue={(option, value) => option.value === value}
            onChange={onChange}
            disabled={disabled}
            loading={loading}
            size={size}
            {...groupBy && { groupBy: groupBy }}
            disableClearable={true}
            renderInput={(params) => 
                <TextField 
                    {...params} 
                    fullWidth 
                    {...error && {helperText: t(error), error: true}}
                    {...label && {label: label}}
                    disabled={disabled}
                    sx={{ 'input': { height: 30, marginRight: '20px' } }}
                    variant="outlined"
                    title={value}
                    required={required}
                />
            }
            options={options}
            sx={sx}
        />

       
    );

    

}

export default AutocompleteWrapper;