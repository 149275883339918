// assets
import { IconUserPlus, IconMan, IconBuildingCommunity } from '@tabler/icons';

// constant
export const icons = {
    IconUserPlus,
    IconMan,
    IconBuildingCommunity
};

const account = {
    id: 'account',
    title: 'side_menu.account',
    type: 'group',
    roles: ['user', 'subuser', 'member', 'supervisor', 'admin', 'demo', 'sales', 'superadmin', 'it'],
    collapse: false,
    children: [
        {
            id: 'account-user-profile',
            title: 'side_menu.user_profile',
            type: 'item',
            url: 'main/account/user-profile',
            icon: 'IconMan', //icons.IconMan,
            breadcrumbs: false,
            roles: ['user', 'subuser', 'member', 'supervisor', 'demo', 'sales', 'admin', 'sales', 'superadmin', 'it'],
        },
        {
            id: 'account-additional-users',
            title: 'side_menu.additional_users',
            type: 'item',
            url: 'main/account/additional-users',
            icon: 'IconUserPlus', //icons.IconUserPlus,
            breadcrumbs: false,
            roles: ['user', 'demo', 'sales', 'admin', 'superadmin', 'it'],
        },
        {
            id: 'account-organization-profile',
            title: 'side_menu.organization_profile',
            type: 'item',
            url: 'main/account/organization-profile',
            icon: 'IconBuildingCommunity', //icons.IconBuildingCommunity,
            breadcrumbs: false,
            roles: ['user', 'demo', 'sales', 'admin', 'superadmin', 'it'],
        }
    ]
};

export default account;
