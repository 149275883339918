import { useState } from "react";
import { InputAdornment, IconButton } from "@mui/material";
import { VisibilityOff, Visibility } from "@mui/icons-material";
import FormControlWrapper from "./FormControlWrapper";

const PasswordInputWrapper = ({
  name,
  value,
  label,
  maxLength,
  error = "",
  theme,
  onChange,
  required,
}) => {
  const [showPassword, setShowPassword] = useState(false);

  const handleClickShowPassword = () => setShowPassword((show) => !show);

  const handleMouseDownPassword = (e) => {
    e.preventDefault();
  };

  return (
    <FormControlWrapper
      name={name}
      value={value}
      label={label}
      type={showPassword ? 'text' : 'password'}
      maxLength={maxLength}
      error={error}
      theme={theme}
      onChange={onChange}
      required={required}
      endAdornment={
        <InputAdornment position="end">
          <IconButton
            aria-label="toggle password visibility"
            onClick={handleClickShowPassword}
            onMouseDown={handleMouseDownPassword}
            edge="end"
          >
            {showPassword ? <VisibilityOff /> : <Visibility />}
          </IconButton>
        </InputAdornment>
      }
    />
  );
};

export default PasswordInputWrapper;
