import { createSlice } from "@reduxjs/toolkit";
import { blockedFundersApi } from "Services/BlockedFundersService";
import { userProjectApi } from "Services/UserProjectService";
import startcase from "utils/startcase";

export const initialState = {
    selectedProject: {
        id: null,
        projectName: ''
    },
    userProjectPagedList: {
        pageNumber: 0,
        pageSize: 10,
        pageCount: 0,
        totalCount: 0,
        orderField: 'name',
        orderDirection: 'asc',
        items: []
    },
    blockedFundersPagedList: {
        pageNumber: 0,
        pageSize: 10,
        pageCount: 0,
        totalCount: 0,
        orderField: 'foundationName',
        orderDirection: 'asc',
        items: []
    }  
};

const blockedFundersSlice = createSlice({
    name: "favorites-page",
    initialState,
    reducers: {
        handleSelectUserProject: (state, action) => {
            state.selectedProject.id = action.payload.id;
            state.selectedProject.name = action.payload.name;
        },
    },
    extraReducers: (builder) => {
        builder.addMatcher(
            blockedFundersApi.endpoints.getBlockedFundersList.matchFulfilled, (state, action) => {
                state.blockedFundersPagedList.pageNumber = action.payload.pageNumber;
                state.blockedFundersPagedList.pageSize = action.payload.pageSize;
                state.blockedFundersPagedList.pageCount = action.payload.pageCount;
                state.blockedFundersPagedList.totalCount = action.payload.totalCount;
                state.blockedFundersPagedList.orderField = action.payload.orderField;
                state.blockedFundersPagedList.orderDirection = action.payload.orderDirection;
                state.blockedFundersPagedList.items = action.payload.items?.map(o => ({...o, foundationName: startcase(o.foundationName)}));
            }
        )
        .addMatcher(
            userProjectApi.endpoints.getAll.matchFulfilled, (state, action) => {

                const items = [...action.payload.items];

                items.push({
                    id: 'blocked-account',
                    name: 'Blocked Account'
                });

                state.userProjectPagedList.pageNumber = action.payload.pageNumber;
                state.userProjectPagedList.pageSize = action.payload.pageSize;
                state.userProjectPagedList.pageCount = action.payload.pageCount;
                state.userProjectPagedList.totalCount = action.payload.totalCount + 1;
                state.userProjectPagedList.orderField = action.payload.orderField;
                state.userProjectPagedList.orderDirection = action.payload.orderDirection;
                state.userProjectPagedList.items = items;
            }
        )
    }

});

export const { 
    handleSelectUserProject,
} = blockedFundersSlice.actions;

export default blockedFundersSlice.reducer;
