import { useState } from 'react';
import PropTypes from 'prop-types';

// material-ui
import { 
    Divider, 
    List, 
    Typography,
    ListItemButton,
    ListItemIcon,
    ListItemText,
    Collapse
} from '@mui/material';
import FiberManualRecordIcon from '@mui/icons-material/FiberManualRecord';

import { IconChevronDown, IconChevronUp } from '@tabler/icons';

// project imports
import NavItem from '../NavItem';
import NavCollapse from '../NavCollapse';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from "react-i18next";
import { collapseMenu } from 'store/Shared.Slice';
import { useNavigate } from 'react-router';


// ==============================|| SIDEBAR MENU LIST GROUP ||============================== //

const NavGroup = ({ item }) => {

    const { t } = useTranslation();
    const dispatch = useDispatch();
    const { userToken } = useSelector(state => state.sharedState)
    const customization = useSelector((state) => state.customization);
    const navigate = useNavigate();

    let userRoles = [];

    if (Array.isArray(userToken.role)) {
        userRoles = userToken.role.map(value => value.toLowerCase());
    }
    else {
        userRoles.push(userToken.role.toLowerCase());
    }

    const [selected, setSelected] = useState(null);

    const menuIcon = (
        <FiberManualRecordIcon
            sx={{
                width: selected === item.id ? 8 : 6,
                height: selected === item.id ? 8 : 6
            }}
            //fontSize={level > 0 ? 'inherit' : 'medium'}
            fontSize='inherit'
        />
    );

    const handleClick = () => {
        setSelected(!selected ? item.id : null);

        if (item.id === 'grant-secretary') {
            navigate('/main/grant-secretary');
        }
        else {
            dispatch(collapseMenu(item.id));
        }
    };


    // menu list collapse & items
    const items = item.children?.map((menu) => {
        switch (menu.type) {
            case 'collapse':

                if (userRoles.filter(x => menu.roles.includes(x)).length > 0)
                    return <NavCollapse key={menu.id} menu={menu} level={1} />;
                else
                    return null;

            case 'item':

                if (userRoles.filter(x => menu.roles.includes(x)).length > 0)
                    return <NavItem key={menu.id} item={menu} level={1} />;
                else
                    return null;

            default:
                return (
                    <Typography key={menu.id} variant="h6" color="error" align="center">
                        Menu Items Error
                    </Typography>
                );
        }
    });

    return (
        <>
          <ListItemButton
                sx={{
                    borderRadius: `${customization.borderRadius}px`,
                    mb: 0.5,
                    alignItems: 'flex-start',
                    //backgroundColor: level > 1 ? 'transparent !important' : 'inherit',
                    backgroundColor: 'transparent !important',
                    py: 2,
                    pl: `${0}px`,
                    color: 'white'
                }}
                //selected={selected === 'grant-secretary'}
                onClick={handleClick}
            >
                <ListItemIcon sx={{ my: 'auto', minWidth: !item.icon ? 18 : 36, color: 'white' }}>{menuIcon}</ListItemIcon>
                <ListItemText
                    primary={
                        <Typography variant={selected === item.id ? 'h5' : 'body1'} color="inherit" sx={{ my: 'auto' }}>
                            {t(item.title)}
                        </Typography>
                    }
                />
                {item.id !== 'grant-secretary' && (
                    item.collapse ? (
                        <IconChevronUp stroke={1.5} size="16" style={{ marginTop: 'auto', marginBottom: 'auto' }} />
                    ) : (
                        <IconChevronDown stroke={1.5} size="16" style={{ marginTop: 'auto', marginBottom: 'auto' }} />
                    )
                )}
                
            </ListItemButton>
            <Collapse in={item.collapse} timeout="auto" unmountOnExit>
                <List
                    component="div"
                    disablePadding
                >
                    {items}
                </List>
            </Collapse>
            {/*<List
                subheader={
                    item.title && (
                        <Typography variant="caption" sx={{ ...theme.typography.menuCaption }} display="block" gutterBottom>
                            {t(item.title)}
                            {item.caption && (
                                <Typography variant="caption" sx={{ ...theme.typography.subMenuCaption }} display="block" gutterBottom>
                                    {t(item.caption)}
                                </Typography>
                            )}
                        </Typography>
                    )
                }
            >
                {items}
            </List>*/}

            {/* group divider */}
            <Divider sx={{ mt: 0.25, mb: 1.25 }} />
        </>
    );
};

NavGroup.propTypes = {
    item: PropTypes.object
};

export default NavGroup;
