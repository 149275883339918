import { emptySplitApi } from './index'

const loginApi = emptySplitApi.injectEndpoints({
  endpoints: (builder) => ({
    login: builder.mutation({
      query: (model) => ({
        url: `api/v1/auth-service/login`,
        method: "POST",
        body: model,
      }),
      
    }),
    logout: builder.mutation({
      query: () => ({
        url: `api/v1/auth-service/login`,
        method: "DELETE",
      }),
      
    }),
  }),
  overrideExisting: false,
});

export const { useLoginMutation, useLogoutMutation } = loginApi;
