import { createSlice } from "@reduxjs/toolkit";
import { couponApi } from "Services/CouponService";

export const initialState = {
    loadingList: false,
    openCreateCouponDialog: false,
    subscriptionPlanList: [],
    selectedSubscriptionPlanList: [],
    pagedList: {
        pageNumber: 0,
        pageSize: 10,
        pageCount: 0,
        totalCount: 0,
        orderField: 'name',
        orderDirection: 'asc',
        items: []
    },
    couponValues: {
        id: "",
        subscriptionPlanLabelList: [],
        isPaused: false,
        name: {
            value: "",
            error: ""
        },
        code: {
            value: "",
            error: ""
        },
        expirationDate: {
            value: null,
            error: ""
        },
        subscriptionPlanId: {
            value: null,
            error: ""
        },
        toggleExtraTime: {
            value: false,
            error: ""
        },
        toggleDiscount: {
            value: false,
            error: ""
        },
        toggleTemplate: {
            value: false,
            error: ""
        },
        extraTime: {
            value: null,
            error: ""
        },
        discount: {
            value: null,
            error: ""
        },
    }
};

const manageCouponSlice = createSlice({
    name: "manage-coupons",
    initialState,
    reducers: {
        handleModelErrors: (state, action) => {
            Object.entries(action.payload).forEach(([field, errors]) => { 
                state.couponValues[field].error = errors[0];
            });
        },
        handleOpenForm: (state, action) => {
            if(action.payload === false) {
                state.couponValues = initialState.couponValues;
                state.selectedSubscriptionPlanList = [];
            } 
            state.openCreateCouponDialog = action.payload;
        },
        handleUpdateCouponValues: (state, action) => {
            const { key, value } = action.payload;
            state.couponValues[key].value = value;
            state.couponValues[key].error = "";
        },
        handleToggleUpdate: (state, action) => {
            
            const { toggleToOn, toggleToOff, isSales, value } = action.payload;
            var toggleToOnValue = state.couponValues[toggleToOn].value;
            if (isSales) {
                state.couponValues[toggleToOn].value = !toggleToOnValue;
                state.couponValues[toggleToOn].error = "";
    
                state.couponValues[toggleToOff].value = toggleToOnValue;
                state.couponValues[toggleToOff].error = "";
            } else {
                //when adm, the both toggles can be false, but the both cant be true
                if (state.couponValues[toggleToOff].value === true && value === true) {
                    state.couponValues[toggleToOn].value = value;
                    state.couponValues[toggleToOn].error = "";

                    state.couponValues[toggleToOff].value = !value;
                    state.couponValues[toggleToOn].error = "";
                } else {
                    state.couponValues[toggleToOn].value = value;
                    state.couponValues[toggleToOn].error = "";
                }
            }
           
        },
        handleSetCouponValues: (state, action) => {
            const { 
                id, 
                name, 
                code, 
                expirationDate, 
                isExtraTime, 
                isDiscount, 
                extraMonths, 
                discountPercentual, 
                subscriptionPlanLabelList, 
                isPaused,
                isTemplate,
            } = action.payload;

            state.selectedSubscriptionPlanList = subscriptionPlanLabelList;
            state.couponValues = {
                id,
                isPaused,
                subscriptionPlanLabelList,
                name: {
                    value: name,
                    error: ""
                },
                code: {
                    value: code,
                    error: ""
                },
                expirationDate: {
                    value: expirationDate,
                    error: ""
                },
                toggleExtraTime: {
                    value: isExtraTime,
                    error: ""
                },
                toggleDiscount: {
                    value: isDiscount,
                    error: ""
                },
                toggleTemplate: {
                    value: isTemplate,
                    error: ""
                },
                extraTime: {
                    value: extraMonths,
                    error: ""
                },
                discount: {
                    value: discountPercentual,
                    error: ""
                }
            };
        },
        handleCheckSubscriptionPlans: (state, action) => {
            state.selectedSubscriptionPlanList = action.payload;
        },
        handleClearSubscriptionPlanList: (state, action) => {
            state.selectedSubscriptionPlanList = [];
        }
    },
    extraReducers: (builder) => {
        builder.addMatcher(
            couponApi.endpoints.getCouponPagedList.matchFulfilled, (state, action) => {
                state.loadingList = false;
                state.pagedList.pageNumber = action.payload.pageNumber;
                state.pagedList.pageSize = action.payload.pageSize;
                state.pagedList.pageCount = action.payload.pageCount;
                state.pagedList.totalCount = action.payload.totalCount;
                state.pagedList.orderField = action.payload.orderField;
                state.pagedList.orderDirection = action.payload.orderDirection;
                state.pagedList.items = action.payload.items;
            }
        )
        .addMatcher(
            couponApi.endpoints.getCouponPagedList.matchPending, (state, action) => {
                state.loadingList = true;
            }
        )
        .addMatcher(
            couponApi.endpoints.getCouponPagedList.matchRejected, (state, action) => {
                state.loadingList = false;
            }
        )
        .addMatcher(couponApi.endpoints.getCouponPlans.matchFulfilled, (state, action) => {
            state.subscriptionPlanList = action.payload?.map(plan => ({
                id: plan.id,
                value: plan.subscriptionPlanName,
                label: plan.planDescription
            })) ?? [];
        });
    }

});

export const {
    handleModelErrors,
    handleOpenForm,
    handleSetCouponValues,
    handleToggleUpdate,
    handleUpdateCouponValues,
    handleCheckSubscriptionPlans,
    handleClearSubscriptionPlanList
} = manageCouponSlice.actions;

export default manageCouponSlice.reducer;