import { createSlice } from "@reduxjs/toolkit";
import { favoriteFundersApi } from "Services/FavoriteFundersService";
import { favoritesApi } from "Services/FavoritesService";
import startcase from "utils/startcase";

export const initialState = {
    selectedFavorite: {
        id: null,
        favoriteName: ''
    },
    popupFavorites: {
        open: false,
        mode: 'insert'
    },
    firstProfileId: '',
    openPopupDuplicateFavorites: false,
    favoritesPagedList: {
        pageNumber: 0,
        pageSize: 10,
        pageCount: 0,
        totalCount: 0,
        orderField: 'favoriteName',
        orderDirection: 'asc',
        items: []
    },
    favoritesPagedSharedList: {
        pageNumber: 0,
        pageSize: 10,
        pageCount: 0,
        totalCount: 0,
        orderField: 'favoriteName',
        orderDirection: 'asc',
        items: []
    },
    favoriteFundersPagedList: {
        pageNumber: 0,
        pageSize: 10,
        pageCount: 0,
        totalCount: 0,
        orderField: 'isQualified',
        orderDirection: 'desc',
        items: []
    }  
};

const favoritesSlice = createSlice({
    name: "favorites-page",
    initialState,
    reducers: {
        handleSelectFavorite: (state, action) => {
            state.selectedFavorite.id = action.payload.id;
            state.selectedFavorite.favoriteName = action.payload.favoriteName;
        },
        handleCreateNewFavorites: (state, action) => {
            state.popupFavorites.open = true;
            state.popupFavorites.mode = 'insert'
        },
        handleEditFavorites: (state, action) => {
            state.popupFavorites.open = true;
            state.popupFavorites.mode = 'edit';
            state.selectedFavorite.id = action.payload.id;
            state.selectedFavorite.favoriteName = action.payload.favoriteName;
        },
        handleClosePopupFavorites: (state, action) => {
            state.popupFavorites.open = false;
            state.popupFavorites.mode = 'insert';
            state.openPopupDuplicateFavorites = false;
        },
        handleConfirmEditFavorites: (state, action) => {
            state.selectedFavorite.favoriteName = action.payload.favoriteName;
            state.favoritesPagedList.items.forEach(item => {
                if (item.id === action.payload.id) {
                    item.favoriteName = action.payload.favoriteName;
                    return;
                }
            });
        },
        handleOpenPopupDuplicateFavorites: (state, action) => {
            state.openPopupDuplicateFavorites = true;
        },
        handleClearFavoriteFundersList: (state, action) => {
            state.favoriteFundersPagedList = { ...initialState.favoriteFundersPagedList };
        },
        handleResetPage: (state, action) => {
            state.favoriteFundersPagedList.pageNumber = 0;
            state.favoriteFundersPagedList.pageSize = 10;
        }
    },
    extraReducers: (builder) => {
        builder.addMatcher(
            favoritesApi.endpoints.getFavoritesPagedList.matchFulfilled, (state, action) => {
                state.favoritesPagedList.pageNumber = action.payload.pageNumber;
                state.favoritesPagedList.pageSize = action.payload.pageSize;
                state.favoritesPagedList.pageCount = action.payload.pageCount;
                state.favoritesPagedList.totalCount = action.payload.totalCount;
                state.favoritesPagedList.orderField = action.payload.orderField;
                state.favoritesPagedList.orderDirection = action.payload.orderDirection;
                state.favoritesPagedList.items = action.payload.items;
            }
        )
        .addMatcher(
            favoritesApi.endpoints.getFavoritesPagedSharedList.matchFulfilled, (state, action) => {
                state.favoritesPagedSharedList.pageNumber = action.payload.pageNumber;
                state.favoritesPagedSharedList.pageSize = action.payload.pageSize;
                state.favoritesPagedSharedList.pageCount = action.payload.pageCount;
                state.favoritesPagedSharedList.totalCount = action.payload.totalCount;
                state.favoritesPagedSharedList.orderField = action.payload.orderField;
                state.favoritesPagedSharedList.orderDirection = action.payload.orderDirection;
                state.favoritesPagedSharedList.items = action.payload.items;
            }
        )
        .addMatcher(
            favoriteFundersApi.endpoints.getFavoriteFundersPagedList.matchFulfilled, (state, action) => {
                state.favoriteFundersPagedList.pageNumber = action.payload.pageNumber;
                state.favoriteFundersPagedList.pageSize = action.payload.pageSize;
                state.favoriteFundersPagedList.pageCount = action.payload.pageCount;
                state.favoriteFundersPagedList.totalCount = action.payload.totalCount;
                state.favoriteFundersPagedList.orderField = action.payload.orderField;
                state.favoriteFundersPagedList.orderDirection = action.payload.orderDirection;
                state.favoriteFundersPagedList.items = action.payload.items?.map(o => ({...o, foundationName: startcase(o.foundationName)}));

                if (action.payload.pageNumber === 0 && action.payload.items.length > 0) {
                    state.firstProfileId = action.payload.items[0]?.profileId;
                }
            }
        )
    }

});

export const { 
    handleSelectFavorite,
    handleOpenPopupFavorites,
    handleEditFavorites,
    handleCreateNewFavorites,
    handleClosePopupFavorites,
    handleConfirmEditFavorites,
    handleOpenPopupDuplicateFavorites,
    handleClearFavoriteFundersList,
    handleResetPage
} = favoritesSlice.actions;

export default favoritesSlice.reducer;
